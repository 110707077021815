<template>
  <Teleport to="body">
    <ion-backdrop
      :visible="modelValue"
      v-if="modelValue"
      :stop-propagation="false"
      :tappable="false"
    ></ion-backdrop>
    <div id="box" v-if="modelValue">
      <div class="modal__container">
        <div class="modal__delete ion-padding-top">
          <div class="modal__title">
            <slot name="title">
              <h3>{{ $t("tasks.remove_title") }}</h3>
            </slot>
          </div>
          <div class="modal__content">
            <slot name="content">
              {{ $t("tasks.remove_description") }}
            </slot>
          </div>
          <div></div>
        </div>
        <slot name="actions">
          <div class="modal__actions ion-padding-bottom">
            <ion-button fill="clear" @click="closeModal"> No</ion-button>
            <ion-button
              fill="clear"
              class="button-confirm"
              @click="confirmDelete"
            >
              Si, eliminar
            </ion-button>
          </div>
        </slot>
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { IonButton, IonBackdrop } from "@ionic/vue";

interface Props {
  modelValue: boolean;
  nameItem?: string;
}

defineProps<Props>();
const emit = defineEmits(["update:modelValue", "confirm:delete"]);

const closeModal = () => {
  emit("update:modelValue", false);
};

const confirmDelete = () => {
  emit("confirm:delete");
};
</script>
<style lang="scss">
.modal__container {
  display: flex;
  flex-direction: column;
}

.modal__title {
  h3 {
    text-align: left;
  }
}

.modal__delete {
  height: 75%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.modal__actions {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.button-confirm {
  color: #cf2617;
}
ion-backdrop {
  opacity: 0.9;
  background: rgb(214 214 214);
}

#box {
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-background-color, #fff);
  width: 80%;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
}
</style>
