<template>
  <ForgotPasswordWrapper>
    <template #step>
      <h2 class="forgot-password-wrapper__title">
        {{ $t("register.verificationCode") }}
      </h2>
      <p class="forgot-password-wrapper__subtitle text-body-s">
        {{ $t("register.verificationCodeDescription") }}
      </p>
      <FormComponent
        :model="model"
        :schema="schemaPinCode"
        no-cancel-button
        @success="pinCodeSubmit"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("register.continue") }}
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </ForgotPasswordWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPinCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { useForgotPassword } from "@/composables/useForgotPassword";
import ForgotPasswordWrapper from "@/views/forgot_password/ForgotPasswordWrapper.vue";

const { pin, savePin } = useForgotPassword();

const model: Ref<any> = ref({
  name: "pin_code",
  fields: [
    {
      name: "pin",
      label: "Escribe el código de verificación",
      type: "pin",
    },
  ],
});

const pinCodeSubmit = async (values: any) => {
  pin.value = values.pin;
  await savePin();
};
</script>
<style scoped lang="scss">
.button--login {
  width: 100%;
}
</style>
