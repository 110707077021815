<template>
  <RegisterWrapper :class="isPlatformIOS ? 'safe-area' : ''">
    <template #step>
      <h2 class="photo-wrapper__title">
        {{ $t("register.addPhotoToProfile") }}
      </h2>
      <p class="photo-wrapper__subtitle text-body-s">
        {{ $t("register.addPhotoToProfileDescription") }}
      </p>
      <section class="photo-wrapper__body">
        <div class="photo-wrapper__logo">
          <PhotoEditComponent
            :image-id="registerStore.user.image_id ?? -1"
            :user-id="registerStore.user.id"
          />
        </div>
        <div class="photo-wrapper__options">
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="button"
            fill="outline"
            @click="skipStep"
            >{{ $t("actions.skip") }}
          </ion-button>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="button"
            @click="photoSubmit"
            >{{ $t("actions.continue") }}
          </ion-button>
        </div>
      </section>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { CoreStore, useCoreStore } from "@/stores/core";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton } from "@ionic/vue";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";
import { ROLES } from "@/utils/constants";

const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const { isPlatformIOS } = usePlatform();

const router = useRouter();

const skipStep = async () => {
  coreStore.resetHelpStep();
  if (registerStore.user.role === ROLES.EMPLOYEE) {
    await router.push({ name: "joinHome" });
  } else {
    await router.push({ name: "registerProfileCreated" });
  }
};

const photoSubmit = async () => {
  if (registerStore.user.role === ROLES.EMPLOYEE) {
    await router.push({ name: "joinHome" });
  } else {
    await router.push({ name: "registerProfileCreated" });
  }
};
</script>
<style scoped lang="scss">
.photo-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
