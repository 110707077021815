<template>
  <ion-menu content-id="main-content" @ionDidClose="closeMenu">
    <ion-content>
      <div class="admin__navigation__drawer">
        <div class="admin__user__item">
          <DropdownLocationMenuComponent @to:locations="closeMenu" />
        </div>
        <div class="admin__items">
          <div v-if="user.role == ROLES.OWNER">
            <a
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('admin.home')"
            >
              <VsxIcon iconName="Home"></VsxIcon>
              <span>{{ i18n.global.t("global.home") }}</span>
            </a>
            <a
              ref="step5_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('default.talkie.list')"
            >
              <VsxIcon iconName="VoiceCricle"></VsxIcon>
              <span>{{ i18n.global.t("global.talkie") }}</span>
            </a>
            <a
              ref="step2_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('admin.locations.list')"
            >
              <VsxIcon iconName="Shop"></VsxIcon>
              <span>{{ i18n.global.t("global.locals") }}</span>
            </a>
            <a
              ref="step3_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('default.tasks.list')"
            >
              <VsxIcon iconName="ClipboardText"></VsxIcon>
              <span>{{ i18n.global.t("global.tasks") }}</span>
            </a>
            <a
              ref="step4_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('admin.groups.list')"
            >
              <VsxIcon iconName="People"></VsxIcon>
              <span>{{ i18n.global.t("global.members") }}</span>
            </a>
          </div>
          <div v-else>
            <a
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('employee.home')"
            >
              <VsxIcon iconName="Home"></VsxIcon>
              <span>{{ i18n.global.t("global.home") }}</span>
            </a>
            <a
              ref="step5_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('default.talkie.list')"
            >
              <VsxIcon iconName="VoiceCricle"></VsxIcon>
              <span>{{ i18n.global.t("global.talkie") }}</span>
            </a>
            <a
              ref="step3_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('default.tasks.list')"
            >
              <VsxIcon iconName="ClipboardText"></VsxIcon>
              <span>{{ i18n.global.t("global.tasks") }}</span>
            </a>
            <a
              ref="step6_1"
              href="#"
              exact-active-class="active"
              @click.prevent="handleGetRoute('joinRequestList')"
            >
              <VsxIcon iconName="People"></VsxIcon>
              <span>{{ i18n.global.t("global.team") }}</span>
            </a>
          </div>
          <div>
            <hr />
            <a href="https://wehop.app/" target="_blank">
              <VsxIcon iconName="Sms"></VsxIcon>
              <span>{{ $t("global.contact") }}</span>
            </a>
            <a href="#" @click.prevent="handleProfile">
              <VsxIcon iconName="Profile"></VsxIcon>
              <span>{{ $t("global.profile") }}</span>
            </a>
            <a href="#" @click.prevent="handleLogout">
              <VsxIcon iconName="Logout"></VsxIcon>
              <span>{{ $t("global.logout") }}</span>
            </a>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script setup lang="ts">
import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { ref } from "vue";
import { IonContent, IonMenu } from "@ionic/vue";
import { Item } from "@/interfaces/GenericModel";
import { useRouter } from "vue-router";
import links from "@/layouts/navigation/links";
import { useAuth } from "@/composables/useAuth";
import DropdownLocationMenuComponent from "@/components/shared/DropdownLocationMenuComponent.vue";
import { MODEL, ROLES } from "@/utils/constants";
import employee_links from "@/layouts/navigation/employee_links";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";

const router = useRouter();
const { logout } = useAuth();
const { user, handleGetMe } = useRegister();
const tourStore: TourStore = useTourStore();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const { setRefreshDashboardOwner } = useCore();

const items: Ref<Array<Item>> = ref(
  user.value.role == ROLES.OWNER ? links : employee_links
);

const step2_1 = ref(null);
const step3_1 = ref(null);
const step4_1 = ref(null);
const step5_1 = ref(null);

const tour2_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour3_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour4_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});
const tour5_1 = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const closeMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "";
  menu.style.inset = "";
  menu?.close();
};
const handleGetRoute = (to: any) => {
  switch (to) {
    case "admin.home":
      closeMenu();
      router.push({ name: to });
      break;
    case "employee.home":
      closeMenu();
      router.push({ name: to });
      break;
    case "default.talkie.list":
      if (!user.value.is_talkie) {
        tour5_1.addStep({
          attachTo: { element: step5_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTalkieTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_talkie: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour5_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour5_1.title"),
          text: i18n.global.t("tour.tour5_1.text"),
        });
        tour5_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    case "admin.locations.list":
      if (!user.value.is_teams) {
        tour2_1.addStep({
          attachTo: { element: step2_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTeamTour({
                  show: true,
                  step: 2,
                });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_teams: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour2_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour2_1.title"),
          text: i18n.global.t("tour.tour2_1.text"),
        });
        tour2_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }

      break;
    case "default.tasks.list":
      if (!user.value.is_task) {
        tour3_1.addStep({
          attachTo: { element: step3_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setTaskTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_task: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour3_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour3_1.title"),
          text: i18n.global.t("tour.tour3_1.text"),
        });
        tour3_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    case "admin.groups.list":
      if (!user.value.is_members) {
        tour4_1.addStep({
          attachTo: { element: step4_1.value, on: "bottom-end" },
          buttons: [
            {
              text: i18n.global.t("actions.accept"),
              action() {
                tourStore.setMemberTour({ show: true, step: 2 });
                closeMenu();
                //Save Step
                handlePutModel({
                  model: MODEL.USER,
                  id: registerStore.user.id,
                  fields: {
                    is_members: true,
                  },
                }).then(async () => {
                  await handleGetMe();
                });
                tour4_1.complete();
                router.push({ name: to });
              },
            },
          ],
          title: i18n.global.t("tour.tour4_1.title"),
          text: i18n.global.t("tour.tour4_1.text"),
        });
        tour4_1.start();
      } else {
        closeMenu();
        router.push({ name: to });
      }
      break;
    default:
      router.push({ name: to });
      break;
  }
};
const handleLogout = () => {
  closeMenu();
  logout();
};

const handleProfile = () => {
  closeMenu();
  coreStore.setSelectedUser(null);
  router.push({ name: "myProfileOwner" });
};
</script>
<style lang="scss">
@import "../../theme/mixins_responsive";

.admin__panel {
  display: flex;
}

.admin__navigation__drawer {
  color: white;
  background-color: #b6b8be;
  height: 100vh;
  @include laptop {
    display: initial;
    min-width: 240px;
    max-width: 240px;
  }

  .admin__items {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include laptop {
      min-width: 240px;
      max-width: 240px;
    }
    background-color: #f8f9fc;
    height: calc(100vh - 128px);
    padding-top: 5px;

    div {
      :hover {
        background-color: rgba(143, 163, 203, 0.3);
      }

      padding: 10px;

      a {
        text-decoration: none;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 400;
        color: var(--ion-color-neutral-60);
        cursor: pointer;

        span {
          padding-left: 5px;
        }

        :hover {
          background-color: transparent;
        }

        &.active {
          background-color: rgba(182, 203, 251, 0.3);
          color: var(--ion-color-primary-60);
          font-weight: 600;
        }
      }
    }

    hr {
      background: rgba(199, 204, 214, 0.5);
    }
  }
}

.admin__content {
  display: flex;
  height: 100vh;
  width: 100vw;
  @include laptop {
    width: calc(100vw - 240px);
  }
}

.admin__user__item {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-right: 20px;
  height: 128px;
  padding-bottom: 20px;
  background: linear-gradient(
    19.37deg,
    #2e333d 64.03%,
    #5e6573 131.04%,
    #464c59 131.04%
  );
  border-radius: 0px 8px 0px 0px;

  div {
    width: 100%;

    .dropdown-menu-mobile {
      width: 300px !important;

      ol {
        width: 100%;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: -25px !important;
      top: 50px;

      ion-item {
        --background: transparent;
        --color: #fff;
        --border-color: transparent;

        .item-inner {
          box-shadow: none !important;
        }

        ion-label {
          display: flex !important;
          flex-direction: column;

          .user__name {
            font-weight: 600;
            font-size: 16px;
          }

          .user__role {
            margin-top: 5px;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
