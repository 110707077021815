<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <div>
      <div>
        <ol ref="tourMembersOptions" style="padding: 0px;margin-left: 20px;">
          <li>
            <ion-item
              :button="true"
              :detail="false"
              class="options_actions"
              id="group_detail"
              @click="emitClick('click:detail')"
            >
              <VsxIcon iconName="Copy" color="gray" />
              <span class="ion-padding-start"> {{ $t("group.detail") }} </span>
            </ion-item>
          </li>
          <li>
            <ion-item
              :button="true"
              :detail="false"
              class="options_actions"
              id="group_edit"
              @click="emitClick('click:edit')"
            >
              <VsxIcon iconName="Edit2" color="gray" />
              <span class="ion-padding-start"> {{ $t("group.edit") }} </span>
            </ion-item>
          </li>
          <li v-if="showDelete">
            <div ref="tourMembersDelete">
              <ion-item
                :button="true"
                :detail="false"
                class="options_actions trash-text"
                id="group_delete"
                @click="emitClick('click:delete')"
              >
                <VsxIcon iconName="ProfileRemove" class="trash-icon" />
                <span class="ion-padding-start">
                  {{ $t("group.delete") }}
                </span>
              </ion-item>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { ref, watch } from "vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { TourStore, useTourStore } from "@/stores/tour";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";

const emit = defineEmits(["click:detail", "click:edit", "click:delete"]);

const { user } = useRegister();
const { handleGetMe } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();

const props = defineProps({
  showDelete: {
    type: Boolean,
    default: true,
  },
  isOwner: {
    type: Boolean,
    default: false,
  },
});

const tourMembersOptions = ref(null);
const tourMembersDelete = ref(null);

const tourMembersOptionWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};

watch(showDropdown, () => {
  if (
    showDropdown.value &&
    !user.value.is_members_options &&
    props.isOwner
  ) {
    tourMembersOptionWrapper.addStep({
      attachTo: { element: tourMembersOptions.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setMemberOptionsTour({ show: true, step: 1 });
            tourMembersOptionWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour4_2.title"),
      text: i18n.global.t("tour.tour4_2.text"),
    });
    tourMembersOptionWrapper.addStep({
      attachTo: { element: tourMembersDelete.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setMemberOptionsTour({ show: true, step: 1 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_members_options: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourMembersOptionWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour4_3.title"),
      text: i18n.global.t("tour.tour4_3.text"),
    });
    tourMembersOptionWrapper.start();
  } else {
    coreStore.setShowDropdown(false);
  }
});
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
