<template>
  <ion-page>
    <WrapperPageComponent>
      <TabListComponent ref="tabList" />
      <MainButtonBarComponent />
      <ion-popover
        trigger="popover-button"
        :dismiss-on-select="true"
        alignment="start"
        side="top"
      >
        <ion-content>
          <ion-list>
            <ion-item
              class="tab-item-menu"
              :button="true"
              :detail="false"
              @click="showDrawerFormAdvice = true"
            >
              <VsxIcon iconName="Notification"></VsxIcon>
              <span class="ion-padding-start"> Aviso </span>
            </ion-item>
            <ion-item
              class="tab-item-menu"
              :button="true"
              :detail="false"
              @click="showDrawerFormTask = true"
            >
              <VsxIcon iconName="NoteText"></VsxIcon>
              <span class="ion-padding-start"> Tarea </span>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button id="popover-button">
          <div ref="step3_2">
            <VsxIcon iconName="Add"></VsxIcon>
          </div>
        </ion-fab-button>
      </ion-fab>
      <ModalComponent noIconClose v-model="showDrawerFormTask">
        <HeaderNavigationComponent
          preventDefault
          @click:back="showDrawerFormTask = false"
          :title="$t('tasks.addTask')"
          :class="isPlatformIOS ? 'safe-area' : ''"
        />

        <div class="ion-padding-top">
          <FormComponent
            v-if="showDrawerFormTask"
            no-cancel-button
            :model="modelTask"
            form-mode="create"
            :schema="schemaTask"
            @success="handleUpdateListTask"
          >
            <template #submitButton>
              <ion-button mode="ios" expand="block" type="submit">
                Guardar
              </ion-button>
            </template>
          </FormComponent>
        </div>
      </ModalComponent>
      <ModalComponent noIconClose v-model="showDrawerFormAdvice">
        <HeaderNavigationComponent
          preventDefault
          @click:back="showDrawerFormAdvice = false"
          :title="$t('advice.addAdvice')"
          :class="isPlatformIOS ? 'safe-area' : ''"
        />

        <div class="ion-padding-top">
          <FormComponent
            v-if="showDrawerFormAdvice"
            no-cancel-button
            :model="modelAdvice"
            form-mode="create"
            :schema="schemaAdvice"
            @success="handleUpdateListAdvice"
          >
            <template #submitButton>
              <ion-button mode="ios" expand="block" type="submit">
                Guardar
              </ion-button>
            </template>
          </FormComponent>
        </div>
      </ModalComponent>
    </WrapperPageComponent>
  </ion-page>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import {
  IonPage,
  toastController,
  IonFab,
  IonFabButton,
  IonPopover,
  IonList,
  IonItem,
  IonButton,
  IonContent,
} from "@ionic/vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import TabListComponent from "@/components/admin/tasks/TabListComponent.vue";
import MainButtonBarComponent from "@/components/admin/MainButtonBarComponent.vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";

import { useRegister } from "@/composables/useRegister";
import { schemanewTask, schemanewAdvice } from "@/utils/getMapModel";
import i18n from "@/plugins/i18n";
import { MODEL, ROLES } from "@/utils/constants";
import useCore from "@/composables/useCore";
import router from "@/router";
import { usePlatform } from "@/composables/usePlatform";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";

const registerStore: RegisterStore = useRegisterStore();
import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { CoreStore, useCoreStore } from "@/stores/core";

const tourStore: TourStore = useTourStore();
const codeStore: CoreStore = useCoreStore();

const { setRefreshTask, refreshTask } = useCore();

const showDrawerFormTask: Ref<boolean> = ref(false);
const showDrawerFormAdvice: Ref<boolean> = ref(false);
const schemaTask: Ref<any> = ref(schemanewTask);
const schemaAdvice: Ref<any> = ref(schemanewAdvice);
const { user, handleGetMe } = useRegister();
const { isPlatformIOS } = usePlatform();

const tabList: Ref<any> = ref(null);
const modelTask: Ref<any> = ref({
  name: MODEL.TASK,
  fields: [
    {
      name: "title",
      label: "Nombre de la tarea",
      type: "textarea",
    },
    {
      name: "description",
      label: "Escribe una descripción",
      type: "textarea",
    },
    {
      name: "assigned_user_id",
      label: "Asignar a (Opcional)",
      type: "autocomplete",
      model: "user",
      filters: [
        {
          model: "userlocation",
          foreign_key: "user_id",
          field: "location_id",
          value: user.value.location_id,
          op: "==",
        },
      ],
      display: user.value.role !== ROLES.EMPLOYEE,
      default: user.value.role === ROLES.EMPLOYEE ? user.value.id : "",
    },
    {
      name: "is_priority",
      label: "Tarea prioritaria",
      type: "toggle",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const modelAdvice: Ref<any> = ref({
  name: MODEL.ADVICE,
  fields: [
    {
      name: "name",
      label: "Nombre del aviso",
      type: "textarea",
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
    },
    {
      name: "date_end",
      label: "Fecha de finalización",
      type: "date",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const step3_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const handleUpdateListTask = async () => {
  showDrawerFormTask.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("global.changesSaved"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  tabList.value.updatePendingList();
};

const handleUpdateListAdvice = async () => {
  showDrawerFormAdvice.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("global.changesSaved"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  tabList.value.updateAdviceList();
};

onMounted(() => {
  if (!user.value.is_task && tourStore.task.step == 2) {
    tour.addStep({
      attachTo: { element: step3_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTaskTour({ show: true, step: 3 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour3_2.title"),
      text: i18n.global.t("tour.tour3_2.text"),
    });
    tour.addStep({
      attachTo: { element: step3_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTaskTour({ show: true, step: 4 });
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_task: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour3_3.title"),
      text: i18n.global.t("tour.tour3_3.text"),
    });
    tour.start();
  }
});

watch(refreshTask, async () => {
  setRefreshTask(false);
  codeStore.setRefreshLocation(false);
  codeStore.setRefreshUserLocation(false);
  codeStore.setRefreshDashboardOwner(false);
  codeStore.setRefreshDashboard(false);
  router.go(0);
});
</script>

<style scoped lang="scss">
ion-fab {
  bottom: 140px;
  right: 20px;
}

.tab-item-menu {
  color: #2e333d;
  --inner-border-width: 0px;
  --background: #f8f9fc !important;
  background: #f8f9fc;
  font-size: 16px;

  svg {
    width: 18px;
  }
}

ion-popover {
  --width: 120px;
  --offset-x: -20px;
}

.safe-area {
  padding-top: 35px;
}
</style>
