<template>
  <ModalComponent :model-value="logout" @update:modelValue="handleLogout">
    <div class="modal__logout_component">
      <span>¿Esta seguro de salir de la aplicación?</span>
    </div>
    <div class="modal__logout_buttons">
      <ion-button color="primary" mode="ios" @click="handleLogout(false)">
        <span>No</span>
      </ion-button>
      <ion-button color="danger" mode="ios" @click="handleLogoutUser">
        <VsxIcon iconName="Logout" />
        <span>salir</span>
      </ion-button>
    </div>
  </ModalComponent>
</template>
<script lang="ts" setup>
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { VsxIcon } from "vue-iconsax";
import { useAuth } from "@/composables/useAuth";
import { useCoreStore, CoreStore } from "@/stores/core";
import { IonButton } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const router = useRouter();
const coreStore: CoreStore = useCoreStore();
const { logout } = storeToRefs(coreStore);

const handleLogout = (value: boolean) => {
  coreStore.setLogout(value);
  router.go(1);
};

const handleLogoutUser = async () => {
  const { logout } = useAuth();
  await logout();
  setTimeout(() => {
    coreStore.setLogout(false);
  }, 1000);
  router.go(0);
};
</script>
<style scoped lang="scss">
.modal__logout_component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-weight: 600;
  flex-direction: column;
}
.modal__logout_buttons {
  display: flex;
  justify-content: center;
}
</style>
