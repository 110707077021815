<template>
  <Form
    ref="form"
    :id="dynamicId"
    v-if="model.fields.length"
    @submit="handleSubmit"
    :initialValues="initialValues"
    :validation-schema="schema"
  >
    <ion-row>
      <template v-for="(field, i) of model.fields" :key="i">
        <ion-col
          size="12"
          v-if="
            verifyInputTextAndNumber(field.type) &&
            field.hide !== true &&
            !hideHeaders?.includes(field.name)
          "
        >
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            :input-type="field.type"
            :name="field.name"
            :label="field.label"
            :placeholder="field.label"
            :type="field.type"
            v-numeric-only="field.type === 'number' && !field.is_float"
            v-float-numeric-only="field.is_float"
            :icon="field?.icon"
          />
        </ion-col>
        <ion-col
          size="12"
          v-if="verifyInputPassword(field.type) && field.hide !== true"
        >
          <div class="password-wrapper">
            <IonInput
              v-show="'display' in field ? field.display : true"
              :modelName="model.name"
              input-type="password"
              :name="field.name"
              :label="field.label"
              :placeholder="field.label"
              :type="showPassword ? 'text' : field.type"
              :icon="field?.icon"
              :clear-field=false
            />
            <div class="password-wrapper__icon">
              <VsxIcon
                :iconName="showPasswordIcon"
                type="linear"
                @click="viewPassword"
              ></VsxIcon>
            </div>
          </div>
        </ion-col>
        <ion-col
          size="12"
          v-if="verifyInputTextArea(field.type) && field.hide !== true"
        >
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="textarea"
            :name="field.name"
            :label="field.label"
            :placeholder="field.label"
          />
        </ion-col>
        <ion-col
          size="12"
          v-if="verifyInputCheckbox(field.type) && !field.hide"
        >
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="checkbox"
            :name="field.name"
            :label="field.label"
          >
            <template v-for="(_, name) in $slots" v-slot:[name]="data">
              <slot :name="name" v-bind="data"></slot>
            </template>
          </IonInput>
        </ion-col>
        <ion-col size="12" v-if="verifyInputToggle(field.type) && !field.hide">
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="toggle"
            :name="field.name"
            :label="field.label"
          >
          </IonInput>
        </ion-col>
        <ion-col size="12" v-if="verifyInputSelect(field.type) && !field.hide">
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="select"
            :options="field.enum || []"
            :name="field.name"
            :label="field.label"
          />
        </ion-col>
        <ion-col size="12" v-if="verifyInputDate(field.type) && !field.hide">
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="date"
            :name="field.name"
            :label="field.label"
            :icon="field?.icon"
            :placeholder="field.label"
          />
        </ion-col>
        <ion-col size="12" v-if="verifyInputTel(field.type) && !field.hide">
          <div>
            <IonInput
              v-show="'display' in field ? field.display : true"
              :modelName="model.name"
              input-type="tel"
              type="tel"
              :name="field.name"
              :label="field.label"
              :icon="field?.icon"
              :placeholder="field.label"
            />
          </div>
        </ion-col>
        <ion-col size="12" v-if="verifyInputPin(field.type) && !field.hide">    
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="model.name"
            input-type="pin"
            :name="field.name"
            :label="field.label"
            :placeholder="field.label"
          />
        </ion-col>
        <ion-col
          size="12"
          v-if="verifyInputAutocomplete(field.type) && !field.hide"
        >
          <IonInput
            v-show="'display' in field ? field.display : true"
            :modelName="field.model || ''"
            input-type="autocomplete"
            :name="field.name"
            :label="field.label"
            :placeholder="field.label"
            :field-search="field.field_search"
            @update="(value) => handleFieldValue(value, field)"
            :filters="handleGetFilters(field)"
            :icon="field.icon"
          />
        </ion-col>
      </template>
      <slot name="options"></slot>
      <ion-col size="12" class="ion-flex ion-justify-content-end">
        <slot name="cancelButton">
          <ion-button
            mode="ios"
            v-if="!noCancelButton"
            color="danger"
            @click="handleResetForm"
          >
            {{ $t("actions.cancel") }}
          </ion-button>
        </slot>
        <slot name="submitButton">
          <ion-button mode="ios" type="submit">
            {{ $t("actions.save") }}
          </ion-button>
        </slot>
      </ion-col>
    </ion-row>
  </Form>
</template>
<script lang="ts" setup>
import { IModel } from "@/interfaces/GenericModel";
import { VsxIcon } from "vue-iconsax";
import IonInput from "@/components/shared/form/InputComponent.vue";
import type { Ref } from "vue";
import { ref, toRefs, computed, onMounted, watch } from "vue";
import { Form } from "vee-validate";
import { IonButton, IonCol, IonRow } from "@ionic/vue";
import useCore from "@/composables/useCore";

import {
  getFieldValue,
  getFilters,
  setDefaultValues,
} from "@/utils/handleFieldValue";
import {
  handlePostModel,
  handlePutModel,
  handleReadModel,
} from "@/services/generic";

import {
  verifyInputCheckbox,
  verifyInputDate,
  verifyInputPassword,
  verifyInputPin,
  verifyInputSelect,
  verifyInputTel,
  verifyInputTextAndNumber,
  verifyInputTextArea,
  verifyInputAutocomplete,
  verifyInputToggle,
} from "@/utils";

interface Props {
  model: IModel;
  schema?: any;
  formMode?: string;
  hideHeaders?: Array<string>;
  idModel?: any;
  noRelations?: boolean;
  noCancelButton?: boolean;
  preventDefault?: boolean;
}

const form: Ref<any> = ref(null);
const initialValues: Ref<any> = ref(null);
const filtersArray: Ref<any[]> = ref([]);
const props = withDefaults(defineProps<Props>(), {
  formMode: "create",
});
const modelRef = toRefs(props).model;
const showPassword = ref(false);
const showPasswordIcon = ref("Eye");

const { resetForm, setResetForm } = useCore();

const emit = defineEmits(["success", "cancel"]);

const handleFieldValue = (item: any, field: any) => {
  const filter = getFieldValue(props, item, field);
  if (filter.length) {
    filtersArray.value = filter;
  }
};

const handleGetFilters = (field: any) => {
  if (field?.filters) {
    return field.filters;
  } else {
    return getFilters(filtersArray.value, field);
  }
};

const handleSubmit = async (values: any) => {
  if (!props.preventDefault) {
    if (props.formMode === "create") {
      const payload = {
        model: props.model.name,
        fields: values,
      };
      const data = await handlePostModel(payload);
      emit("success", data);
    } else {
      const payload = {
        model: props.model.name,
        fields: values,
        id: props.idModel,
      };
      const data = await handlePutModel(payload);
      emit("success", data);
    }
  } else {
    emit("success", values);
  }
};

watch(resetForm, () => {
  form.value.resetForm();
  setResetForm(false);
})

const handleSetDefaultValues = () => {
  initialValues.value = setDefaultValues(props.model.fields);
};
handleSetDefaultValues();

const handleResetForm = () => {
  form.value.resetForm();
  emit("cancel");
};

const dynamicId = computed(() => {
  return `form-${props.model.name}`;
});

const handleGetDetailModel = async () => {
  if (props.formMode === "update") {
    const payload = {
      model: props.model.name,
      id: props.idModel,
    };
    const values = await handleReadModel(payload);
    const hasNameProperty = "birthdate" in values;
    if (hasNameProperty && values.birthdate == null) {
      delete values.birthdate;
    }
    initialValues.value = values;
  }
};

const viewPassword = () => {
  showPassword.value = !showPassword.value;
  if (showPassword.value == true) {
    showPasswordIcon.value = "EyeSlash";
  } else {
    showPasswordIcon.value = "Eye";
  }
};

onMounted(() => {
  handleGetDetailModel();
});

watch(modelRef, () => {
  handleGetDetailModel();
});
defineExpose({ handleResetForm });
</script>
<style scoped lang="scss">
.password-wrapper {
  position: relative;

  &__icon {
    position: absolute;
    top: 12px;
    right: 10px;
    z-index: 999;
    color: #787f8c;
  }
}
</style>
