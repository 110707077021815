<template>
  <ion-card class="plan-wrapper">
    <ion-card-content class="plan-wrapper__content">
      <div class="plan-wrapper__header">
        <div class="plan-wrapper__price">
          <span class="plan-wrapper__price__amount">3€</span>/{{
            $t("global.month")
          }}
        </div>
        <h2 class="plan-wrapper__title">{{ $t("register.planUsersTitle") }}</h2>
        <p class="plan-wrapper__include caption">
          {{ $t("register.planIncludeNewUsers") }}
        </p>
      </div>
      <section class="plan-wrapper__options">
        <div class="plan-wrapper__options__option">
          <div>
            <ion-row
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <ion-col size="3">
                <ion-icon :icon="caretUpOutline" @click="plus" style="margin-right: 10px"></ion-icon>
              </ion-col>
              <ion-col size="7">
                <ion-input
                    :value="planUserSelected"
                  readonly
                  class="plan-wrapper__options__input"
                  size="3"
                  fill="outline"
                  label="Users"
                  type="text"
                  placeholder="1"
                ></ion-input>
              </ion-col>
              <ion-col size="2">
                <ion-icon size="6" :icon="caretDownOutline" @click="minus"></ion-icon>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </section>
      <ion-button
        mode="ios"
        expand="block"
        class="button--select ion-margin-top"
        type="button"
        @click="selectPlan"
        >{{ $t("actions.buyPlan") }}
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script setup lang="ts">
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/vue";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";
import { CoreStore, useCoreStore } from "@/stores/core";
import {PropType, ref} from "vue";
import {IPlanProduct} from "@/interfaces/plan_product/IPlanProduct";

const planUserSelected = ref(1);
const coreStore: CoreStore = useCoreStore();
const emit = defineEmits(["selected"]);

const props = defineProps({
  planProducts: {
    type: Array as PropType<IPlanProduct[]>,
    required: true,
  },
})

const selectPlan = () => {
  emit("selected", props.planProducts[planUserSelected.value - 1]);
};

const plus = () => {
  planUserSelected.value++
  if (planUserSelected.value > props.planProducts.length) {
    planUserSelected.value = props.planProducts.length
  }
};

const minus = () => {
  planUserSelected.value--
  if (planUserSelected.value < 1) {
    planUserSelected.value = 1
  }
};

</script>

<style scoped lang="scss">
ion-icon {
  font-size: 20px;
}

.plan-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  min-width: 246px;

  &.selected {
    border: 2px solid #64cde1;
  }

  &__best {
    &__icon {
      width: 15px;
      height: 16px;
    }

    &__rounded {
      margin-bottom: 0;
      padding: 6px 14px;
      border-radius: 50px;
      background: #dee6f7;
      color: #1c4197;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        text-transform: uppercase;
      }
    }
  }

  &__header {
    flex: 1;
  }

  &__price {
    &__amount {
      font-weight: bold;
      color: #464c59;
    }

    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--ion-color-primary-500);
  }

  &__content {
    padding: 24px;
    width: 100%;
    text-align: left;
  }

  &__option__icon {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;

    svg {
      height: 100%;
    }
  }

  &__options {
    margin-top: 30px;
    flex: 1;
    font: {
      size: 12px;
    }

    &__option {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font: {
      weight: 600;
      size: 30px;
    }
    line-height: 35px;
    color: #2e333d;
  }

  &__include {
    margin-top: 10px;
  }
}

.plan-wrapper__options__input {
  border: 1px solid black !important;
  color: black !important;
  text-align: center;
  padding: 3px;
  font-size: 15px;
  border-radius: 3px;
}

ion-card {
  --background: #f8f9fc;
  border-radius: 16px;
  max-width: 92%;

  ion-card-content {
    display: flex;
    flex-direction: column;
  }
}

.button--select {
  margin-top: 10px;
}
</style>
