<template>
    <ion-avatar>
        <img :src="photo ? photo : defaultPhoto" alt=""/>
    </ion-avatar>
</template>

<script setup lang="ts">
import { IonAvatar } from '@ionic/vue';
import defaultPhoto from "@/assets/default_photo.svg";
import {onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";

const props = defineProps({
    userId: {
        type: Number,
        required: true
    },
    imageId: {
        type: Number,
        required: true,
        default: -1
    },
})

const photo = ref<string | null>(null);

const {getPhoto} = useRegister();

onMounted(async () => {
    if (props.imageId !== null && props.imageId!== -1) {
        const data = await getPhoto(props.userId, props.imageId, true);
        if (data.url) {
            photo.value = data.url;
        } else {
            photo.value = null;
        }
    } else {
        photo.value = null;
    }
})

</script>

<style scoped>

</style>
