<template>
  <RegisterWrapper
    :total-steps="4"
    :show-progress="false"
    :title-button-back="$t('register.signUp')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div>
        <section class="business-wrapper__header">
          <h2 class="business-wrapper__title">
            {{ $t("register.businessInfo") }}
          </h2>
          <p class="business-wrapper__subtitle text-body-s">
            {{ $t("register.businessInfoDescriptionFinalStep") }}
          </p>
        </section>
        <FormComponent
          :model="model"
          :schema="schemaRegisterEntityFinal"
          no-cancel-button
          @success="businessInfoSubmit"
          prevent-default
        >
          <template #submitButton>
            <div ref="tour1_2" style="margin: 0px; padding: 0px; width: 100%">
              <ion-button
                mode="ios"
                expand="block"
                class="button--login ion-margin-top"
                type="submit"
                >{{ $t("actions.continue") }}
              </ion-button>
            </div>
          </template>
        </FormComponent>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaRegisterEntityFinal } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";
import { useShepherd } from "vue-shepherd";

const { email, phone, sendEntityInfo, number_employees, number_locals } =
  useRegister();
const { isPlatformIOS } = usePlatform();

const tour1_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "text",
      icon: "sms",
    },
    {
      name: "phone",
      label: i18n.global.t("register.phone"),
      type: "text",
      icon: "call",
    },
    {
      name: "number_locals",
      label: i18n.global.t("register.locals"),
      type: "text",
      icon: "copy",
    },
    {
      name: "number_employees",
      label: i18n.global.t("register.employees"),
      type: "text",
      icon: "people",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  email.value = values.email;
  phone.value = values.phone;
  number_employees.value = values.number_employees;
  number_locals.value = values.number_locals;
  await sendEntityInfo();
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_2.value, on: "bottom" },
    title: i18n.global.t("tour.tour1_2.title"),
    text: i18n.global.t("tour.tour1_2.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});
</script>
<style scoped lang="scss">
.business-wrapper {
  &__header {
    padding: 0px 5px;
  }

  &__title {
    margin-top: 60px;
  }

  &__subtitle {
    color: #5e6573;
    margin-bottom: 20px;
  }
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
