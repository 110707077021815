<template>
  <ion-page>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Listado</ion-card-title>
          <ion-card-subtitle
            >Ubicación:
            /components/shared/list/ListComponent.vue</ion-card-subtitle
          >
        </ion-card-header>
      </ion-card>
      <ListWrapper :model="model" :headers="headers" title-key="email_field">
        <template #email_field_desktop="props">
          {{ props.email_field }} + 1
        </template>
        <template #bool_field_desktop> es un boolean </template>
      </ListWrapper>
    </ion-content>
  </ion-page>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
} from "@ionic/vue";

const headers = [
  {
    label: "Booleano",
    name: "bool_field",
  },
  {
    label: "Date",
    name: "date_field",
  },
  {
    label: "Email",
    name: "email_field",
  },
  {
    label: "Status",
    name: "enum_field",
  },
];
const model = ref("dummy");
</script>
<style scoped>
ion-card {
  --background: white;
}
</style>
