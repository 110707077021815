<template>
  <div>
    <ion-menu content-id="main-content1">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $t("components") }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list v-for="(link, index) of links" :key="index">
          <ion-item button @click="$router.push(link.to)">
            <ion-label>{{ link.label }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-page id="main-content1">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-router-outlet></ion-router-outlet>
      </ion-content>
    </ion-page>
  </div>
</template>
<script lang="ts" setup>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonList,
  IonRouterOutlet,
} from "@ionic/vue";
import type { Ref } from "vue";
import { ref } from "vue";
import { ItemList } from "@/interfaces/ItemList";

const links: Ref<ItemList> = ref([
  {
    label: "Formulario",
    to: "/docs/",
  },
  {
    label: "Listado",
    to: "/docs/list/",
  },
]);
</script>
