<template>
  <ForgotPasswordWrapper>
    <template #step>
      <h2 class="forgot-password-wrapper__title">
        {{ $t("forgotPassword.changePassword") }}
      </h2>
      <p class="forgot-password-wrapper__subtitle text-body-s">
        {{ $t("forgotPassword.changePasswordDescription") }}
      </p>
      <FormComponent
        :model="model"
        :schema="schemaPassword"
        no-cancel-button
        @success="passwordSubmit"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("actions.save") }}
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </ForgotPasswordWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPassword } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { useForgotPassword } from "@/composables/useForgotPassword";
import ForgotPasswordWrapper from "@/views/forgot_password/ForgotPasswordWrapper.vue";
import i18n from "@/plugins/i18n";

const { password, sendPassword } = useForgotPassword();

const model: Ref<any> = ref({
  name: "change_password",
  fields: [
    {
      name: "password",
      label: i18n.global.t("forgotPassword.newPassword"),
      type: "password",
      icon: "key",
    },
    {
      name: "confirm_password",
      label: i18n.global.t("forgotPassword.repeatPassword"),
      type: "password",
      icon: "key",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  password.value = values.password;
  await sendPassword();
};
</script>
<style scoped lang="scss">
.button--login {
  width: 100%;
}
</style>
