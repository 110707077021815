<template>
  <ion-page>
    <ion-content class="ion-padding register-wrapper">
      <div class="register-wrapper__back">
        <ion-back-button
          v-if="showButtonBack"
          :text="titleButtonBack"
          color="neutral-80"
          defaultHref="/register"
        ></ion-back-button>
        <h3 v-else>{{ titleButtonBack }}</h3>
      </div>
      <div class="register-wrapper__help" v-if="showProgress">
        <ProgressCircle :total-steps="totalSteps" />
        <h3 class="ion-padding">{{ title }}</h3>
      </div>
      <slot name="step"></slot>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonBackButton, IonContent, IonPage } from "@ionic/vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import i18n from "@/plugins/i18n";
import { useTokenNotification } from "@/composables/useTokenNotification";
import { onMounted } from "vue";

defineProps({
  totalSteps: {
    type: Number,
    default: 3,
  },
  title: {
    type: String,
    default: i18n.global.t("register.createProfile"),
  },
  showProgress: {
    type: Boolean,
    default: true,
  },
  titleButtonBack: {
    type: String,
    default: "",
  },
  showButtonBack: {
    type: Boolean,
    default: true,
  },
});

const { initPushNotifications } = useTokenNotification();
onMounted(() => {
  initPushNotifications();
});
</script>

<style scoped lang="scss">
.register-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__help {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 60px;

    h3 {
      text-align: left;
    }
  }
}
.safe-area {
  padding-top: 40px;
}
</style>
