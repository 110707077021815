<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <ion-content class="ion-padding profile-created-wrapper">
      <section class="profile-created-wrapper__body">
        <div class="profile-created-wrapper__logo">
          <img :src="defaultPhoto" alt="">
          <p>John Doe</p>
        </div>
        <h2 class="profile-created-wrapper__title">{{ $t('register.profileCreated') }}</h2>
        <p class="profile-created-wrapper__subtitle text-body-s">{{ $t('register.profileCreatedDescription') }}</p>
        <div class="profile-created-wrapper__options">
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              @click="goHome"
          >{{ $t('actions.goTo') }}
          </ion-button>
        </div>

      </section>
      <div class="profile-created-wrapper__logo-normal">
        <img :src="wehopLogo" alt=""/>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import {useRouter} from "vue-router";
import {CoreStore, useCoreStore} from "@/stores/core";
import defaultPhoto from "@/assets/default_photo.svg";
import wehopLogo from "@/assets/wehop-logo.svg";

import {IonButton, IonContent, IonPage} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";

const coreStore: CoreStore = useCoreStore()
const {isPlatformIOS } = usePlatform();

const router = useRouter();

const goHome = async () => {
  coreStore.resetHelpStep();
  await router.push({name: "home"});
}
</script>
<style scoped lang="scss">

.profile-created-wrapper {

  &__help {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 60px;

    h3 {
      text-align: left;
    }
  }


  &__body {
    width: 100%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    font: {
      size: 14px;
      weight: 600;
    }
  }

  &__logo-normal {
    width: 100%;
    bottom: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 30%;
      object-fit: contain;
    }
  }
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
