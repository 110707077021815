<template>
    <div class="ion-flex ion-align-items-center member-wrapper" v-if="user">
        <AvatarComponent :image-id="user.image_id ?? -1" :user-id="user.id"></AvatarComponent>
        <div class="ion-padding-start">
            <ion-label>
                {{ user.name + " " + user.last_name }}
                <p>
                    {{ $t(`global.${role}`) }}
                </p>
            </ion-label>
        </div>
    </div>
</template>
<script setup lang="ts">
import {IonLabel} from "@ionic/vue";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {onMounted, ref, Ref} from "vue";
import { getRoleName, MODEL } from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {IUser} from "@/interfaces/login/ILoginResponse";

interface Props {
    item: any;
}

const props = defineProps<Props>();

const user: Ref<IUser | undefined> = ref<IUser>();
const role = ref();

onMounted(() => {
    try {
        const payload = {
            model: MODEL.USER,
            fields: ['name', 'last_name', 'phone_number', 'image_id', 'role'],
        };
        const filter = [
            {
                field: 'id',
                op: "==",
                value: props.item.user_id,
            },
        ];
        handleSearchReadModel(payload, filter).then(async (data) => {
            user.value = data.items[0] as IUser;
            role.value = getRoleName(props.item.role_id)
        })
    } catch (err) {
        console.error(err);
    }
})

</script>
<style scoped lang="scss">
.member-wrapper {
    padding: 5px;
}
</style>
