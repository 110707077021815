<template>
  <RadialProgress
      :diameter="diameter"
      :completed-steps="helpStep"
      :total-steps="totalSteps"
      :stroke-width="strokeWidth"
      :inner-stroke-width="innerStrokeWidth"
      :start-color="startColor"
      :stop-color="stopColor"
      :inner-stroke-color="innerStrokeColor"
  >
    <div class="text">{{ helpStep }} / {{ totalSteps }}</div>
  </RadialProgress>
</template>

<script lang="ts" setup>
import RadialProgress from "vue3-radial-progress";
import {CoreStore, useCoreStore} from "@/stores/core";
import {storeToRefs} from "pinia";

const coreStore: CoreStore = useCoreStore()
const {helpStep} = storeToRefs(coreStore);

defineProps(
    {
      diameter: {
        type: Number,
        default: 45
      },
      totalSteps: {
        type: Number,
        default: 4
      },
      strokeWidth: {
        type: Number,
        default: 3
      },
      innerStrokeWidth: {
        type: Number,
        default: 3
      },
      startColor: {
        type: String,
        default: "#4178F5"
      },
      stopColor: {
        type: String,
        default: "#64CDE1"
      },
      innerStrokeColor: {
        type: String,
        default: "#C7CCD6"
      }
    }
);

</script>

<style scoped lang="scss">
.text {
  font: {
    size: 12px;
  }
  letter-spacing: -1px;
  color: #1C4197;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
