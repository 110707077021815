<template>
  <RegisterWrapper :class="isPlatformIOS ? 'safe-area' : ''">
    <template #step>
      <div ref="tour1_3">
        <FormComponent
          :model="model"
          :schema="schemaRegister"
          no-cancel-button
          @success="passwordSubmit"
          prevent-default
        >
          <template #checkbox>
            <ion-label class="ion-text-wrap">
              {{ $t("register.iAccept") }}
              <router-link
                to="/register/policy-privacy"
                class="link margin-top-20"
                >{{ $t("register.thePrivacyPolicy") }}
              </router-link>
              {{ $t("register.and") }}
              <router-link to="/register/terms" class="link margin-top-20"
                >{{ $t("register.conditions") }}
              </router-link>
            </ion-label>
          </template>
          <template #submitButton>
            <div style="width: 100%; padding: 0px; margin: 0px">
              <ion-button
                mode="ios"
                expand="block"
                class="button--login ion-margin-top"
                type="submit"
                >{{ $t("actions.continue") }}
              </ion-button>
            </div>
          </template>
        </FormComponent>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton, IonLabel } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaRegister } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";
import { useShepherd } from "vue-shepherd";

const {
  phonePrefix,
  phone,
  name,
  last_name,
  password,
  confirmPassword,
  saveInfo,
} = useRegister();
const { isPlatformIOS } = usePlatform();

const tour1_3 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("register.phone"),
      type: "tel",
      icon: "call",
    },
    {
      name: "name",
      label: i18n.global.t("register.firstName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t("register.lastName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "password",
      label: i18n.global.t("register.newPassword"),
      type: "password",
      icon: "key",
    },
    {
      name: "confirm_password",
      label: i18n.global.t("register.repeatPassword"),
      type: "password",
      icon: "key",
    },
    {
      name: "accept",
      label: "Acepto la política de privacidad y los términos y condiciones ",
      type: "checkbox",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  const phoneNumber = values.phone.split("|");
  phonePrefix.value = phoneNumber[0];
  phone.value = phoneNumber[1];
  name.value = values.name;
  last_name.value = values.last_name;
  password.value = values.password;
  confirmPassword.value = values.confirm_password;
  await saveInfo();
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_3.value, on: "bottom" },
    title: i18n.global.t("tour.tour1_3.title"),
    text: i18n.global.t("tour.tour1_3.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});
</script>
<style scoped lang="scss">
.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
