<template>
  <section class="my-profile-wrapper" v-if="user">
    <PhotoEditComponent
        :user-id="user.id"
        :image-id="user.image_id ?? -1"
        class="my-profile-wrapper__photo" go-back
    />
    <p class="caption">{{ user.prefix_number }}{{ user.phone_number }}</p>
    <div class="info">
      <div>
        <p class="title__info">{{ $t("register.fullName") }}</p>
        <p>{{ user.name }} {{ user.last_name }}</p>
      </div>
      <div v-if="user.email">
        <p class="title__info">{{ $t("register.email") }}</p>
        <p>{{ user.email }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {RegisterStore, useRegisterStore} from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import {schemaUpdateRegisterEmployee} from "@/utils/getMapModel";
import {IonButton} from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import {onMounted, ref, Ref} from "vue";
import i18n from "@/plugins/i18n";
import {useRegister} from "@/composables/useRegister";
import {IUser} from "@/interfaces/login/ILoginResponse";
import {CoreStore, useCoreStore} from "@/stores/core";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const {updateEmployeeInfo, name, email} = useRegister();

const model: Ref<any> = ref({
  name: "user",
  fields: [
    {
      name: "name",
      label: i18n.global.t('register.fullName'),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t('register.email'),
      type: "email",
      icon: "sms",
    },
  ],
});

const user = ref<IUser>()

const updateInfo = (values: any) => {
  if (user.value) {
    name.value = values.name;
    email.value = values.email;
    updateEmployeeInfo(user.value.id).then(() => {
      coreStore.setSelectedUser(null);
    })
  }
}

onMounted(() => {
  registerStore.setUserSelectedPhoto("");
  if (coreStore.selectedUser != null) {
    user.value = coreStore.selectedUser
  } else {
    user.value = registerStore.user;
  }
})

</script>

<style scoped lang="scss">

.info {
  padding: 5px 5px 0px;
  width: 90%;

  div {
    padding-top: 5px;

    .title__info {
      font-size: 14px;
      font-weight: 600;
      color: #787f8c;
    }

    p {
      padding: 0px 0px;
      margin: 10px 0px;
    }
  }
}

.my-profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__photo {
    margin-top: 40px;
  }

  &__form {
    margin-top: 20px;
  }
}

.button--login {
  margin-top: 60px;
  width: 100%;
}

</style>
