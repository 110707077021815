<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <div>
      <div>
        <ol ref="tour2_3" style="padding: 0px;margin-left: 20px;">
          <li>
            <div>
              <ion-item
                :button="true"
                :detail="false"
                class="options_actions"
                @click="emitClick('click:detail')"
              >
                <VsxIcon iconName="InfoCircle" color="gray" />
                <span class="ion-padding-start"> Ver detalle </span>
              </ion-item>
            </div>
          </li>
          <li>
            <ion-item
              :button="true"
              :detail="false"
              class="options_actions"
              @click="emitClick('click:edit')"
            >
              <VsxIcon iconName="Edit2" color="gray" />
              <span class="ion-padding-start"> Editar </span>
            </ion-item>
          </li>
          <li>
            <div ref="tour2_4">
              <ion-item
                :button="true"
                :detail="false"
                class="options_actions"
                @click="emitClick('click:copy')"
              >
                <VsxIcon iconName="Copy" color="gray" />
                <span class="ion-padding-start"> {{ $t("group.copy") }} </span>
              </ion-item>
            </div>
          </li>
          <li>
            <div ref="tour2_5">
              <ion-item
                :button="true"
                :detail="false"
                class="options_actions"
                @click="emitClick('click:share')"
              >
                <VsxIcon iconName="Hierarchy2" color="gray" />
                <span class="ion-padding-start"> {{ $t("group.share") }} </span>
              </ion-item>
            </div>
          </li>
          <li>
            <div ref="tour2_6">
              <ion-item
                :button="true"
                :detail="false"
                class="options_actions"
                @click="emitClick('click:delete')"
              >
                <VsxIcon iconName="Trash" class="trash-icon" />
                <span class="ion-padding-start trash-text"> Eliminar </span>
              </ion-item>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { ref, watch } from "vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { TourStore, useTourStore } from "@/stores/tour";

const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();
const { user } = useRegister();
const { handleGetMe } = useRegister();

interface Props {
  item: any;
}

const props = defineProps<Props>();
const emit = defineEmits([
  "click:edit",
  "click:delete",
  "click:detail",
  "click:copy",
  "click:share",
]);

const tour2_3 = ref(null);
const tour2_4 = ref(null);
const tour2_5 = ref(null);
const tour2_6 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const emitClick = (event: any) => {
  emit(event, props.item);
};

watch(showDropdown, () => {

  if (showDropdown.value && !user.value.is_teams_contextual) {
    tour.addStep({
      attachTo: { element: tour2_3.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 4 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_3.title"),
      text: i18n.global.t("tour.tour2_3.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_4.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 5 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_4.title"),
      text: i18n.global.t("tour.tour2_4.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_5.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 6 });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_5.title"),
      text: i18n.global.t("tour.tour2_5.text"),
    });
    tour.addStep({
      attachTo: { element: tour2_6.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tour.next();
            tourStore.setTeamTour({ show: true, step: 7 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_teams_contextual: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
          },
        },
      ],
      title: i18n.global.t("tour.tour2_6.title"),
      text: i18n.global.t("tour.tour2_6.text"),
    });
    tour.start();
  } else {
    coreStore.setShowDropdown(false);
  }
});
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
