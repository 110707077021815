<template>
  <ion-page>
    <ion-content class="ion-padding payment-unverified">
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="ion-text-center">
          <img :src="unverifiedImage" alt="" />
          <h3 class="payment-unverified__title">
            {{ $t("payment.unverified_title") }}
          </h3>
          <p class="payment-unverified__description">
            {{ $t("payment.unverified_description") }}
          </p>
          <ion-button
            mode="ios"
            expand="block"
            :disabled="loading"
            class="button--select ion-margin-top"
            @click="verifyTransaction()"
            type="button"
          >
            {{ $t("payment.action_retry") }}
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import { IonContent, IonPage, IonButton, toastController } from "@ionic/vue";
import unverifiedImage from "@/assets/unverified_payment.png";
import { usePaymentStore } from "@/stores/payment";
import { useRouter } from "vue-router";
import { post } from "@/services/api";
import i18n from "@/plugins/i18n";
import { ref } from "vue";
import { useCoreStore } from "@/stores/core";

const router = useRouter();
const { transaction } = usePaymentStore();
const coreStore = useCoreStore();
const loading = ref();

const verifyTransaction = async () => {
  loading.value = true;
  try {
    await post({ url: "/payment/check", payload: transaction });
    await router.push({ name: "successPayment" });
  } catch {
    const toast = await toastController.create({
      message: i18n.global.t("payment.retry_verification"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped lang="scss">
.payment-unverified {
  --padding-start: 24px;
  --padding-end: 24px;

  &__title {
    margin-top: 32px;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 56px;
    color: #5e6573;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
