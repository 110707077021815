<template>
  <ion-avatar v-if="userData" class="avatar-wrapper" :style="`width:${width}px;height:${height}px`">
    <img :src="photo" :alt="userData.first_name"/>
  </ion-avatar>
</template>

<script setup lang="ts">
import {IonAvatar} from '@ionic/vue';
import defaultPhoto from "@/assets/avatar_default.svg";
import defaultPhotoAdd from "@/assets/default_photo.svg";
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {CoreStore, useCoreStore} from "@/stores/core";
import {IUser} from "@/interfaces/login/ILoginResponse";


const props = defineProps({
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  isNew: {
    type: Boolean,
    default: false
  }
})

const photo = ref<string | null>(props.isNew ? defaultPhotoAdd : defaultPhoto);
const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const {getPhoto, userSelectedPhoto} = useRegister();
const userData = ref<IUser|null>();

watch(userSelectedPhoto, () => {
  if (userSelectedPhoto.value !== "") {
    photo.value = userSelectedPhoto.value !== ""? userSelectedPhoto.value : defaultPhoto;
  }
});

onMounted(async () => {
  userData.value = coreStore.selectedUser ?? null;
  if (userData.value?.image_id == null) {
    photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
  } else {
    if (registerStore.userSelectedPhoto == "") {
      const data = await getPhoto(userData.value.id, userData.value.image_id, true);
      if (data.url) {
        registerStore.setUserSelectedPhoto(data.url);
        photo.value = data.url;
      } else {
        photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
      }
    } else {
      photo.value = registerStore.userSelectedPhoto !== ""? registerStore.userSelectedPhoto : defaultPhoto;
    }
  }
})

</script>

<style scoped lang="scss">
.avatar-wrapper {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
</style>
