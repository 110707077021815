<template>
  <div class="container-avatar">
    <ion-avatar slot="start" class="ion-margin-vertical">
      <img alt="User Image" :src="image" />
    </ion-avatar>
    <div class="label-avatar">
      <ion-label v-if="label" class="label-text">
        {{ label }}
      </ion-label>
      <ion-label class="label-user">
        {{ fullName }}
      </ion-label>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { handleReadModel } from "@/services/generic";
import { IonAvatar, IonLabel } from "@ionic/vue";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import { useRegister } from "@/composables/useRegister";
import { getImageLetter } from "@/utils/getImageLetter";

interface Props {
  userId: number | null;
  label?: string;
}
const props = defineProps<Props>();
const image: Ref<string | undefined> = ref(undefined);
const fullName: Ref<string | undefined> = ref("");
const { getPhoto } = useRegister();

const getUserInfo = async () => {
  const payload = {
    id: props.userId,
    model: "user",
  };
  const { id, name, last_name, image_id } = await handleReadModel(payload);
  fullName.value = `${name} ${last_name}`;
  if (image_id) {
    const data = await getPhoto(id, image_id, true);
    if (data.url) {
      image.value = data.url;
    } else {
      image.value = getImageLetter(name);
    }
  } else {
    image.value = image_id ? "" : getImageLetter(name);
  }
};
onMounted(() => {
  getUserInfo();
});
</script>

<style scoped lang="scss">
.label-avatar {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  color: #787f8c;
  .label-text {
    font-size: 12px;
  }
  .label-user {
    font-size: 14px;
    font-weight: 600;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.container-avatar {
  display: flex;
  align-items: center;
}
</style>
