<template>
  <div class="ion-padding ion-justify-content-start">
    <table>
      <thead align="left">
        <tr>
          <th v-if="checkable" class="check-tr">
            <ion-checkbox></ion-checkbox>
          </th>
          <template v-for="(item, index) of headers" :key="index">
            <th>{{ item.label }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) of items"
          :key="index"
          :class="classDynamic(item?.extraData)"
        >
          <template v-for="(header, index) in headers" :key="index">
            <td v-if="checkable && index === 0" class="check-tr">
              <ion-checkbox
                :data-check="item?.extraData?.selected || false"
                :checked="item?.extraData?.selected || false"
              ></ion-checkbox>
            </td>
            <td>
              <slot :name="`${header.name}_desktop`" v-bind="item">
                {{ item[header.name] }}
              </slot>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <a
        href="#"
        class="pagination--next"
        :class="{ 'disabled-a': pagination.page === 1 }"
        @click.prevent="selectPage(pagination.page - 1)"
      >
        <VsxIcon iconName="ArrowLeft" type="linear"></VsxIcon>
      </a>
      <template v-for="index in mapPagination" :key="index">
        <a
          href="#"
          class="pagination--page"
          @click.prevent="selectPage(index)"
          :class="{ active: index === pagination.page }"
          >{{ index }}</a
        >
      </template>
      <a
        href="#"
        class="pagination--back"
        :class="{
          'disabled-a': pagination.page === totalPage[totalPage.length - 1],
        }"
        @click.prevent="selectPage(pagination.page + 1)"
      >
        <VsxIcon iconName="ArrowRight" type="linear"></VsxIcon
      ></a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, watch, computed, toRefs } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import { Item, Pagination } from "@/interfaces/GenericModel";
import { IonCheckbox } from "@ionic/vue";
import { calculatePagesCount } from "@/utils/calculatePageCount";

interface Props {
  items: Array<Item>;
  hideHeaders: Array<string> | [] | undefined;
  headers: Array<Item>;
  pagination: Pagination;
  checkable?: boolean;
}

const props = defineProps<Props>();
const totalPage: Ref<number[]> = ref([]);
const emit = defineEmits(["page"]);
const paginationProps = toRefs(props).pagination;
const classDynamic = (item: any) => {
  const classD = [];
  if (item?.disabled) {
    classD.push("disabled-tr");
  }
  if (item?.selected) {
    classD.push("selected-tr");
  }
  return classD.join(" ");
};

const selectPage = (page: number) => {
  emit("page", { page });
};

const mapPagination = computed(() => {
  const page = props.pagination.page;
  if (page > 3) {
    return totalPage.value.slice(page - 3, page + 2);
  }
  return totalPage.value.slice(0, 5);
});

const setPagination = () => {
  if (props.pagination.total) {
    const cal = calculatePagesCount(
      props.pagination.size,
      props.pagination.total
    );
    totalPage.value = Array.from({ length: cal }, (_, i) => i + 1);
  }
};

watch(paginationProps, () => {
  setPagination();
});
onMounted(() => {
  setPagination();
});
</script>
<style scoped>
a.disabled-a {
  opacity: 0.5;
  pointer-events: none;
}
tr.disabled-tr {
  opacity: 0.5;
  pointer-events: none;
}

tr.selected-tr {
  background-color: #d8e3fd;
}
.pagination {
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f3f5fa;
  color: #5e6573;
  border-radius: 8px;
}

.pagination a.pagination--page,
a {
  color: black;
  float: left;
  padding: 8px 16px;
  margin-left: 3px;
  margin-right: 3px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #dee6f7;
  color: #1c4197;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
table {
  width: 100%;
  border-collapse: seperate;
}
td,
th {
  padding: 16px;
  font-weight: 400;
}

thead > tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

thead > tr:last-child th:first-child {
  border-bottom-left-radius: 10px;
  margin-bottom: 20px;
}

thead > tr:last-child th:last-child {
  border-top-right-radius: 10px;
}

thead > tr:last-child th:last-child {
  border-bottom-right-radius: 10px;
}

tr {
  padding-top: 20px;
}

tr:first-child > td:first-child {
  border-top-left-radius: 10px;
}

tr:first-child > td:last-child {
  border-top-right-radius: 10px;
}

tbody::before {
  content: "";
  display: table-row;
  background-color: var(--ion-background-color);
  height: 20px;
}

table > thead {
  background-color: #f3f5fa;
  color: #5e6573;
}

table > tbody {
  background-color: #f3f5fa;
  color: #5e6573;
  border-radius: 8px 8px 0px 0px;
}
</style>
