<template>
  <section class="box-wrapper" v-if="entityInfo">
    <h2>{{ $t('myProfile.myCurrentPlan') }}:</h2>
    <CurrentPlan/>
    <hr/>
    <div class="cards-business-wrapper">
      <CardBusinessInfo icon="shop" :value="locales" :text="$t('global.locals')" />
      <div style="width: 20px"></div>
      <CardBusinessInfo icon="people" :value="users" :text="$t('global.users')" />
    </div>
  </section>
</template>

<script setup lang="ts">
import {IonCardContent, IonButton} from "@ionic/vue";
import CurrentPlan from "@/components/shared/CurrentPlan.vue";
import router from "@/router";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {computed, onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";
import {ILocation} from "@/interfaces/locale/ILocation";
import {IEntityResponse} from "@/interfaces/entity/IEntityResponse";
import {useLocation} from "@/composables/useLocation";

const updateBusinessInfo = () => {
  router.push({name: 'businessUpdateInfoView'})
}

const {user} = useRegister();
const { getCountries, getCities, countries, cities } = useLocation();

const country = computed(() => {
  return countries.value[0] ?? null;
});
const city = computed(() => {
  return cities.value[0] ?? null;
});

const locales = ref();
const localesIds = ref();
const users = ref();
const entityInfo = ref<IEntityResponse>();

const getLocales = async () => {
  try {
    const payload = {
      model: MODEL.LOCATION,
      fields: ['id', 'name', 'code', 'image_id'],
    };
    const filter = [
      {
        field: 'entity_id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then((data) => {
      locales.value = data.items.length;
      localesIds.value = data.items.map((item:ILocation) => item.id);
      const payload = {
        model: MODEL.USERLOCATION,
        fields: ['id', 'name', 'code'],
      };
      const filter = [
        {
          field: 'location_id',
          op: "in",
          value: localesIds.value,
        },
      ];
      handleSearchReadModel(payload, filter).then((data) => {
        users.value = data.items.length;
      })
    })
  } catch (err) {
    console.error(err);
  }
};

const getEntity = async () => {
  try {
    const payload = {
      model: MODEL.ENTITY,
      fields: ['id', 'name', 'commercial_name', 'address', 'country_id', 'city_id', 'vat', 'phone_number', 'email'],
    };
    const filter = [
      {
        field: 'id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      entityInfo.value = data.items[0] as IEntityResponse;
      await getCountries(entityInfo.value.country_id);
      await getCities(entityInfo.value.city_id);
    })
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getLocales();
  getEntity();
})

</script>

<style scoped lang="scss">

.info {
  padding: 10px 5px 5px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  div {
    padding-top: 5px;

    .title__info {
      font-size: 14px;
      font-weight: 600;
      color: #787f8c;
    }
  }
}

.cards-business-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

hr {
  background: rgba(199, 204, 214, 0.5);
}

h1 {
  font-size: 20px;
  color: #5E6573
}

h2 {
  font-size: 16px;
}

.box-wrapper {

  &__update {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item__box {
    width: 200px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 8px 16px 16px;
    background: #f8f9fc;
    border: 1px solid #eceef3;
    border-radius: 8px;

    h2 {
      font-size: 16px;
    }

    p {
      color: #787f8c;
    }
  }
}

.plan-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  min-width: 90%;

  &.selected {
    border: 2px solid #64CDE1;
  }

  &__best {

    &__icon {
      width: 15px;
      height: 16px;
    }

    &__rounded {
      margin-bottom: 0;
      padding: 6px 14px;
      border-radius: 50px;
      background: #DEE6F7;
      color: #1C4197;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        text-transform: uppercase;
      }
    }
  }

  &__header {
    flex: 1;
  }

  &__price {
    &__amount {
      font-weight: bold;
      color: #464C59;
    }

    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--ion-color-primary-500);
  }

  &__content {
    padding: 24px;
    width: 100%;
    text-align: left;

    &__body {
      display: flex;
      align-items: center;

    }
  }

  &__option__icon {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;

    svg {
      height: 100%;
    }
  }

  &__options {
    margin-top: 30px;
    flex: 1;
    font: {
      size: 12px;
    }

    &__option {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        padding-left: 10px;
      }
    }
  }

  &__title {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font: {
      weight: 600;
      size: 30px;
    }
    line-height: 35px;
    color: #2E333D
  }

  &__include {
    margin-top: 10px;
  }

}

ion-card {
  --background: #F8F9FC;
  border-radius: 16px;
  max-width: 230px;

  ion-card-content {
    display: flex;
    flex-direction: column;
  }
}


.button--login {
  margin-top: 60px;
  width: 100%;
}

</style>
