<template>
  <div class="user-wrapper">
    <div class="user-wrapper__checkbox">
      <ion-checkbox :checked="isChecked" @click="removeUser"></ion-checkbox>
    </div>
    <div class="user-wrapper__name">{{ user.user_name }}</div>
  </div>
</template>
<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { IUserMember } from "@/interfaces/ITeamMember";
import {
  handleDeleteModel,
  handlePostModel,
  handlePutModel,
} from "@/services/generic";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { getSubscriptionAvailability } from "@/services/subscription";
import { CoreStore, useCoreStore } from "@/stores/core";
import { toastController, IonCheckbox } from "@ionic/vue";
import i18n from "@/plugins/i18n";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const props = defineProps({
  user: {
    type: Object as PropType<IUserMember>,
    required: true,
  },
  locationId: {
    type: Number,
    required: true,
  },
});

const isChecked = ref<boolean>(props.user?.user_location_id != null);
const userLocationId = ref<number>(props.user?.user_location_id);

const removeUser = async () => {
  if (isChecked.value == false) {
    ///Add User
    if (coreStore.subscriptionAvailability?.users_status) {
      if (coreStore.subscriptionAvailability?.users_status?.number_users >= 6) {
        isChecked.value = false;
        const toast = await toastController.create({
          message: i18n.global.t("group.rememberLimitUsers"),
          duration: 1500,
          position: "bottom",
          mode: "ios",
        });
        await toast.present();
      } else {
        await handlePostModel({
          model: MODEL.USERLOCATION,
          fields: {
            location_id: props.locationId,
            role_id: ROLE_ID.EMPLOYEE,
            user_id: props.user?.user_id,
            workdays: "Horario de Lunes a Viernes de 09:00 a 16:00",
            shift_days: "Turno: Lunes, Miércoles, Viernes",
          },
        }).then(async (data: IUserLocation) => {
          if (data.id) {
            userLocationId.value = data.id;
          } else {
            userLocationId.value = 0;
          }
          isChecked.value = true;
          await getSubscriptionAvailability();
        });
      }
    }
  } else {
    ///Remove User
    await handleDeleteModel({
      model: MODEL.USERLOCATION,
      id: userLocationId.value,
    }).then(async () => {
      isChecked.value = false;
      await getSubscriptionAvailability();
    });
  }
};
</script>
<style scoped lang="scss">
ion-checkbox {
  --size: 20px;
  --checkbox-background-checked: #6815ec;
}

.user-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__checkbox {
    width: 10%;
    margin-right: 10px;
  }

  &__name {
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}
</style>
