<template>
  <RegisterWrapper
    :show-button-back="false"
    :show-progress="false"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div v-if="users">
        <h3 class="downgrade-title">
          {{ $t("payment.planChangeUsersTitle") }}
        </h3>
        <p
          v-if="users.items"
          class="downgrade-description caption"
          v-html="
            $t('payment.planChangeTitleDescriptionOption3', {
              users: users.items.length,
            })
          "
        ></p>
        <section v-if="users.items">
          <ul class="downgrade-users">
            <li v-for="user in users.items" :key="user">
              <MemberInfo :user="user" :location-id="registerStore.user?.location_id ?? -1" />
            </li>
          </ul>
        </section>
        <ion-row>
          <ion-col size="12">
            <ion-button
              color="primary"
              :disabled="coreStore.subscriptionAvailability?.users_status?.number_users > 5"
              expand="block"
              @click="goFinish"
            >{{ $t("actions.finish") }}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import { computed, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handleUsersByLocationData } from "@/services/user_location";
import { ILocationResult } from "@/interfaces/ILocationResult";
import { handleUsersByIdsData } from "@/services/user";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { ITeamMember } from "@/interfaces/ITeamMember";
import { getTeamMembers } from "@/services/subscription";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import MemberInfo from "@/components/shared/MemberInfo.vue";

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();


const users = computed<ITeamMember>(() => coreStore.teamMembers);

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goFinish = async () => {
  await router.replace({ name: "registerPlanUsersFinishDowngrade" });
};

onMounted(async () => {
  if (registerStore.user.location_id) {
    await getTeamMembers(registerStore.user.location_id);
  }
});
</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }
}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;

    &.center {
      width: 100% !important;
    }
  }
}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

.plan-blocked {
  background-color: #d3d3d3;
  color: #a1a1a1;
  pointer-events: none;
  opacity: 0.7;
}

.downgrade-title {
  text-align: center;
  margin-top: 20px;
}

.downgrade-description {
  text-align: center;
}

.downgrade-button {
  margin-top: 40px;
}

.downgrade-users {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  border: 1px solid blue;
  padding: 10px;
  border-radius: 10px;

  > li {
    list-style: none;
    width: 85%;
    height: 35px;
    display: flex;
  }
}

</style>
