<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="Pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <ol>
      <li ref="tourContextualCopy">
        <ion-item
          :button="true"
          :detail="false"
          class="options_actions"
          id="group_copy"
          @click="emitClick('click:copy')"
        >
          <VsxIcon iconName="Copy" color="gray" />
          <span class="ion-padding-start"> {{ $t("group.copy") }} </span>
        </ion-item>
      </li>
      <li ref="tourContextualShare">
        <ion-item
          :button="true"
          :detail="false"
          class="options_actions"
          id="group_share"
          @click="emitClick('click:share')"
        >
          <VsxIcon iconName="Hierarchy2" color="gray" />
          <span class="ion-padding-start"> {{ $t("group.share") }} </span>
        </ion-item>
      </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import useCore from "@/composables/useCore";
import { ref, watch } from "vue";

const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();
const { handleGetMe } = useRegister();

const emit = defineEmits(["click:copy", "click:share"]);

const tourContextualCopy = ref(null);
const tourContextualShare = ref(null);

const tourContextualWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};

watch(showDropdown, () => {
  try {
    if (showDropdown.value && !user.value.is_members_contextual) {
      tourContextualWrapper.addStep({
        attachTo: { element: tourContextualCopy.value, on: "top" },
        buttons: [
          {
            text: i18n.global.t("actions.accept"),
            action() {
              tourStore.setTalkieContextualTour({ show: true, step: 1 });
              tourContextualWrapper.next();
            },
          },
        ],
        title: i18n.global.t("tour.tour4_4.title"),
        text: i18n.global.t("tour.tour4_4.text"),
      });
      tourContextualWrapper.addStep({
        attachTo: { element: tourContextualShare.value, on: "top" },
        buttons: [
          {
            text: i18n.global.t("actions.accept"),
            action() {
              tourStore.setTalkieContextualTour({ show: true, step: 2 });
              coreStore.setShowDropdown(false);
              handlePutModel({
                model: MODEL.USER,
                id: registerStore.user.id,
                fields: {
                  is_members_contextual: true,
                },
              }).then(async () => {
                await handleGetMe();
              });
              tourContextualWrapper.next();
            },
          },
        ],
        title: i18n.global.t("tour.tour4_5.title"),
        text: i18n.global.t("tour.tour4_5.text"),
      });
      tourContextualWrapper.start();
    }
  } catch (error) { /* empty */ }
});
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
