<template>
  <RegisterWrapper
    :show-button-back="false"
    :show-progress="false"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div class="finish-wrapper">
        <h3 class="downgrade-title">
          {{ $t("global.ready") }}
        </h3>
        <p
          class="downgrade-description"
          v-html="
            $t('payment.downgradeTeamFinished', {locale_name: localeName})
          "
        ></p>
        <ion-row>
          <ion-col size="12">
            <ion-button
              expand="block"
              @click="goHome"
            >{{ $t("actions.goToDashboard") }}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
const { isPlatformIOS } = usePlatform();
import { useRouter } from "vue-router";

import "cordova-plugin-purchase";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { computed } from "vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
const router = useRouter();

const registerStore: RegisterStore = useRegisterStore();
 const localeName = computed(() => {
   return registerStore.location.name ?? '';
 });

const goHome = async () => {
  await router.replace({ name: "admin.home" });
};

</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }
}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;

    &.center {
      width: 100% !important;
    }
  }
}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

.plan-blocked {
  background-color: #d3d3d3;
  color: #a1a1a1;
  pointer-events: none;
  opacity: 0.7;
}

.downgrade-title {
  text-align: center;

}

.downgrade-description {
  text-align: center;
}

.downgrade-button {
  margin-top: 40px;
}

.downgrade-users {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  border: 1px solid blue;
  padding: 10px;
  border-radius: 10px;

  > li {
    list-style: none;
    width: 85%;
    height: 35px;
    display: flex;
  }
}

.finish-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

</style>
