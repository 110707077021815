<template>
  <section class="my-profile-wrapper">
    <PhotoEditComponent
      :user-id="registerStore.user.id"
      :image-id="registerStore.user.image_id ?? -1"
      class="my-profile-wrapper__photo"
      go-back
    />
    <p class="caption">{{ registerStore.user.phone_number }}</p>
    <section class="my-profile-wrapper__form">
      <FormComponent
        ref="form"
        :model="model"
        :schema="schemaUpdateRegisterOwner"
        form-mode="update"
        :id-model="registerStore.user.id"
        no-cancel-button
        @success="updateInfo"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("actions.update") }}
          </ion-button>
        </template>
      </FormComponent>
      <a
        exact-active-class="active"
        @click.prevent="handlerDeleteUser()"
        id="delete_user_button"
        class="my-profile-wrapper caption"
      >
        <span>{{ i18n.global.t("global.deleteUser") }}</span>
      </a>
    </section>
  </section>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import { schemaUpdateRegisterOwner } from "@/utils/getMapModel";
import { IonButton, alertController } from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handlerServiceDeleteUser } from "@/services/user";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import useCore from "@/composables/useCore";
import router from "@/router";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { updateOwnerInfo, name, last_name, email, birthday } = useRegister();
const { setResetPinDigit, setResetForm } = useCore();
const { clearUserStore } = useRegister();

const form: Ref<any> = ref(null);
const addLocationResult: Ref<IActionShareResult | undefined> = ref();

const model: Ref<any> = ref({
  name: "user",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.fullName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t("register.lastName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "email",
      icon: "sms",
    },
    {
      name: "birthdate",
      label: i18n.global.t("register.birthday"),
      type: "date",
      icon: "calendar",
    },
  ],
});

const updateInfo = (values: any) => {
  name.value = values.name;
  last_name.value = values.last_name;
  email.value = values.email;
  birthday.value = values.birthdate;
  updateOwnerInfo(registerStore.user.id).then(() => {
    coreStore.setRefreshUserLocation(true);
  });
};

const handlerDeleteUser = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.deleteUserHeader"),
    message: i18n.global.t("global.deleteUserBody"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("actions.confirm"),
        cssClass: "wehop-alert-confirm",
        handler: async () => {
          try {
            handlerServiceDeleteUser().then(() => {
              setResetPinDigit(true);
              setResetForm(true);
              clearUserStore();
              setTimeout(async () => {
                await router.push({ name: "login" });
              }, 500);
            });
          } catch (e) {
            console.error(e);
          }
        },
      },
      {
        text: i18n.global.t("actions.cancel"),
        cssClass: "wehop-alert-cancel",
      },
    ],
  });

  await alert.present();

  console.log(alert);
};
</script>

<style scoped lang="scss">
#delete_user_button {
  color: #750a00;
}

.my-profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__photo {
    margin-top: 40px;
  }

  &__form {
    margin-top: 20px;
  }
}

.button--login {
  margin-top: 60px;
  width: 100%;
}
</style>
