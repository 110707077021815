<template>
  <div id="application-pending">
    <ion-col size="12" :class="{ 'ion-padding-top ion-no-padding': isMobile }">
      <ListWrapper :model="model.name" :headers="headers">
        <template #item_mobile="props ">
          <div
            class="
              ion-flex ion-justify-content-between ion-align-items-center
              group-member-wrapper
            "
          >
            <MemberWrapper :item="props" />
            <DropdownMenuMemberComponent
              v-if="props"
              :item="props"
              :show-delete="parseInt(props.role_id) !== ROLE_ID.OWNER"
              :is-owner="parseInt(props.role_id) === ROLE_ID.OWNER"
              @click:detail="openDetail(props)"
              @click:edit="openEdit(props)"
              @click:delete="openDelete(props)"
            />
          </div>
        </template>
      </ListWrapper>
    </ion-col>
    <ion-alert
      :is-open="isOpen"
      :header="$t('group.deleteUserConfirm')"
      :message="$t('group.deleteUserConfirmMessage')"
      :buttons="alertButtons"
      @didDismiss="setOpen(false)"
    ></ion-alert>
  </div>
</template>

<script setup lang="ts">
import { IonAlert, IonCol } from "@ionic/vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { ref, Ref, watch } from "vue";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import { useGenericStore } from "@/stores/generic";
import { handleDeleteModel, handleGetDescribeModel, handlePutModel, handleSearchReadModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import MemberWrapper from "@/components/admin/group/MemberWrapper.vue";
import DropdownMenuMemberComponent from "@/components/admin/group/DropdownMenuMemberComponent.vue";
import router from "@/router";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { CoreStore, useCoreStore } from "@/stores/core";
import useCore from "@/composables/useCore";
import { useList } from "@/composables/useList";

const { isMobile } = useNativePlatform();
const storeList = useGenericStore();
const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { refreshUserLocation } = useCore();
const { getModelData } = useList();

const headers = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: i18n.global.t("joinTeam.userId"),
    name: "user_id",
  },
  {
    label: i18n.global.t("joinTeam.code"),
    name: "location_id",
  },
  {
    label: "",
    name: "role_id",
  },
  {
    label: "",
    name: "image_id",
  },
  {
    label: "",
    name: "actions",
  },
];

const model: Ref<any> = ref({
  name: MODEL.USERLOCATION,
  fields: [],
});

const isOpen = ref(false);
const locationId = ref("");
const userId = ref("");
const alertButtons = [
  {
    text: i18n.global.t("actions.no"),
  },
  {
    text: i18n.global.t("actions.confirmDelete"),
    cssClass: "alert-button-confirm",
    handler: async () => {
      await handleDeleteModel({
        model: MODEL.USERLOCATION,
        id: locationId.value,
      }).then(async () => {
        router.go(0);
      });
    },
  },
];

const userInfo = ref<IUser>();

storeList.setItemsModel(MODEL.USERLOCATION, []);
storeList.setFiltersModel(MODEL.USERLOCATION, [
  {
    field: "location_id",
    op: "==",
    value: registerStore.location.id,
  },
]);


const openDetail = (item: any) => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "id",
        "name",
        "last_name",
        "role",
        "prefix_number",
        "phone_number",
        "email",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      userInfo.value = data.items[0] as IUser;
      coreStore.setSelectedUser(userInfo.value);
      if (userInfo.value.role == ROLES.OWNER) {
        await router.push({
          name: "myProfileOwner",
          query: { mode: "detail" },
        });
      } else if (userInfo.value.role == ROLES.MANAGER) {
        await router.push({
          name: "myProfileManager",
          query: { mode: "detail" },
        });
      } else {
        await router.push({
          name: "myProfileEmployee",
          query: { mode: "detail" },
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
};

const openEdit = (item: any) => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "id",
        "name",
        "last_name",
        "role",
        "prefix_number",
        "phone_number",
        "email",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      userInfo.value = data.items[0] as IUser;
      coreStore.setSelectedUser(userInfo.value);
      if (userInfo.value.role == ROLES.OWNER) {
        await router.push({ name: "myProfileOwner", params: { mode: "edit" } });
      } else if (userInfo.value.role == ROLES.MANAGER) {
        await router.push({
          name: "myProfileManager",
          params: { mode: "edit" },
        });
      } else {
        await router.push({
          name: "myProfileEmployee",
          params: { mode: "edit" },
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
};

const setOpen = (state: boolean) => {
  isOpen.value = state;
};

const openDelete = async (item: any) => {
  locationId.value = item.id;
  userId.value = item.user_id;
  isOpen.value = true;
};

const getModel = (payload?: any) => {
  getModelData({
    headers: headers,
    modelName: MODEL.USERLOCATION,
    reset: payload?.reset,
  });
};

watch(refreshUserLocation, () => {
  storeList.setItemsModel(MODEL.USERLOCATION, []);
  storeList.setRefresh(true);
  if (refreshUserLocation.value) {
    storeList.setItemsModel(MODEL.USERLOCATION, []);
    getModel({ reset: true });
    storeList.$reset();
    coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
  }
});
</script>

<style scoped lang="scss">
.group-member-wrapper {
  padding: 5px 15px;
}

button.alert-button.alert-button-confirm {
  background: #cf2617;
  color: #cf2617;
}
</style>
