<template>
  <div :class="isPlatformIOS ? 'safe-area' : ''">
    <div class="photo-wrapper">
      <PhotoLocalComponent
        :image-id="item?.image_id"
        :local-id="item?.id"
      ></PhotoLocalComponent>
    </div>
    <div class="box">
      <div class="item__box">
        <h2>{{ item?.roles_stats?.owner }}</h2>
        <p>{{ $t("joinTeam.ownersRole") }}</p>
      </div>
      <div class="item__box">
        <h2>{{ item?.roles_stats?.manager }}</h2>
        <p>{{ $t("joinTeam.managersRole") }}</p>
      </div>
      <div class="item__box">
        <h2>{{ item?.roles_stats?.member }}</h2>
        <p>{{ $t("joinTeam.employeesRole") }}</p>
      </div>
    </div>
    <div class="info">
      <div>
        <p class="title__info">{{ $t("joinTeam.sector") }}</p>
        <p>{{ item.local_type }}</p>
      </div>
      <div v-if="country">
        <p class="title__info">{{ $t("register.country") }}</p>
        <p>{{ country.name }}</p>
      </div>
      <div v-if="city">
        <p class="title__info">{{ $t("register.city") }}</p>
        <p>{{ city.name }}</p>
      </div>
      <div>
        <p class="title__info">{{ $t("register.address") }}</p>
        <p>{{ item.address }}</p>
      </div>
      <div>
        <p class="title__info">{{ $t("register.phone") }}</p>
        <p>{{ item.phone_number }}</p>
      </div>
      <div>
        <p class="title__info">{{ $t("register.corporateEmail") }}</p>
        <p>{{ item.email }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useLocation } from "@/composables/useLocation";
import PhotoLocalComponent from "@/components/shared/PhotoLocalComponent.vue";
import { usePlatform } from "@/composables/usePlatform";

interface Props {
  item: any;
}

const props = defineProps<Props>();
const { getCountries, getCities, countries, cities } = useLocation();
const { isPlatformIOS } = usePlatform();

const country = computed(() => {
  return countries.value[0];
});
const city = computed(() => {
  return cities.value[0];
});

onMounted(async () => {
  await getCountries(props.item.country_id);
  await getCities(props.item.city_id);
});
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;

  .item__box {
    width: 180px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    padding: 8px 16px 10px;
    background: #f8f9fc;
    border: 1px solid #eceef3;
    border-radius: 8px;

    p {
      color: #787f8c;
    }
  }
}

.info {
  padding: 5px 5px 0px;

  div {
    padding-top: 5px;

    .title__info {
      font-size: 14px;
      font-weight: 600;
      color: #787f8c;
    }

    p {
      padding: 0px 0px;
      margin: 10px 0px;
    }
  }
}

.photo-wrapper {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.safe-area {
  padding-top: 10px;
}
</style>
