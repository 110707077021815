<template>
  <ion-modal
    v-if="modelValue"
    mode="ios"
    :is-open="modelValue"
    ref="modal"
    @willDismiss="closeModal"
    v-bind="$attrs"
  >
    <div class="modal__wrapper modal-content ion-padding">
      <div class="ion-flex ion-justify-content-end" v-if="!noIconClose">
        <VsxIcon
          iconName="CloseCircle"
          class="pointer"
          color="gray"
          @click="closeModal"
        />
      </div>
      <slot></slot>
    </div>
  </ion-modal>
</template>
<script setup lang="ts">
import { IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";

interface Props {
  modelValue: boolean;
  noIconClose?: boolean;
}

defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const closeModal = () => {
  emit("update:modelValue", false);
};
</script>
<style scoped lang="scss">

.modal__wrapper {
  overflow: auto;
  height: 100%;
  color: var(--ion-color-neutral-90);
  h3 {
    text-align: left;
  }
}
ion-modal {
  --max-width: 520px;
}
</style>
