<template>
  <div class="container-avatar">
    <div>
      <ion-avatar slot="start">
        <img alt="User Image" :src="image" />
      </ion-avatar>
    </div>
    <div class="label-avatar">
      <slot :fullName="fullName"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { handleReadModel } from "@/services/generic";
import { IonAvatar, IonLabel } from "@ionic/vue";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import { useRegister } from "@/composables/useRegister";
import { getImageLetter } from "@/utils/getImageLetter";

interface Props {
  userId: number | undefined;
  label?: string;
}
const props = defineProps<Props>();
const image: Ref<string | undefined | null> = ref(undefined);
const fullName: Ref<string | undefined> = ref("");
const { getPhoto } = useRegister();

const getUserInfo = async () => {
  const payload = {
    id: props.userId,
    model: "user",
  };
  const { id, name, last_name, image_id } = await handleReadModel(payload);
  fullName.value = `${name} ${last_name}`;
  if (image_id) {
    const data = await getPhoto(id, image_id, true);
    if (data.url) {
      image.value = data.url;
    } else {
      image.value = getImageLetter(name);
    }
  } else {
    image.value = image_id ? "" : getImageLetter(name);
  }
};
onMounted(() => {
  getUserInfo();
});
</script>

<style scoped lang="scss">
.container-avatar {
  display: flex;
  ion-avatar {
    width: 24px;
    height: 24px;
  }
}
.label-avatar {
  padding-left: 10px;
}
</style>
