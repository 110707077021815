<template>
  <ion-avatar :style="`width:${width}px;height:${height}px`">
    <img :src="photo ? photo : getImageLetter(localeName ?? 'LO')"/>
  </ion-avatar>
</template>

<script setup lang="ts">
import {IonAvatar} from '@ionic/vue';
import {onMounted, ref} from "vue";
import {useRegister} from "@/composables/useRegister";
import {getImageLetter} from "@/utils/getImageLetter";

const props = defineProps({
  localeId: {
    type: Number,
    required: true
  },
  imageId: {
    type: Number as () => number | null,
    default: -1
  },
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  localeName: {
    type: String,
    required: true,
    default: ""
  },
})

const photo = ref<string | null>(null);

const {getLocalePhoto} = useRegister();

onMounted(async () => {
  if (props.imageId !== null && props.imageId !== -1) {
    const data = await getLocalePhoto(props.localeId, props.imageId, true);
    if (data.url) {
      photo.value = data.url;
    } else {
      photo.value = null;
    }
  } else {
    photo.value = null;
  }
})

</script>

<style scoped>

</style>
