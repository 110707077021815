<template>
    <ModalDeleteComponent
            v-if="modelValue"
            :name-item="item.name"
            :model-value="modelValue"
            @update:modelValue="handleCloseModal"
            @confirm:delete="handleClickLeaveModel"

    >
        <template #title>
            <h3>{{ $t('joinTeam.leaveTeam', {'name': item.name}) }}</h3>
        </template>
        <template #content>
            <div>
                {{ $t('joinTeam.leaveTeamDescription') }}
            </div>
        </template>
        <template #input>
            <div></div>
        </template>
        <template #actions>
            <div class="modal__actions">
                <ion-button fill="clear" @click="handleCloseModal"> No</ion-button>
                <ion-button fill="clear" @click="handleClickLeaveModel">
                    Si, eliminar
                </ion-button>
            </div>
        </template>
    </ModalDeleteComponent>
</template>
<script setup lang="ts">
import ModalDeleteComponent from "@/components/shared/modal/ModalDeleteComponent.vue";
import {IonButton} from "@ionic/vue";

interface Props {
    modelValue: boolean;
    item?: any;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue", "confirm:leave"]);


const handleCloseModal = () => {
    emit("update:modelValue", false);
};

const handleClickLeaveModel = () => {
    emit("confirm:leave", props.item);
};

</script>
<style scoped lang="scss">
.modal__delete {
  height: 50% !important;
}
</style>
