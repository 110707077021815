<template>
  <section class="my-profile-wrapper" v-if="user">
    <PhotoEditComponent class="my-profile-wrapper__photo" go-back />
    <p class="caption">{{ user.prefix_number }}{{ user.phone_number }}</p>
    <section class="my-profile-wrapper__form">
      <ion-radio-group
        :value="role"
        style="margin-top: 20px"
        mode="md"
        @ionChange="getEmployee($event)"
      >
        <ion-item>
          <ion-label>{{ $t("joinTeam.employee") }}</ion-label>
          <ion-radio slot="end" :value="ROLE_ID.EMPLOYEE"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>{{ $t("joinTeam.manager") }}</ion-label>
          <ion-radio slot="end" :value="ROLE_ID.MANAGER"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-button
        style="margin-top: 40px"
        mode="ios"
        expand="block"
        type="button"
        @click="confirm"
        >{{ $t("actions.save") }}
      </ion-button>
      <router-link
        to="/register/terms"
        class=" margin-top-20 my-profile-wrapper caption"
        style="margin-top: 100px"
        exact-active-class="active"
        @click.prevent="handlerDeleteUser()"
        id="delete_user_button"
        >{{ $t("global.deleteUser") }}
      </router-link>
    </section>
  </section>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import {
  IonButton,
  alertController,
  IonRadio,
  IonItem,
  IonLabel,
  IonRadioGroup,
  RadioGroupCustomEvent,
  toastController,
} from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handlerServiceDeleteUser } from "@/services/user";
import useCore from "@/composables/useCore";
import router from "@/router";
import { MODEL, ROLE_ID, ROLES } from "@/utils/constants";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handlePutModel, handleSearchReadModel } from "@/services/generic";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { updateEmployeeInfo, clearUserStore, name, email, last_name } =
  useRegister();
const { setResetPinDigit, setResetForm } = useCore();

const role = ref<number>(ROLE_ID.EMPLOYEE);
const locations = ref<ILocation[]>([]);
const userLocation = ref<IUserLocation>();

const user = ref<IUser>();

const getEmployee = async (event: RadioGroupCustomEvent) => {
  role.value = event.target.value;
};

const confirm = async () => {
  if (userLocation.value) {
    await handlePutModel({
      model: MODEL.USERLOCATION,
      id: userLocation.value.id,
      fields: {
        role_id: role.value,
      },
    }).then(async (_) => {
      const toast = await toastController.create({
        message: i18n.global.t("global.updateSuccess"),
        duration: 1500,
        position: "bottom",
        mode: "ios",
      });
      await toast.present();
      coreStore.setRefreshUserLocation(true);
      router.back();
    });
  }
};

const handlerDeleteUser = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.deleteUserHeader"),
    message: i18n.global.t("global.deleteUserBody"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("actions.confirm"),
        cssClass: "wehop-alert-confirm",
        handler: async () => {
          try {
            handlerServiceDeleteUser().then(() => {
              setResetPinDigit(true);
              setResetForm(true);
              clearUserStore();
              setTimeout(async () => {
                await router.push({ name: "login" });
              }, 500);
            });
          } catch (e) {
            console.error(e);
          }
        },
      },
      {
        text: i18n.global.t("actions.cancel"),
        cssClass: "wehop-alert-cancel",
      },
    ],
  });

  await alert.present();
};

onMounted(async () => {
  if (coreStore.selectedUser) {
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "user_id",
        op: "==",
        value: coreStore.selectedUser.id,
      },
      {
        field: "location_id",
        op: "==",
        value: registerStore.location.id,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    const userLocations = data.items as IUserLocation[];
    userLocation.value = userLocations[0];

    //Assign Role
    role.value = parseInt(userLocation.value?.role_id.toString());
  }

  registerStore.setUserSelectedPhoto("");
  if (coreStore.selectedUser != null) {
    user.value = coreStore.selectedUser;
  } else {
    user.value = registerStore.user;
  }
});
</script>

<style scoped lang="scss">
.my-profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__photo {
    margin-top: 40px;
  }

  &__form {
    margin-top: 20px;
    width: 100%;
  }
}

.button--login {
  margin-top: 60px;
  width: 100%;
}
</style>
