<template>
  <RegisterWrapper
    :show-button-back="false"
    :show-progress="false"
    :title-button-back="$t('register.downgradePlan')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div>
        <ion-slides :pager="false" :options="slideOpts" v-if="plans.length">
          <ion-slide
            v-for="(plan, index) in plans"
            :key="index"
            :class="{ center: updateUserPlan || updateLocationPlan }"
          >
            <PlanDetail
               :plan-disabled="plan.name == PLAN_TYPE_NAME.PRO"
              :plan="plan"
              @selected="planSelected"
              :class="plan.name == PLAN_TYPE_NAME.PRO ? 'plan-blocked' : ''"
            ></PlanDetail>
          </ion-slide>
        </ion-slides>
      </div>
      <div>
        <ion-button fill="outline" expand="block" @click="goHome"
          >{{ $t("global.goBack") }}
        </ion-button>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import PlanDetail from "@/components/PlanDetail.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { usePlan } from "@/composables/usePlan";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { MODEL, PLAN_TYPE_CODE, PLAN_TYPE_NAME } from "@/utils/constants";
import { IPlanProduct } from "@/interfaces/plan_product/IPlanProduct";
import { IPlan } from "@/interfaces/plan/IPlan";
import { post } from "@/services/api";
import { IPaymentResult } from "@/interfaces/payment/IPaymentResult";
import { handlePutModel } from "@/services/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { getSubscriptionAvailability, handleSubscriptionByEntity } from "@/services/subscription";
import { ISubscription } from "@/interfaces/subscription/ISubscription";

// eslint-disable-next-line no-use-before-define
declare let CdvPurchase: any;

const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const planProducts = ref<IPlanProduct[]>([]);

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goHome = async () => {
  await router.replace({ name: "admin.home" });
};
const planSelected = async (planProduct: any) => {
  if (planProduct.store_id == 'free') {

    handleSubscriptionByEntity().then(async (data) => {
      const subscription = data as ISubscription;
      await handlePutModel({
        model: MODEL.SUBSCRIPTION,
        id: subscription.id,
        fields: {
          status: 'cancel'
        },
      }).then(async () => {
        await handlePutModel({
          model: MODEL.USER,
          id: registerStore.user.id,
          fields: {
            subscription_id: null,
          },
        }).then(async () => {
          await getSubscriptionAvailability().then(() => {
            if (coreStore.subscriptionAvailability) {
              const numberTeams = coreStore.subscriptionAvailability.teams_status.number_teams;
              const numberUsers = coreStore.subscriptionAvailability.users_status.number_users;

              if ( numberTeams == 1) {
                if (numberUsers <= 5) {
                  return router.replace({ name: "registerPlanOptionOneDowngrade" });
                } else {
                  return router.replace({ name: "registerPlanOptionTwoDowngrade" });
                }
              } else {
                return router.replace({ name: "registerPlanTeamsDowngrade" });
              }
            }
          })
        });
      });
    })
  }
};

const { plans, getSubscriptionPlans } = usePlan();

CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

function registerProducts(plans: IPlan[]) {
  const paidPlan = plans.filter((plan, _) => plan.store_id);
  CdvPurchase.store.verbosity = CdvPurchase.store.DEBUG;
  CdvPurchase.store.register(paidPlan.map(plan => ({
    id: plan.store_id!,
    type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
    platform: isPlatformAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE
  })));
  CdvPurchase.store.initialize();
  CdvPurchase.store.update();
  CdvPurchase.store.restorePurchases();
  mergeProducts();
}

CdvPurchase.store.ready(() => {
  mergeProducts();
});

// eslint-disable-next-line no-use-before-define
const verifyAndroidTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  const transactionToVerify = {
    platform: isPlatformAndroid ? 'android' : CdvPurchase.Platform.APPLE_APPSTORE,
    store_id: transaction.products[0].id,
    purchase_token: transaction.purchaseId
  }
  setTransaction(transactionToVerify);
  verifyTransaction(transactionToVerify)
}

const verifyTransaction = async (payload: any) => {
  try {
    post({url: '/payment/check', payload}).then(async (response: IPaymentResult) => {
      await router.replace({ name: "registerPlanOptionsDowngrade" });
    })
  } catch {
    await router.push({name: 'unverifiedPayment'});
  }
}

// eslint-disable-next-line no-use-before-define
const onApprovedTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  verifyAndroidTransaction(transaction)
}

CdvPurchase.store.when()
  .finished(transaction => alert("state" + transaction.state))
  .approved(onApprovedTransaction);

const mergeProducts = () => {
  planProducts.value = planProducts.value.map(planProduct => ({
    ...planProduct,
    product: CdvPurchase.store.products.find(product => product.id === planProduct.plan.store_id && planProduct.plan.store_id)
  }));
}

onMounted(() => {
  getSubscriptionPlans();
});

onUnmounted(() => {
  CdvPurchase.store.off(onApprovedTransaction)
});

</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 85%;
  }
}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;
    &.center {
      width: 100% !important;
    }
  }
}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

.plan-blocked {
  background-color: #d3d3d3;
  color: #a1a1a1;
  pointer-events: none;
  opacity: 0.9;
}
</style>
