<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <ol>
      <li ref="tourContextual">
        <div style="width: 100%; height: 30px" id="tour_contextual">
          <ion-item
            :button="true"
            :detail="false"
            class="options_actions"
            @click="emitClick('click:button')"
          >
            <VsxIcon iconName="Maximize" color="gray" />
            <span class="ion-padding-start">
              {{ $t("talkie.showButton") }}
            </span>
          </ion-item>
        </div>
      </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { ref, watch } from "vue";
import i18n from "@/plugins/i18n";
import { useShepherd } from "vue-shepherd";
import { CoreStore, useCoreStore } from "@/stores/core";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";

const { handleGetMe } = useRegister();

const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { showDropdown } = useCore();

const emit = defineEmits(["click:button"]);

const tourContextual = ref(null);

const tourContextualWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const emitClick = (event: any) => {
  emit(event);
};
watch(showDropdown, () => {
  if (showDropdown.value && !user.value.is_talkie_contextual) {
    tourContextualWrapper.addStep({
      attachTo: { element: tourContextual.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieContextualTour({ show: true, step: 1 });
            tourContextualWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_5.title"),
      text: i18n.global.t("tour.tour5_5.text"),
    });
    tourContextualWrapper.addStep({
      attachTo: { element: tourContextual.value, on: "top" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieContextualTour({ show: true, step: 2 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_contextual: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourContextualWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_6.title"),
      text: i18n.global.t("tour.tour5_6.text"),
    });
    tourContextualWrapper.start();
  }
});
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
