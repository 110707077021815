<template>
  <RegisterWrapper
    :show-button-back="false"
    :show-progress="false"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div v-if="result">
        <h3 class="downgrade-title">{{ $t("payment.planChangeTitle") }}</h3>
        <p class="downgrade-description" v-html="$t('payment.planChangeTitleDescriptionOption1')"></p>
        <div class="downgrade-button">
        <ion-row>
          <ion-col size="12">
            <ion-button
              expand="block"
              @click="goHome"
            >{{ $t("actions.goToDashboard") }}
            </ion-button>
          </ion-col>
        </ion-row>
        </div>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton, IonSlide, IonSlides } from "@ionic/vue";
import { onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { usePaymentStore } from "@/stores/payment";

import "cordova-plugin-purchase";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import { handleUsersByLocationData } from "@/services/user_location";
import { ILocationResult } from "@/interfaces/ILocationResult";


const router = useRouter();

const { setPlanProduct, setTransaction } = usePaymentStore();
const { user } = useRegister();
const { updateLocationPlan, updateUserPlan } = useCore();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const coreStore: CoreStore = useCoreStore();

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const result =  ref<ILocationResult|undefined>();

const goAddUsers = async () => {
  coreStore.setUpdateUserPlan(true);
  await router.replace({ name: "registerPlan" });
};
const goHome = async () => {
  await router.replace({ name: "admin.home" });
};
const goSelectUsers = async () => {
  await router.replace({ name: "registerPlanUsersDowngrade" });
};

onMounted(async () => {
  result.value = await handleUsersByLocationData();
});
</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }
}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;

    &.center {
      width: 100% !important;
    }
  }
}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

.plan-blocked {
  background-color: #d3d3d3;
  color: #a1a1a1;
  pointer-events: none;
  opacity: 0.7;
}

.downgrade-title {
  text-align: center;
  margin-top: 20px;
}

.downgrade-description {
  text-align: center;
}
.downgrade-button {
  margin-top: 40px;
}
</style>
