<template>
  <div>
    <ion-avatar :style="listAvatarStyle" v-if="photoUrl">
      <img :src="photoUrl" />
      <div class="gradient" v-if="item.image_id"></div>
      <div v-if="showRecording" :class="item.id == itemRecording ? 'icon-recording' : 'icon'">
        <VsxIcon
          :iconName="item.id == itemRecording ? 'Sound' : 'Microphone2'"
          :color="item.image_id ? 'white' : '#4178F5'"
        ></VsxIcon>
      </div>
    </ion-avatar>
  </div>
</template>
<script setup lang="ts">
import { getPhotoUrl } from "@/services/me";
import { VsxIcon } from "vue-iconsax";
import { getImageLetter } from "@/utils/getImageLetter";
import { IonAvatar } from "@ionic/vue";
import { computed, onMounted, PropType, ref } from "vue";
import { IUser } from "@/interfaces/login/ILoginResponse";

const props = defineProps({
  listMode: {
    type: Boolean,
    required: true,
  },
  item: {
    type: Object as PropType<IUser>,
    required: true,
  },
  itemRecording: {
    type: Number,
    required: true,
  },
  showRecording: {
    type: Boolean,
    required: false,
  },
});

const listAvatarStyle = computed(() => {
  return !props.listMode
    ? ""
    : "width: 55px !important;height: 55px !important;";
});

const photoUrl = ref<string>(""); // Initialize with an empty string

async function fetchPhoto() {
  if (props.item.image_id) {
    try {
      getPhotoUrl(props.item.id, props.item.image_id, true).then((data) => {
        photoUrl.value = data.url;
      });
    } catch (error) {
      console.error("Error fetching photo:", error);
    }
  } else {
    photoUrl.value = getImageLetter(props.item.name);
  }
}

onMounted(() => {
  fetchPhoto();
});
</script>

<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-column-gap: 10px;
  max-height: 75vh;
  overflow: auto;
  padding-bottom: 100px;

  div {
    padding-top: 5px;

    .inactive {
      filter: grayscale(100%);
    }

    .no-recording {
      filter: opacity(50%);
    }

    .recording {
      filter: opacity(100%);

      ion-avatar {
        padding: 4px !important;

        width: 70px !important;
        height: 70px !important;
        border: 3px solid #cf2617 !important;

        .gradient {
          height: 90% !important;
          width: 90% !important;
          bottom: 4px !important;
          left: 4px !important;
          background: rgba(105, 6, 6, 0.508) !important;
        }

        .icon-recording {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            width: 45px;
          }
        }
      }
    }

    .item-container {
      padding-top: 5px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      position: relative;

      &__notification {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #1c4197;
        position: absolute;
        top: 20px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 10px;
        font-style: normal;
      }

      .name {
        pointer-events: none;

        font-size: 12px;
        color: #5e6573;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      ion-avatar {
        width: 64px;
        height: 64px;
        padding: 2px;
        border-radius: 50%;
        border: 3px solid #64cde1;
        position: relative;
        display: inline-block;
        overflow: hidden;

        .gradient {
          position: absolute;
          bottom: 2px;
          left: 2px;
          width: 94%;
          height: 100%;
          border-radius: 50%;
          background: linear-gradient(
            to bottom,
            transparent 20%,
            rgba(0, 0, 0, 0.8) 100%
          );
        }

        .icon {
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate(-50%);

          svg {
            width: 15px;
          }
        }
      }
    }
  }
}

.photo-wrapper {
  width: 55px !important;
  height: 55px !important;
}
</style>
