<template>
  <ion-col size="12" :class="{ 'ion-padding-top ion-no-padding': isMobile }">
    <ListWrapper :model="model.name" :headers="headers">
      <template #actions_desktop="props">
        <div class="container" ref="menu">
          <div class="action-wrapper-waiting" v-if="props['status'] == APPLICATION_STATUS.WAITING">
            <WaitingComponent :key="props" :item="props"></WaitingComponent>
          </div>
          <div class="action-wrapper-rejected" v-if="props['status'] == APPLICATION_STATUS.REJECTED">
            <VsxIcon size="15" iconName="CloseCircle" slot="start"></VsxIcon>
          </div>
        </div>
      </template>
      <template #item_mobile="props">
        <div class="ion-flex ion-justify-content-between ion-align-items-center ion-padding item__select">
          <ItemMobileComponent :item="props"/>
          <WaitingComponent :key="props" :item="props"></WaitingComponent>
        </div>
      </template>
    </ListWrapper>
  </ion-col>
</template>

<script setup lang="ts">
import {IonCol} from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {ref, Ref} from "vue";
import ItemMobileComponent from "@/components/shared/applications/ItemMobileComponent.vue";
import {APPLICATION_STATUS, MODEL} from "@/utils/constants";
import i18n from "@/plugins/i18n";
import WaitingComponent from "@/components/shared/applications/WaitingComponent.vue";
import {useGenericStore} from "@/stores/generic";
import {useRegister} from "@/composables/useRegister";

const {isMobile} = useNativePlatform();
const storeList = useGenericStore();
const {user} = useRegister();

const headers = [
  {
    label: 'Id',
    name: 'id'
  },
  {
    label: i18n.global.t('joinTeam.code'),
    name: 'location_code'
  },
  {
    label: i18n.global.t('joinTeam.status'),
    name: "status",
  },
  {
    label: "",
    name: "actions",
  },
];

const model: Ref<any> = ref({
  name: MODEL.APPLICATION,
  fields: [],
});

storeList.setItemsModel(MODEL.APPLICATION, []);
storeList.setFiltersModel(MODEL.APPLICATION, [
  {
    field: "status",
    op: "in",
    value: ['waiting', 'rejected'],
  },
  {
    field: "user_id",
    op: "==",
    value: user.value.id,
  },
]);

</script>

<style scoped>
.action-wrapper-waiting {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #DEE6F7;
  color: #4178F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-wrapper-rejected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #F9E8E7;
  color: #CF2617;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
