<template>
    <ModalComponent id="modal-delete" :model-value="modelValue" @update:model-value="closeModal">
        <div class="modal__delete">
            <div class="modal__title">
                <slot name="title">
                    <h3>¿Estás seguro? Confirma la eliminación</h3>
                </slot>
            </div>
            <div class="modal__content">
                <slot name="content">
                    <div>
                        Al eliminar el elemento
                        <span class="text-bold">"{{ nameItem }}"</span> perderás toda la
                        información relacionada con el y no podrás recuperarlo.
                    </div>
                    <div class="ion-padding-top">
            <span class="text-bold">
              Para confirmar el borrado debes escribir el nombre del elemento
            </span>
                        tal y como está registrado
                    </div>
                </slot>
            </div>
            <slot name="input">
                <ion-item>
                    <ion-input
                            v-model="name"
                            @ion-change="verifyName"
                            placeholder="Confirmar borrado"
                    ></ion-input>
                </ion-item>
            </slot>
        </div>
        <slot name="actions">
            <div class="modal__actions">
                <ion-button fill="clear" @click="closeModal"> No</ion-button>
                <ion-button
                        fill="clear"
                        :color="!valid ? '' : 'danger'"
                        :disabled="!valid"
                        @click="confirmDelete"
                >
                    Si, eliminar
                </ion-button>
            </div>
        </slot>
    </ModalComponent>
</template>
<script setup lang="ts">
import {IonInput, IonItem, IonButton} from "@ionic/vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import type {Ref} from "vue";
import {ref} from "vue";

interface Props {
    modelValue: boolean;
    nameItem?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue", "confirm:delete"]);
const valid: Ref<boolean> = ref(false);
const name: Ref<string> = ref("");

const closeModal = () => {
    emit("update:modelValue", false);
};

const verifyName = () => {
    valid.value = name.value === props.nameItem;
};

const confirmDelete = () => {
    emit("confirm:delete");
};
</script>
<style lang="scss">

ion-modal#modal-delete {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.modal__title {
  h3 {
    text-align: left;
  }
}

.modal__delete {
  height: 75%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.modal__actions {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.button-disabled {
  color: gray !important;
}
</style>
