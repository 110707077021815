<template>
  <ion-avatar v-if="registerStore.location" class="avatar-wrapper" :style="`width:${width}px;height:${height}px`">
    <img :src="photo" :alt="registerStore.location.name"/>
  </ion-avatar>
</template>

<script setup lang="ts">
import {IonAvatar} from '@ionic/vue';
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {getImageLetter} from "@/utils/getImageLetter";
import {CoreStore, useCoreStore} from "@/stores/core";


const props = defineProps({
  width: {
    type: Number,
    default: 45
  },
  height: {
    type: Number,
    default: 45
  },
  isNew: {
    type: Boolean,
    default: false
  },
  onlyCurrent: {
    type: Boolean,
    default: false
  }
})

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const {getLocalePhoto, localePhoto} = useRegister();
const photo = ref<string | null>(getImageLetter(registerStore.location?.name ?? 'LO'));

watch(localePhoto, () => {
  if (localePhoto.value) {
    photo.value = registerStore.localePhoto;
    coreStore.setNewLocal(false);
  }
});

onMounted(async () => {
  try {
    if (!coreStore.newLocal) {
      if (coreStore.selectedLocale == null || props.onlyCurrent) {
        if (registerStore.location && registerStore.location.image_id && registerStore.location.id) {
          const data = await getLocalePhoto(registerStore.location.id, registerStore.location.image_id, true);
          if (data.url) {
            registerStore.setLocalePhoto(data.url);
            photo.value = data.url;
          }
        }
      } else {
        if (coreStore.selectedLocale && coreStore.selectedLocale.image_id && coreStore.selectedLocale.id) {
          const data = await getLocalePhoto(coreStore.selectedLocale.id, coreStore.selectedLocale.image_id, true);
          if (data.url) {
            registerStore.setLocalePhoto(data.url);
            photo.value = data.url;
          }
        }
      }
    }
  } catch (e) {
    console.error(e.message);
  }
})

</script>

<style scoped lang="scss">
.avatar-wrapper {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
</style>
