<template>
  <ion-page>
    <ion-content class="ion-padding register-wrapper">
      <section class="photo-wrapper__body">
        <div class="photo-wrapper__logo">
          <Cropper
              ref="cropper"
              class="photo-wrapper__background"
              :src="img"
              :auto-zoom="true"
              :stencil-props="{
              handlers: {},
              movable: true,
              resizable: true,
            }"
              :stencil-size="{
              width: cropperWidth,
              height: cropperHeight,
            }"
              @change="onChange"
          ></Cropper>
        </div>
        <div class="photo-wrapper__cropper__options">
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="decreaseCropper"
          >
            <ion-icon :icon="removeOutline"></ion-icon>
          </ion-button>
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="photoSubmit"
          >
            <ion-icon :icon="scanOutline"></ion-icon>
          </ion-button>
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="increaseCropper"
          >
            <ion-icon :icon="addOutline"></ion-icon>
          </ion-button>
        </div>
        <div class="photo-wrapper__options">
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="outline"
              @click="handleFileImport"
          >{{ $t("actions.changePhoto") }}
          </ion-button>
          <input
              accept="image/jpeg, image/png"
              ref="uploader"
              style="display: none"
              type="file"
              @change="onFileChanged"
          />
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              @click="photoSubmit"
          >{{ $t("actions.save") }}
          </ion-button>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import {useRegister} from "@/composables/useRegister";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  toastController,
} from "@ionic/vue";
import {addOutline, removeOutline, scanOutline} from "ionicons/icons";
import {Cropper,CropperResult} from "vue-advanced-cropper";
import {onMounted, ref} from "vue";
import {Camera, CameraResultType} from "@capacitor/camera";
import {useLocation} from "@/composables/useLocation";
import {ILocation} from "@/interfaces/locale/ILocation";
import {CoreStore, useCoreStore} from "@/stores/core";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {RegisterStore, useRegisterStore} from "@/stores/register";
import {getImageLetter} from "@/utils/getImageLetter";


const {photo, sendLocalePhoto} = useRegister();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const {isMobile} = useNativePlatform();

const location = ref<ILocation>();

const img = ref<string | ArrayBuffer | null | undefined>("");

const emit = defineEmits(["close"]);

const {getLocation} = useLocation();

const isSelecting = ref<boolean>(false);
const uploader = ref<HTMLInputElement>();
const cropper = ref<CropperResult>();

const newPhoto = ref(false);
const fileName = ref("");
const newPhotoBase64 = ref<string>();
const cropperWidth = ref(200);
const cropperHeight = ref(200);

const photoSubmit = async () => {
  coreStore.toggleLoading(true);
  try {
    photo.value = newPhotoBase64.value ?? "";
    const result = await sendLocalePhoto(fileName.value);
    const toast = await toastController.create({
      message: "Imagen cargada con éxito.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    emit("close", result);
  } catch (error) {
    const toast = await toastController.create({
      message: "Ocurrió un error al cargar la imagen, intente de nuevo.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  } finally {
    coreStore.toggleLoading(false);
  }
};

const increaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height + 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width + 10;
};

const decreaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height - 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width - 10;
};


const takePicture = async () => {
  const permissions = await Camera.requestPermissions();
  if (permissions.photos === "granted" || permissions.camera === "granted") {
    await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    }).then((data) => {
      newPhoto.value = true;
      fileName.value = "photo.jpg";
      img.value = "data:image/jpeg;base64," + data.base64String;
    })
  }
};

const onChange = (canvas: any) => {
  newPhotoBase64.value = canvas.canvas?.toDataURL() ?? undefined;
};

const onFileChanged = (e: any) => {
  isSelecting.value = false;
  if (e.target.files && e.target.files[0]) {
    newPhoto.value = true;
    fileName.value = e.target.files[0].name;
    getBase64(e.target.files[0]);
  }
};

const getBase64 = (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img.value = reader.result;
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

const handleFileImport = () => {
  isSelecting.value = true;
  window.addEventListener(
      "focus",
      () => {
        isSelecting.value = false;
      },
      {once: true}
  );
  if (isMobile) {
    takePicture();
  } else {
    uploader.value?.click();
  }
};

onMounted(async () => {
  img.value = getImageLetter(registerStore.location.name ?? 'LO');
});
</script>
<style scoped lang="scss">
.photo-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__back {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__cropper {
    width: 100%;

    &__options {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  &__options {
    width: 100%;
  }

  &__logo {
    margin-top: 20px;
    width: 90%;
  }

  &__background {
    background: #d4d9d9;
    height: 400px;
  }
}

.button--login {
  width: 100%;
}
</style>
