<template>
  <div id="application-active" :key="Math.random().toString(36).substring(7)">
    <ion-col size="12" :class="{ 'ion-padding-top ion-no-padding': isMobile }">
      <ListWrapper :model="model.name" :headers="headers">
        <template #item_mobile="props">
          <div class="ion-flex ion-justify-content-between ion-align-items-center ion-padding item__select">
            <ItemMobileComponent :item="props"/>
            <DropdownMenuComponent
                :item="props"
                @click:detail="openDetail"
                @click:leave="openLeave"
            />
          </div>
          <ModalDeleteComponent
              v-if="location"
              v-model="showModalDelete"
              @confirm:leave="handleClickLeaveModel"
              :item="props"
          />
        </template>
      </ListWrapper>
    </ion-col>
    <ion-alert
        :is-open="isOpen"
        :header="$t('joinTeam.leaveTeam')"
        :message="$t('joinTeam.leaveTeamDescription')"
        :buttons="alertButtons"
        @didDismiss="setOpen(false)"
    ></ion-alert>
  </div>
</template>

<script setup lang="ts">
import {IonAlert, IonCol} from "@ionic/vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {onMounted, ref, Ref} from "vue";
import {MODEL} from "@/utils/constants";
import i18n from "@/plugins/i18n";
import ItemMobileComponent from "@/components/shared/applications/ItemMobileComponent.vue";
import {useGenericStore} from "@/stores/generic";
import DropdownMenuComponent from "@/components/shared/applications/DropdownMenuComponent.vue";
import ModalDeleteComponent from "@/components/shared/applications/ModalDeleteComponent.vue";
import { handleDeleteModel, handlePutModel, handleSearchReadModel } from "@/services/generic";
import {ILocation} from "@/interfaces/locale/ILocation";
import {useAudio} from "@/composables/useAudio";
import router from "@/router";
import {useRegister} from "@/composables/useRegister";
import {IUserLocation} from "@/interfaces/user_location/IUserLocation";
import {useLocation} from "@/composables/useLocation";
import { RegisterStore, useRegisterStore } from "@/stores/register";

const {isMobile} = useNativePlatform();
const storeList = useGenericStore();
const {verifySpeaking} = useAudio();
const {setLocation, user} = useRegister();
const {getLocation} = useLocation();
const registerStore: RegisterStore = useRegisterStore();

const isOpen = ref(false);
const applicationId = ref('');
const locationId = ref<number>(0);
const locationName = ref('');
const locationCode = ref('');
const alertButtons = [
  {
    text: 'No',
  },
  {
    text: 'Sí, eliminar',
    cssClass: 'alert-button-confirm',
    handler: async () => {
      try {
        const payload = {
          model: MODEL.LOCATION,
          fields: ['id', 'code', 'name', 'image_id'],
        };
        const filter = [
          {
            field: 'id',
            op: "==",
            value: locationId.value,
          },
        ];
        handleSearchReadModel(payload, filter).then(async (data) => {
          const location = data.items[0] as ILocation;
          if (location) {
            const payload = {
              model: MODEL.USERLOCATION,
              fields: ['id', 'user_id', 'location_id'],
            };
            const filter = [
              {
                field: 'user_id',
                op: "==",
                value: user.value.id,
              },
              {
                field: 'location_id',
                op: "==",
                value: location.id ?? -1,
              },
            ];
            handleSearchReadModel(payload, filter).then(async (data) => {
              const userLocation = data.items[0] as IUserLocation;
              locationId.value = userLocation.location_id;
              handleDeleteModel({
                model: MODEL.USERLOCATION,
                id: userLocation.id,
              }).then((_) => {
                const payload = {
                  model: MODEL.USERLOCATION,
                  fields: [
                    "id",
                    "location_id",
                    "user_id",
                  ],
                };
                const filter = [
                  {
                    field: "user_id",
                    op: "==",
                    value: user.value.id,
                  },
                ];
                handleSearchReadModel(payload, filter).then(async (data) => {
                  if (data.items.length > 0) {
                    if (user.value.location_id == locationId.value) {
                      const userLocation = data.items[0] as IUserLocation;
                      getLocation(userLocation.location_id).then((data) => {
                        const location = data ? data[0] : [];
                        setLocation(location as ILocation);
                        router.go(0);
                      });
                    } else {
                      router.go(0);
                    }
                  } else {
                    await handlePutModel({
                      model: MODEL.USER,
                      id: registerStore.user.id,
                      fields: {
                        location_id: null,
                      },
                    }).then(async () => {
                      await router.push({ name: "joinHome" });
                    });
                  }
                });
              })

            })
          }
        })

      } catch (err) {
        console.error(err);
      }

    },
  },
];

const openLeave = async (item: any) => {
  applicationId.value = item.id;
  locationId.value = item.location_id;
  isOpen.value = true;
};

const setOpen = (state: boolean) => {
  isOpen.value = state;
};

const headers = [
  {
    label: 'Id',
    name: 'id'
  },
  {
    label: i18n.global.t('joinTeam.code'),
    name: 'location_id'
  },
  {
    label: i18n.global.t('joinTeam.status'),
    name: "status",
  },
  {
    label: "",
    name: "actions",
  },
];
const itemSelected: Ref<any> = ref(null);
const showModalDelete: Ref<boolean> = ref(false);

const model: Ref<any> = ref({
  name: MODEL.APPLICATION,
  fields: [],
});

const location: Ref<ILocation | undefined> = ref<ILocation>()

storeList.setItemsModel(MODEL.USERLOCATION, []);
storeList.setFiltersModel(MODEL.USERLOCATION, [
  {
    field: "user_id",
    op: "==",
    value: user.value.id,
  },
]);

const openDetail = (item: any) => {
  console.log(item);
};
const handleClickLeaveModel = (item: any) => {

  try {
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ['id', 'user_id', 'location_id'],
    };
    const filter = [
      {
        field: 'user_id',
        op: "==",
        value: user.value.id,
      },
      {
        field: 'location_id',
        op: "==",
        value: location.value?.id ?? -1,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      const userLocation = data.items[0] as IUserLocation;

      handleDeleteModel({
        model: MODEL.USERLOCATION,
        id: userLocation.id,
      }).then((_) => {
        handleDeleteModel({
          model: MODEL.APPLICATION,
          id: item.id,
        }).then((_) => {

          const payload = {
            model: MODEL.USERLOCATION,
            fields: [
              "id",
              "location_id",
              "user_id",
            ],
          };
          const filter = [
            {
              field: "user_id",
              op: "==",
              value: user.value.id,
            },
          ];
          handleSearchReadModel(payload, filter).then(async (data) => {
            if (data.items.length > 0) {
              const userLocation = data.items[0] as IUserLocation;
              getLocation(userLocation.location_id).then((data) => {
                const location = data ? data[0] : [];
                setLocation(location as ILocation);
                router.go(0);
              });
            } else {
              await router.push({name: "joinHome"});
            }
          });

        })
      })

    })
  } catch (err) {
    console.error(err);
  }
  showModalDelete.value = false;
};

onMounted(() => {
  verifySpeaking();
});

</script>

<style scoped>
button.alert-button.alert-button-confirm {
  background: #CF2617;
  color: #CF2617;
}
</style>
