<template>
  <ion-page>
    <ion-content class="privacy-wrapper ion-padding">
      <div class="privacy-wrapper__back">
        <ion-back-button
          :text="$t('register.privacyPolicy')"
          color="neutral-80"
          :defaultHref="route"
        ></ion-back-button>
      </div>
      <ion-accordion-group mode="ios" :value="['1']">
        <ion-accordion value="1">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.privacyPolicy")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              De acuerdo con la Ley 34/2002, de 11 de julio, de Servicios de
              Sociedad de la Información y del Comercio Electrónico (en adelante
              LSSI), el Reglamento (UE) 2016/679 del Parlamento Europeo y del
              Consejo, de 27 de abril de 2016, relativo a la protección de las
              personas físicas en lo que respecta al tratamiento de datos
              personales y a la libre circulación de estos (en adelante RGPD), y
              la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
              Personales y garantía de los derechos digitales (en adelante
              LOPDGDD) se informa al usuario de que SUMMUMAPP, S.L. es la
              titular de la página web www.wehop.app y de la aplicación
              informática (app) WEHOP.
            </p>
            <p>
              A través de esta política de privacidad y cookies se facilitará al
              usuario toda la información sobre el uso que SUMMUMAPP, S.L. hace
              de sus datos personales a través de la plataforma WEHOP.
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="2">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.responsible")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              SUMMUMAPP, S.L., con CIF B-01827716, con domicilio en Avenida
              Andalucía, 139, 29740 Vélez-Málaga (Málaga), con teléfono de
              contacto 676403985 y correo electrónico
              protecciondedatos@wehop.app
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="3">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.data")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              WEHOP recopila, entre otros, datos que han sido facilitados
              previamente por el usuario, ya sea a través de la app o de la web.
            </p>
            <p>
              El usuario se compromete a que los datos facilitados por el mismo
              son veraces y exactos, que serán siempre tratados por SUMMUMAPP,
              S.L. de manera confidencial.
            </p>
            <p>
              Por lo general los datos que SUMMUMAPP, S.L puede recopilar, entre
              otros, son:
            </p>
            <ul>
              <li>
                Datos identificativos (nombre, apellidos, teléfono de contacto,
                email, fecha, población, etc).
              </li>
              <li>Datos bancarios para el pago de la cuota de WEHOP</li>
              <li>Imágenes captadas por cámaras de videovigilancia</li>
              <li>
                Voz captada por los servicios de telecomunicaciones de WEHOP
              </li>
            </ul>
          </div>
        </ion-accordion>
        <ion-accordion value="4">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.treatment")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              La finalidad de este tratamiento es la gestión del usuario en la
              aplicación, así como cualquier cuestión en relación con el
              servicio contratado y poder gestionar el pago del servicio.
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="5">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.legitimation")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              Teniendo en cuenta la finalidad del tratamiento, la base jurídica
              es el consentimiento del usuario que se registra en la app, de
              conformidad con el artículo 6.1 del RGPD.
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="6">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.dataRetentionPeriod")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              Los datos del usuario se conservarán durante el tiempo que sea
              usuario registrado en la app y hasta el plazo necesario para
              cumplir con las obligaciones legales después de la baja de la app.
              En todo caso, la conservación se realizará de conformidad con la
              Norma de conservación y destrucción de datos de WEHOP
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="7">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.transfers")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              SUMMUMAPP, S.L. no realiza transferencias internacionales de tus
              datos personales para ninguna de las finalidades indicadas.
            </p>
            <p>
              En caso de que este hecho cambie, te informaremos de ello
              actualizando la presente política de privacidad y adoptando los
              mecanismos necesarios para legitimar tales tratamientos de tus
              datos.
            </p>
          </div>
        </ion-accordion>
        <ion-accordion value="8">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.security")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              El tratamiento de sus datos se realizará adoptando las medidas
              técnicas y organizativas necesarias para evitar la destrucción,
              pérdida o alteración accidental o ilícita de datos personales
              transmitidos, conservados o tratados de otra forma o la
              comunicación o acceso no autorizados a dichos datos, en función de
              las tecnologías disponibles, la naturaleza de los datos y el
              análisis de riesgos efectuado. Si conociera o sospechase de un
              incidente que pudiera afectar a sus datos personales, ruego nos
              envíe un email a:
            </p>
            <a href="mailto:incidentedeseguridad@wehop.app"
              >incidentedeseguridad@wehop.app</a
            >
          </div>
        </ion-accordion>
        <ion-accordion value="9">
          <ion-item slot="header" color="transparent">
            <ion-label class="privacy-wrapper__title">{{
              $t("register.policy.userRights")
            }}</ion-label>
          </ion-item>
          <div class="ion-padding privacy-wrapper__content" slot="content">
            <p>
              SUMMUMAPP, S.L. garantiza el ejercicio de los derechos de los
              usuarios. Para ello, el usuario podrá dirigirse al responsable sin
              que suponga ningún gasto para él a la dirección
              protecciondedatos@wehop.app. En esta comunicación el usuario
              deberá indicar el motivo de la solicitud y el derecho que desea
              ejercitar.
            </p>
            <p>De este modo, el usuario tiene derecho a:</p>
            <ol>
              <li>
                Acceder a los datos que SUMMUMAPP, S.L disponga del usuario. El
                usuario podrá solicitar al responsable una copia de toda la
                información personal que SUMMUMAPP, S.L tenga sobre él.
              </li>
              <li>
                Rectificar los datos que SUMMUMAPP, S.L disponga del usuario. Si
                bien el usuario se compromete a proporcionar al responsable
                datos ciertos y veraces, el interesado podrá solicitar a
                SUMMUMAPP, S.L. la rectificación de los mismos.
              </li>
              <li>
                Suprimir los datos facilitados a SUMMUMAPP, S.L, cuando ya no
                sean necesarios para la finalidad perseguida o cuando no exista
                una base jurídica para el tratamiento.
              </li>
              <li>
                Oponerse al tratamiento de los datos en cualquier momento.
              </li>
              <li>
                Limitar el tratamiento de los datos, solicitando a SUMMUMAPP,
                S.L que suspenda temporalmente el tratamiento de los datos.
              </li>
              <li>
                La portabilidad de los datos. El usuario podrá solicitar a
                SUMMUMAPP, S.L recibir una copia de sus datos personales en un
                formato estructurado y pedir que envíe dicha información a otra
                entidad.
              </li>
            </ol>
            <p>
              Asimismo, si usted considera que no se han atendido sus derechos
              podrá presentar una reclamación ante la autoridad de control
              pertinente: la Agencia Española de Protección de Datos (<a
                href="https://www.aepd.es"
                >https://www.aepd.es</a
              >) en C/ Jorge Juan, 6, 28001 Madrid, con número de teléfono
              901100099 y 912663517.
            </p>
            <p>Última actualización: 19 abril de 2023</p>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonPage,
  IonContent,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonBackButton,
} from "@ionic/vue";
import { computed } from "vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import { USER_TYPES } from "@/utils/constants";

const coreStore: CoreStore = useCoreStore();

const route = computed(() => {
  return coreStore.userType == USER_TYPES.OWNER
    ? "/register/store"
    : "/register/employee";
});
</script>

<style scoped lang="scss">
.privacy-wrapper {
  color: #5e6573 !important;

  &__back {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__content {
    p {
      font-size: 14px;
      font-weight: 400;
      color: #5e6573;
    }

    ul,
    ol > li {
      font-size: 14px;
      font-weight: 400;
      color: #5e6573;
      margin-bottom: 20px;
    }
  }
}
</style>
