<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <section class="join-wrapper__header">
        <div>
          <img :src="wehopBrand" alt="WeHop Brand" />
        </div>
        <div>
          <avatar-component
            :user-id="registerStore.user.id"
            :image-id="registerStore.user.image_id ?? -1"
          />
        </div>
      </section>
      <section class="join-wrapper__body">
        <img :src="joinWrong" />
        <h3 class="join-wrapper__title">{{ $t("joinTeam.requestWrong") }}</h3>
        <p class="join-wrapper__description caption">
          {{ $t("joinTeam.requestWrongMessage") }}
        </p>
        <ion-button
          class="join-wrapper__button"
          fill="solid"
          expand="block"
          style="width: 100%"
          @click="goTo"
        >
          {{ $t("actions.goToHome") }}
        </ion-button>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { IonButton, IonContent, IonPage } from "@ionic/vue";
import wehopBrand from "@/assets/wehop-brand.svg";
import joinWrong from "@/assets/join-wrong.svg";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import router from "@/router";

const registerStore: RegisterStore = useRegisterStore();

const goTo = () => {
  router.push({ name: "admin.home" });
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.join-wrapper {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    margin: 30px 0px 0px 0px;
  }

  &__description {
    margin: 30px 0px 0px 0px;
  }

  &__button {
    margin: 30px 0px 0px 0px;
  }
}
</style>
