<template>
  <DropdownComponent>
    <template #button>
      <ion-item @click="getLocations">
        <AvatarCurrentLocaleComponent :only-current="true" :width="45" :height="45" />
        <ion-label>
          <span class="user__name">
            {{
              registerStore.location
                ? registerStore.location.name
                : registerStore.user.name
            }}
          </span>
          <span class="user__role">
            {{
              registerStore.location
                ? registerStore.location.address
                : registerStore.user.role
            }}
          </span>
        </ion-label>
      </ion-item>
      <VsxIcon
        class="pointer"
        iconName="ArrowDown2"
        @click="getLocations"
        slot="end"
      ></VsxIcon>
    </template>
    <ol>
      <li
        v-for="(location, index) in locationList"
        :key="location.id"
        :class="{ 'item-start': index === 0 }"
      >
        <ion-item
          :button="true"
          :detail="false"
          @click="setCurrentLocation(location)"
        >
          <span style="margin-right: 10px">
            <AvatarLocaleComponent
              :locale-id="location.id ?? -1"
              :image-id="location.image_id"
              :locale-name="location.name"
              :width="35"
              :height="35"
            />
          </span>
          <div class="content">
            <div class="box-title-item">
              <span class="title-item"> {{ location.name }} </span>
            </div>
            <div class="box-address-item">
              <span class="address-item"> {{ location.address }} </span>
            </div>
          </div>
          <VsxIcon
            v-if="registerStore.user.location_id === location.id"
            iconName="TickCircle"
            color="#4178F5"
            slot="end"
            type="bold"
          />
        </ion-item>
      </li>
      <li class="item-end" v-if="isOwner">
        <ion-item :button="true" :detail="false" @click="handleGoLocations">
          <div class="ion-flex ion-justify-content-center">
            <VsxIcon iconName="Setting4" color="#4178F5" slot="end" />
            <span class="title-item-admin">
              {{ $t("locale.adminLocales") }}</span
            >
          </div>
        </ion-item>
      </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem, IonAvatar, IonLabel } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { useLocation } from "@/composables/useLocation";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useRouter } from "vue-router";
import { handlePutModel } from "@/services/generic";
import { MODEL, ROLES } from "@/utils/constants";
import { ILocation } from "@/interfaces/locale/ILocation";
import { computed, onMounted, watch } from "vue";
import useCore from "@/composables/useCore";
import { CoreStore, useCoreStore } from "@/stores/core";
import AvatarCurrentLocaleComponent from "@/components/shared/avatar/AvatarCurrentLocaleComponent.vue";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";

const { locations, getLocations, getLocation } = useLocation();
const registerStore: RegisterStore = useRegisterStore();
const emit = defineEmits(["to:locations"]);
const router = useRouter();
const { refreshLocation } = useCore();
const coreStore: CoreStore = useCoreStore();
const { user } = useRegister();
const { handleGetMe, getLocalePhoto } = useRegister();

const isOwner = computed(() => {
  return user.value.role == ROLES.OWNER;
});

const locationList = computed(() => {
  return locations.value;
});

const handleGoLocations = () => {
  coreStore.setShowDropdown(false);
  emit("to:locations");
  router.push({ name: "admin.locations.list" });
};

const setCurrentLocation = async (location: ILocation) => {
  coreStore.setShowDropdown(false);
  await handlePutModel({
    model: MODEL.USER,
    id: registerStore.user.id,
    fields: {
      location_id: location.id,
    },
  }).then(async () => {
    registerStore.setLocation(location);
    await handleGetMe();
    if (location.image_id) {
      const data = await getLocalePhoto(
        location.id ?? -1,
        location.image_id,
        true
      );
      if (data.url) {
        registerStore.setLocalePhoto(data.url);
      }
    }
    setTimeout(() => {
      coreStore.setRefreshLocation(true);
      coreStore.setRefreshUserLocation(true);
      coreStore.setRefreshTask(true);
      coreStore.setRefreshDashboardOwner(true);
      coreStore.setRefreshDashboard(true);
    }, 800);
  });
};
onMounted(() => {
  getLocations();
});
watch(refreshLocation, async () => {
  if (refreshLocation.value) {
    await getLocations();
    let location = null;
    if (registerStore.location.id != null) {
      location = await getLocation(registerStore.location.id);
      if (location) {
        registerStore.setLocation(location[0]);
      }
    } else {
      await handleGetMe().then(async () => {
        if (registerStore.user.location_id != null) {
          location = await getLocation(registerStore.user.location_id);
          if (location) {
            registerStore.setLocation(location[0]);
          }
        }
      });
    }
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
    coreStore.setRefreshTask(false);
    coreStore.setRefreshDashboardOwner(false);
    coreStore.setRefreshDashboard(false);
  }
});
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
  --min-height: 60px;
}

.item-start {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.item-end {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.box-title-item {
  padding-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.title-item {
  font-size: 16px;
}

.box-address-item {
  padding-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.address-item {
  font-size: 14px;
  color: #5e6573;
}

.title-item-admin {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #4178f5;
}

.content {
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.item-select-menu {
  width: 100%;
}
</style>
