<template>
  <div>
    <div class="bar--record">
      <div class="tabs">
        <div class="tab tab--left">
          <div
            :class="{ 'tab-selected': tabSelected('/home') }"
            @click="handleMenuResend" 
          >
            <VsxIcon iconName="Home2"></VsxIcon>
            <span v-if="tabSelected('/home')">{{ $t("navigation.home") }}</span>
          </div>
        </div>
        <div class="tab tab--fab">
          <div
            :class="{ 'tab-selected': tabSelected('/talkie') }"
            @click="router.push({ name: 'default.talkie.list' })"
          >
            <VsxIcon iconName="Microphone2"></VsxIcon>
            <span v-if="tabSelected('/talkie')">{{
              $t("navigation.talkie")
            }}</span>
          </div>
        </div>
        <div class="tab tab--right">
          <div
            :class="{ 'tab-selected': tabSelected('/tasks') }"
            @click="router.push({ name: 'default.tasks.list' })"
          >
            <VsxIcon iconName="ClipboardText"></VsxIcon>
            <span v-if="tabSelected('/tasks')">{{
              $t("navigation.tasks")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import { useRegister } from "@/composables/useRegister";

const route = useRoute();
const router = useRouter();
const { user } = useRegister();

const tabSelected = (itemSelected: any) => {
  return route.path === itemSelected;
};

const handleMenuResend = () => {
  if (user.value.role === 'owner') {
    router.push({ name: 'admin.home' })
  } else {
    router.push({ name: 'employee.home' })
  }  
};
</script>
<style scoped lang="scss">
.tab-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b6cbfb4d;
  border-radius: 10px;
  padding: 10px;
  color: #4178f5;

  span {
    margin-left: 5px;
  }
}

.bar--record {
  border-radius: 10px;
  margin: 0 20px;
  position: fixed;
  right: 0;
  bottom: 20px;
  left: 0;
  height: 56px;
  background: transparent;
  overflow: hidden;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.3);
  display: flex;

  .tabs {
    color: #787f8c;
    display: flex;
    width: 100%;
    justify-content: space-around;
    z-index: 1;
    align-items: center;
    background: white;
  }
}
</style>
