<template>
  <RegisterWrapper
      :show-button-back="false"
      :show-progress="false"
      :title-button-back="$t('register.selectPlan')"
      :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div v-if="!updateUserPlan">
      <ion-slides :pager="false" :options="slideOpts" v-if="plans.length">
        <ion-slide
            v-for="(planProduct, index) in planProductsFiltered"
            :key="index" :class="{'center': updateUserPlan || updateLocationPlan, }">
          <PlanDetail :plan="planProduct.plan" @selected="() => planSelected(planProduct as IPlanProduct)"></PlanDetail>
        </ion-slide>
      </ion-slides>
      </div>
      <div v-else>
          <PlanDetailUser
              v-if="planProductsFiltered"
              :plan-products="planProductsFiltered as IPlanProduct[]"
              @selected="planSelected"
          ></PlanDetailUser>
      </div>
      <div v-if="updateUserPlan">
        <ion-button
            fill="outline"
            expand="block"
            @click="updateUserPlanAction"
        >{{ $t("global.goBack") }}
        </ion-button>
      </div>
      <div v-else-if="updateLocationPlan">
        <ion-button
            fill="outline"
            expand="block"
            @click="updateLocationPlanAction"
        >{{ $t("global.goBack") }}
        </ion-button>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import {IonButton, IonSlide, IonSlides} from "@ionic/vue";
import PlanDetail from "@/components/PlanDetail.vue";
import {computed, ComputedRef, onMounted, onUnmounted, ref, watch} from "vue";
import {usePlan} from "@/composables/usePlan";
import {IPlan} from "@/interfaces/plan/IPlan";
import {IPlanProduct} from "@/interfaces/plan_product/IPlanProduct";
import {useRouter} from "vue-router";
import {post} from "@/services/api";
import {usePaymentStore} from "@/stores/payment";

import "cordova-plugin-purchase";
import {useRegister} from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import {usePlatform} from "@/composables/usePlatform";
import {IPaymentResult} from "@/interfaces/payment/IPaymentResult";
import PlanDetailUser from "@/components/PlanDetailUser.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import {PLAN_TYPE_CODE} from "@/utils/constants";
import { handleAmountUserLocationData, handleUsersByLocationData } from "@/services/user_location";

// eslint-disable-next-line no-use-before-define
declare let CdvPurchase: any;

const router = useRouter();

const {setPlanProduct, setTransaction} = usePaymentStore();
const {user} = useRegister();
const {updateLocationPlan, updateUserPlan} = useCore();
const {isPlatformIOS, isPlatformAndroid} = usePlatform();
const coreStore: CoreStore = useCoreStore();

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 'auto',
  setWrapperSize: true,
  spaceBetween: 0,
};

const {plans,  getSubscriptionPlans, getSubscriptionPlansPro, getSubscriptionPlansUser, getSubscriptionAllPlans} = usePlan();
const {setRefreshPlan} = useCore()

const planProducts = ref<IPlanProduct[]>([]);

const planProductsFiltered = computed(() => {
  if (updateLocationPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === false && planProduct.plan.id === PLAN_TYPE_CODE.PRO);
  }
  if (!updateLocationPlan.value && !updateUserPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === false);
  }
  if (updateUserPlan.value) {
    return planProducts.value.filter((planProduct) => planProduct.plan.is_product === true);
  }
  return [];
});

const planSelected = async (planProduct: IPlanProduct) => {
  setPlanProduct(planProduct);
  if (planProduct.product) {
    try {
      const result = await CdvPurchase.store.order(planProduct.product.offers[0]);
      if (result?.isError) {
        await router.push({name: 'failedPayment'})
      }
    } catch {
      await router.push({name: 'failedPayment'});
    }
  } else {
    if (user.value.entity_id == null) {
      await router.replace({name: 'registerBusinessInfo'})
    } else {
      await router.replace({name: 'admin.locations.list'})
    }
  }
}
const updateUserPlanAction = async () => {
  router.back();
};

const updateLocationPlanAction = async () => {
  router.back();
};


watch(plans, () => {
  planProducts.value = [];
  if (plans.value.length) {
    planProducts.value = plans.value.map(plan => ({
      plan
    }));
    CdvPurchase.store.register([]);
    registerProducts(plans.value);
  }
});

CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;

function registerProducts(plans: IPlan[]) {
  const paidPlan = plans.filter((plan, _) => plan.store_id);
  CdvPurchase.store.verbosity = CdvPurchase.store.DEBUG;
  CdvPurchase.store.register(paidPlan.map(plan => ({
      id: plan.store_id!,
      type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
      platform: isPlatformAndroid ? CdvPurchase.Platform.GOOGLE_PLAY : CdvPurchase.Platform.APPLE_APPSTORE
  })));
  CdvPurchase.store.initialize();
  CdvPurchase.store.update();
  CdvPurchase.store.restorePurchases();
  mergeProducts();
}

CdvPurchase.store.ready(() => {
  mergeProducts();
});

// eslint-disable-next-line no-use-before-define
const verifyAndroidTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  const transactionToVerify = {
    platform: isPlatformAndroid ? 'android' : CdvPurchase.Platform.APPLE_APPSTORE,
    store_id: transaction.products[0].id,
    purchase_token: transaction.purchaseId
  }
  setTransaction(transactionToVerify);
  verifyTransaction(transactionToVerify)
}

const verifyTransaction = async (payload: any) => {
  try {

    post({url: '/payment/check', payload}).then(async (response: IPaymentResult) => {
      setRefreshPlan(true);
    })
    if (updateUserPlan.value) {
      await router.replace({name: 'successUserPayment'});
    } else {
      await router.replace({name: 'successPayment'});
    }
  } catch {
    await router.push({name: 'unverifiedPayment'});
  }
}

// eslint-disable-next-line no-use-before-define
const onApprovedTransaction = (transaction: CdvPurchase.Transaction) => { // eslint-disable-line
  verifyAndroidTransaction(transaction)
}

CdvPurchase.store.when()
    .finished(transaction => alert("state" + transaction.state))
    .approved(onApprovedTransaction);

const mergeProducts = () => {
  planProducts.value = planProducts.value.map(planProduct => ({
    ...planProduct,
    product: CdvPurchase.store.products.find(product => product.id === planProduct.plan.store_id && planProduct.plan.store_id)
  }));
}

onMounted(() => {
  getSubscriptionAllPlans();
});

onUnmounted(() => {
  CdvPurchase.store.off(onApprovedTransaction)
});
</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }

}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;
    &.center {
      width: 100% !important;
    }
  }

}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

</style>
