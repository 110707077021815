<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <div class="header-task ion-padding-top ion-padding-horizontal">
      <DropdownMenuTaskComponent
        v-if="canOptionsMenu"
        text-edit="advice.edit"
        text-delete="advice.delete"
        @click:delete="emit('click:delete')"
        @click:edit="showModalUpdateAdvice = true"
      />
    </div>
    <ion-content class="content-task ion-padding-horizontal">
      <h3>
        {{ advice.name }}
      </h3>
      <ion-chip>
        <ion-avatar>
          <VsxIcon iconName="Calendar"></VsxIcon>
        </ion-avatar>
        <ion-label>{{ dateFinish }}</ion-label>
      </ion-chip>
    </ion-content>

    <ModalComponent noIconClose v-model="showModalUpdateAdvice">
      <HeaderNavigationComponent
        preventDefault
        @click:back="showModalUpdateAdvice = false"
        :title="'Editar Aviso'"
        :class="isPlatformIOS ? 'safe-area' : ''"
      />

      <div class="ion-padding-top">
        <FormComponent
          v-if="showModalUpdateAdvice"
          no-cancel-button
          :model="model"
          form-mode="update"
          :id-model="advice.id"
          :schema="schema"
          @success="updateAdvice"
        >
          <template #submitButton>
            <ion-button mode="ios" expand="block" type="submit">
              Guardar
            </ion-button>
          </template>
        </FormComponent>
      </div>
    </ModalComponent>
  </ion-page>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { MODEL, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { schemanewAdvice } from "@/utils/getMapModel";
import { useRegister } from "@/composables/useRegister";
import dayjs from "dayjs";
import {
  IonPage,
  IonChip,
  IonContent,
  IonLabel,
  IonAvatar,
  IonButton,
  toastController,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";

interface Props {
  advice: any;
}

const schema: Ref<any> = ref(schemanewAdvice);
const showModalUpdateAdvice: Ref<boolean> = ref(false);
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const props = defineProps<Props>();
const emit = defineEmits(["click:back", "update:advices", "click:delete"]);

const model: Ref<any> = ref({
  name: MODEL.ADVICE,
  fields: [
    {
      name: "name",
      label: "Nombre del aviso",
      type: "textarea",
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
      default: props.advice.date_start,
    },
    {
      name: "date_end",
      label: "Fecha de finalización",
      type: "date",
      default: props.advice.date_end,
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
  ],
});

const updateAdvice = async () => {
  showModalUpdateAdvice.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("advice.updated_advice"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  emit("update:advices");
};

const dateFinish = computed(() => {
  const date = dayjs(props.advice.date_end);
  return `Finaliza el día ${date.format("DD")} de ${date.format(
    "MMM"
  )} del ${date.format("YYYY")}`;
});

const canOptionsMenu = computed(() => {
  return (
    props.advice.created_user_id === user.value.id ||
    user.value.role !== ROLES.EMPLOYEE
  );
});
</script>
<style lang="scss" scoped>
.safe-area {
  padding-top: 35px;
}
.header-task {
  display: flex;
  align-items: center;
  justify-content: end;
}
ion-chip {
  --background: #eceef3;
  --color: #5e6573;
  ion-avatar {
    svg {
      margin-left: 10px;
      width: 15px;
    }
  }
}
.avatar-container-task {
  display: flex;
  gap: 10px;
}
.description-task {
  color: #5e6573;
  font-size: 14px;
}
.content-task {
  h3 {
    margin-top: 0px;
  }
}
</style>
