<template>
  <ion-page @click.prevent="closeContextualMenu">
    <ion-content class="ion-padding">
      <ion-header class="talkie-wrapper">
        <div class="talkie-wrapper__back">
          <ion-back-button
            text=""
            color="neutral-80"
            defaultHref="/talkie"
          ></ion-back-button>
        </div>
        <div class="talkie-wrapper__locale" v-if="user">
          <avatar-component
            :image-id="user.image_id ?? -1"
            :user-id="user.id"
          />
          <div class="locale-info-wrapper">
            <h3 id="locale_name" class="ion-padding">{{ user.last_name }}</h3>
          </div>
        </div>
        <div class="talkie-wrapper__options">
          <DropdownMenuAudioPersonalComponent />
        </div>
      </ion-header>
      <ion-content class="message-container">
        <div class="message-container__grouped" v-for="key in keys" :key="key">
          <p
            class="caption message-container__grouped__title"
            v-if="grouped[key].length"
          >
            {{ $t(`global.${key}`) }}
          </p>
          <MessageWrapper
            :notifications="notifications"
            :message="message"
            v-for="message in grouped[key]"
            :key="message"
          />
        </div>
        <div
          class="message-container__delete-message"
          v-if="notificationList.length > 0"
        >
          <span class="register-link" @click="deleteNotifications">{{
            $t("talkie.markAsListened")
          }}</span>
        </div>
        <ion-infinite-scroll @ionInfinite="ionInfinite">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
      <RecordBarComponent v-if="showRecord" :show-all-options="false" />
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import {
  InfiniteScrollCustomEvent,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { computed, onMounted, ref } from "vue";
import { useMessage } from "@/composables/useMessage";
import MessageWrapper from "@/components/admin/talkie/MessageWrapper.vue";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import { useRoute } from "vue-router";
import DropdownMenuAudioPersonalComponent from "@/components/admin/talkie/DropdownMenuAudioPersonalComponent.vue";
import { useUser } from "@/composables/useUser";
import { IUser } from "@/interfaces/login/ILoginResponse";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import { handleRemoveNotification } from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { messages, notifications, grouped, getLocationMessagesByUser, setPage } =
  useMessage();
const { getUserInfo } = useUser();
const { setNotifications } = useRegister();

const showRecord = ref(false);
const route = useRoute();

const user = ref<IUser>();

const keys = computed(() => {
  return Object.keys(grouped.value);
});

const notificationList = computed(() => {
  return notifications.value;
});

/**
 * Close contextual menu
 */
const closeContextualMenu = () => {
  coreStore.setShowDropdown(false);
}

/**
 * Delete all notifications
 */
const deleteNotifications = () => {
  coreStore.setShowDropdown(false);
  notifications.value.forEach((notifications) => {
    handleRemoveNotification(notifications.message_id).then(() => {
      setNotifications();
    });
  });
};


/**
 * Infinite scroll handler
 * @param ev
 */
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
  setPage();
  await getLocationMessagesByUser(
    registerStore.location.id,
    user.value?.id.toString()
  );
  setTimeout(() => ev.target.complete(), 500);
};

onMounted(async () => {
  const userId = route.params.id!.toString() ?? null;
  const users = await getUserInfo(parseInt(userId));
  user.value = users.items[0];
  await getLocationMessagesByUser(registerStore.location.id, userId);
});
</script>
<style scoped lang="scss">
.message-container {
  height: 85%;

  &__grouped {
    p {
      text-align: center;
    }

    &__title {
      text-transform: capitalize;
    }
  }
}

.locale-info-wrapper {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  h3 {
    margin: 0px;
    padding: 0px;
  }

  p {
    margin: 0px;
    padding: 0px;
  }
}

.talkie-wrapper {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__locale {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    h3 {
      text-align: left;
    }
  }

  &__options {
    position: absolute;
    right: 10px;
    top: 30px;
  }
}

.register-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-underline: none;
  color: #4178f5;
  cursor: pointer;
  font: {
    weight: 600;
  }
}
</style>
