<template>
  <div
    class="
      ion-flex
      ion-justify-content-start
      ion-align-items-center
      ion-padding-top
      ion-padding-start
    "
  >
    <div @click="backClickManually">
      <VsxIcon iconName="ArrowLeft2"></VsxIcon>
    </div>
    <h3 class="ion-no-margin ion-padding-start">{{ title }}</h3>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import {CoreStore, useCoreStore} from "@/stores/core";

const coreStore: CoreStore = useCoreStore();

interface Props {
  title: string;
  preventDefault?: boolean;
}
const props = defineProps<Props>();

const router = useRouter();
const emit = defineEmits(["click:back"]);

const backClickManually = () => {
  if (props.preventDefault) {
    emit("click:back");
  } else {
    //router.go(-1);
    coreStore.setNewLocal(false);
    router.push({name: 'admin.home'})
  }
};
</script>
<style scoped lang="scss"></style>
