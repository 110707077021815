<template>
  <div
    :class="[
      item['status'] == APPLICATION_STATUS.WAITING
        ? 'action-wrapper-waiting'
        : 'action-wrapper-rejected',
    ]"
  >
    <ion-button
      fill="clear"
      :id="`open-modal${item['location_code']}${item['id']}`"
    >
      <VsxIcon
        size="15"
        :iconName="
          item['status'] == APPLICATION_STATUS.WAITING
            ? 'Clock'
            : 'CloseCircle'
        "
        slot="start"
      ></VsxIcon>
    </ion-button>
    <ion-modal
      :trigger="`open-modal${item['location_code']}${item['id']}`"
      :initial-breakpoint="0.5"
      :breakpoints="[0, 0.25, 0.5, 0.75]"
      handle-behavior="cycle"
    >
      <ion-content class="ion-padding">
        <div class="ion-margin-top">
          <ion-label
            >{{
              item.status == APPLICATION_STATUS.WAITING
                ? $t("joinTeam.requestWaitingMessage", {
                    name: location["name"],
                  })
                : $t("joinTeam.requestRejectedMessage", {
                    name: location["name"],
                  })
            }}
          </ion-label>
          <section class="join-wrapper__body">
            <img :src="teamPreview" alt="" />
            <h3 class="join-wrapper__title">{{ location["name"] ?? "" }}</h3>
            <p class="join-wrapper__description">
              {{ location["address"] }}
            </p>
          </section>
        </div>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script setup lang="ts">
import { IonButton, IonContent, IonLabel, IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { onMounted, ref, Ref } from "vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { APPLICATION_STATUS, MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import teamPreview from "@/assets/team-preview.svg";

interface Props {
  item: any;
}

const props = defineProps<Props>();

const location: Ref<ILocation | undefined> = ref<ILocation>();

onMounted(() => {
  try {
    const payload = {
      model: MODEL.LOCATION,
      fields: [
        "id",
        "name",
        "address",
        "country_id",
        "phone_number",
        "email",
        "local_type",
        "code",
        "entity_id",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "code",
        op: "==",
        value: props.item.location_code,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      location.value = data.items[0] as ILocation;
    });
  } catch (err) {
    console.error(err);
  }
});
</script>

<style scoped lang="scss">
.join-wrapper {
  &__body {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }
}

.action-wrapper-waiting {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #dee6f7;
  color: #4178f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-wrapper-rejected {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f9e8e7;
  color: #cf2617;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
