<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <section class="join-wrapper__header">
        <div>
          <img :src="wehopBrand" alt="WeHop Brand" />
        </div>
        <div>
          <DropdownMenuComponent
            @click:profile="openProfile"
            @click:settting="openSetting"
            @click:logout="openLogout"
          />
        </div>
      </section>
      <section class="join-wrapper__body">
        <img :src="joinSent" />
        <h3 class="join-wrapper__title">{{ $t("joinTeam.requestSent") }}</h3>
        <p class="join-wrapper__description caption">
          {{ $t("joinTeam.requestSentMessage") }}
        </p>
        <ion-button
          class="join-wrapper__button"
          fill="outline"
          expand="block"
          style="width: 100%"
          router-link="/join/validate"
        >
          <ion-icon :icon="addOutline"></ion-icon>
          {{ $t("actions.joinAnotherTeam") }}
        </ion-button>
        <ion-button
          class="join-wrapper__button"
          fill="solid"
          expand="block"
          style="width: 100%"
          @click="viewRequest"
        >
          {{ $t("actions.viewMyRequest") }}
        </ion-button>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/vue";
import { addOutline } from "ionicons/icons";
import wehopBrand from "@/assets/wehop-brand.svg";
import joinSent from "@/assets/join-sent.svg";
import router from "@/router";
import DropdownMenuComponent from "@/components/shared/avatar/DropdownMenuComponent.vue";
import { handleLogout } from "@/services/auth";
import useCore from "@/composables/useCore";

const { setResetPinDigit, setResetForm } = useCore();

const viewRequest = () => {
  router.push({ name: "joinRequestInitialList" });
};

const openProfile = () => {
  router.push({ name: "myProfileEmployee" });
};
const openSetting = () => {
  router.push({ name: "mySettingEmployee" });
};
const openLogout = async () => {
  try {
    await handleLogout().then(async () => {
      setResetPinDigit(true);
      setResetForm(true);
      localStorage.clear();
      setTimeout(async () => {
        await router.push({ name: "login" });
      }, 500);
    });
  } catch (e) {
    console.error(e);
  }
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.join-wrapper {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    margin: 30px 0 0 0;
  }

  &__description {
    margin: 30px 0 0 0;
  }

  &__button {
    margin: 30px 0 0 0;
  }
}
</style>
