<template>
  <ion-page class="ion-md-padding">
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Formulario</ion-card-title>
          <ion-card-subtitle
            >Ubicación:
            /components/shared/form/FormComponent.vue</ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          <FormComponent
            :schema="schema"
            :model="model"
            @success="handleSuccess"
          />
          <div class="ion-padding" v-if="form">
            <ion-card-subtitle>Resultado:</ion-card-subtitle>
            <pre v-highlightjs>
              <code class="javascript">{{ form }}</code>
            </pre>
          </div>

          <ion-card-header>
            <ion-card-title>Ejemplo de uso</ion-card-title>
            <ion-card-subtitle
              >Ubicación: /views/docs/IndexView.vue</ion-card-subtitle
            >
          </ion-card-header>
          <div class="ion-padding">
            <pre v-highlightjs>
              <code class="vue ion-padding">{{ code }}</code>
            </pre>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<script lang="ts" setup>
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref } from "vue";
import type { Ref } from "vue";
import { mapModel, mapSchema } from "@/utils/getMapModel";
import { code } from "@/utils/docs/formComponent";
import { handleGetDescribeModel } from "@/services/generic";
const form = ref(null);
const handleSuccess = (values: any) => {
  form.value = values;
};

const schema: Ref<any> = ref(null);
const model: Ref<any> = ref({
  name: "userlocation",
  fields: [],
});

const getModel = async () => {
  const fields = "fields=location_id,user_id,role_id,workdays,shift_days";
  const describe = await handleGetDescribeModel(model.value.name, fields);
  const modelMap = mapModel(describe);
  const schemaMap = mapSchema(describe);
  schema.value = schemaMap;
  model.value.fields = modelMap;
  console.log(model.value);
};
getModel();
</script>
<style scoped>
ion-card {
  --background: white;
}
</style>
