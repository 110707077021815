<template>
  <ForgotPasswordWrapper>
    <template #step>
      <h2 class="forgot-password-wrapper__title">
        {{ $t("forgotPassword.title") }}
      </h2>
      <p class="forgot-password-wrapper__subtitle text-body-s">
        {{ $t("forgotPassword.description") }}
      </p>
      <FormComponent
        :model="model"
        :schema="schemaForgotPassword"
        no-cancel-button
        @success="forgotPassword"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("actions.continue") }}
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </ForgotPasswordWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaForgotPassword } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { useForgotPassword } from "@/composables/useForgotPassword";
import ForgotPasswordWrapper from "@/views/forgot_password/ForgotPasswordWrapper.vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import i18n from "@/plugins/i18n";
import { SMS_TYPE } from "@/utils/constants";

const { phone, phonePrefix, subjectSMS, sendPhone } = useForgotPassword();
const coreStore: CoreStore = useCoreStore();

const model: Ref<any> = ref({
  name: "forgot_password",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("forgotPassword.phone"),
      type: "tel",
      icon: "call",
    },
  ],
});

onMounted(() => {
  coreStore.resetHelpStep();
});

const forgotPassword = async (values: any) => {
  const phoneNumberSplit = values.phone.split("|");
  phonePrefix.value = phoneNumberSplit[0];
  phone.value = phoneNumberSplit[1];
  subjectSMS.value = SMS_TYPE.FORGOT_PASSWORD;
  await sendPhone();
};
</script>
<style scoped lang="scss">
.button--login {
  width: 100%;
}
</style>
