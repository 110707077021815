<template>
  <div>
    <div ref="PinCode" class="pin-container">
      <input
          type="tel"
          class="pin-code"
          v-for="(el, ind) in digits"
          :key="el + ind"
          v-model="digits[ind]"
          :autofocus="ind === 0"
          placeholder="."
          maxlength="1"
          @keydown="handleKeyDown($event, ind)"
          @paste="handlePaste($event)"
          :class="{ bounce: digits[ind] !== null }"
      />
    </div>
    <ion-note
        slot="helper"
        color="neutral-80"
        v-if="errorMessage"
        class="pin-code-description"
    >
      {{ errorMessage }}
    </ion-note>
    <ion-note v-else color="neutral-80" class="pin-code-description">
      {{ normalMessage }}
    </ion-note>
  </div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, reactive, Ref, ref, watch} from "vue";
import {IonNote} from "@ionic/vue";
import i18n from "@/plugins/i18n";
import useCore from "@/composables/useCore";

const { resetPinDigit, setResetPinDigit } = useCore();

const props = defineProps({
  default: String,
  digitCount: {
    type: Number,
    required: true,
    default: 5,
  },
});

const digits = reactive<string[]>([]);
const errorMessage = ref<string | undefined>(null);
const normalMessage = ref<string | undefined>(i18n.global.t("rules.pinCode"));

if (props.default) {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = props.default.charAt(i);
  }
  if (digits.join("").length === props.digitCount) {
    errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
  } else {
    errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
  }
} else {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = "";
  }
}

const PinCode: Ref<HTMLElement | undefined> = ref();
const emit = defineEmits(["update:otp"]);
const isDigitsFull = function () {
  for (const elem of digits) {
    if (elem == null || elem == undefined || elem == ".") {
      return false;
    }
  }
  return true;
};
const handleKeyDown = function (event: KeyboardEvent, index: number) {
  console.log(event.key);

  if (
      event.key !== "Tab" &&
      event.key !== "ArrowRight" &&
      event.key !== "ArrowLeft" &&
      event.key !== "Paste"
  ) {
    event.preventDefault();
  }

  if (event.key === "Backspace") {
    digits[index] = "";

    if (index != 0) {
      PinCode.value!.children[index - 1].focus();
    }
    errorMessage.value = i18n.global.t("rules.pinCode");
    return;
  }


  if (new RegExp("^([0-9])$").test(event.key)) {
    digits[index] = event.key;
    if (index != props.digitCount - 1) {
      PinCode.value!.children[index + 1].focus();
    }
    if (isDigitsFull()) {
      emit("update:otp", digits.join(""));
      if (digits.join("").length === props.digitCount) {
        errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
      } else {
        errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
      }
    }
  }
};

const handleInputChange = function (event: Event) {
  const value = (event.target as HTMLInputElement).value;
  console.log(value);
}

const handlePaste = (event: ClipboardEvent) => {
  const clipboardData = event.clipboardData || (window as any).clipboardData;
  if (clipboardData) {
    const pastedText = clipboardData.getData('text/plain').trim();

    if (/^[0-9]{5}$/.test(pastedText)) {
      for (let i = 0; i < 5; i++) {
        digits[i] = pastedText.charAt(i);
      }
    }

    // Prevent the default paste behavior
    event.preventDefault();
    
    if (isDigitsFull()) {
      emit("update:otp", digits.join(""));
      if (digits.join("").length === props.digitCount) {
        errorMessage.value = i18n.global.t("rules.pinCodeCompleted");
      } else {
        errorMessage.value = i18n.global.t("rules.pinCodeNotCompleted");
      }
    }
  }
};

watch(resetPinDigit, () => {
    for (let i = 0; i < props.digitCount; i++) {
      digits[i] = "";
    }
})

onBeforeUnmount(() => {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = "";
  }
})

</script>

<style scoped lang="scss">
.pin-container {
  display: flex;
  justify-content: space-between;
}

.pin-code {
  height: 56px;
  width: 56px;
  border: 0px solid black;
  display: inline-block;
  border-radius: 5px;
  margin: 5px;
  padding: 15px;
  font: {
    size: 16px;
    weight: 600;
  }
  background-color: white;
  text-align: center;

  &:focus {
    outline: 1px solid white;
  }
}

.bounce {
  animation: pulse 0.3s ease-in-out alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.pin-code-description {
  display: block;
  font: {
    size: 13px;
  }
}
</style>
