<template>
  <div class="autocomplete">
    <ion-item
      fill="solid"
      class="input">
      <VsxIcon
        v-if="icon" 
        :iconName="icon" 
        slot="start"     
        class="ion-margin-end"    
        :color="iconColor"/>
      <input
        autocomplete="off"
        :id="'autocomplete-input-' + model"
        class="input"
        :placeholder="placeholder"
        type="text"
        v-model="selected"
        @focus="() => changeFocus(true)"
        @blur="()=> changeFocus(false)"
        @input="handleInput"/>
    </ion-item>

    <ul v-if="showResults && focused" class="autocomplete-results list-group">
      <li
        v-for="result in results"
        :key="result.id"
        @click="handleSelect(result)"
        class="list-group-item"
      >
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { handleReadModel } from "@/services/generic";
import { ref, computed, watch, onMounted, toRefs } from "vue";
import { IonItem } from "@ionic/vue"

interface Props {
  icon?: string;
  modelValue: any;
  placeholder?: string;
  searchFunction: any;
  itemTitle: string;
  itemValue?: string;
  onSelect: any;
  model: string;
}
const focused = ref(false);
const iconColor = "#787F8C"
const props = defineProps<Props>();
const modelValueRef = toRefs(props).modelValue;
const results = ref<any[]>([]);
const selected = ref<any>("");
const showResults = computed(() => results.value.length > 0);

function handleInput(event: Event) {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  if (value) {
    props.searchFunction(value).then((data: any[]) => {
      results.value = data;
    });
  } else {
    results.value = [];
  }
}

function changeFocus(_focused: boolean) {
  // the settimeout is so that the click on the options is triggered before the assignment
  setTimeout(() => {
    focused.value = _focused
  }, 500);
}

function handleSelect(result: any) {
  results.value = [];
  selected.value = result[props.itemTitle];
  props.onSelect(result);
}
watch(modelValueRef, () => {
  getModelDefault();
});
const getModelDefault = async () => {
  if (props.modelValue) {
    const model = await handleReadModel({
      model: props.model,
      id: props.modelValue,
    });
    selected.value = model[props.itemTitle];
  }
};
onMounted(() => {
  getModelDefault();
});
</script>

<style scoped lang="scss">
.autocomplete {
  position: relative;

  .item-fill-solid {
    --border-radius: 8px 8px 0px 0px;
    --highlight-height: 2px;
    --background: #f3f5fa !important;
    --background-hover: #f3f5fa !important;
  }
  .form-label {
    margin-bottom: 0.5rem;
    display: block;
  }

  ion-item{
    --padding-end: 0px;
    --inner-padding-end: 0px;
  }
  input.input {
    --color: #464c59;
    --placeholder-color: #464c59;
    --placeholder-opacity: 0.8;
    --padding-bottom: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    height: 100%;
    width: 100%;
    border-color: transparent;
    background-color: transparent;
    outline: none;
    border-width: 0px;
    border-bottom-width: 2px;
    box-sizing: border-box;
    &:focus {
      border-bottom: 2px solid var(--highlight-color-focused);
    }
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #f3f5fa;
    border: none;
    border-bottom: 1px solid #ced4da;

    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      border: none;
      border-bottom: 1px solid #ced4da;

      outline: none;
    }
  }
 
  ion-input.input {
  --color: #464c59;
  --placeholder-color: #464c59;
  --placeholder-opacity: 0.8;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

  .autocomplete-results {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background: white;
    margin: 0;
    padding: 0;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    list-style: none;
    max-height: 200px;
    overflow: auto;

    .list-group-item {
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: #e9ecef;
      }
    }
  }
}
</style>
