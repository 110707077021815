<template>
  <ion-page @click="closeMenuContextual">
    <WrapperPageComponent>
      <template #options>
        <DropdownMenuButtonNative v-if="isPlatformAndroid"
          @click:button="openButton"
        ></DropdownMenuButtonNative>
      </template>
      <ion-content class="ion-padding-horizontal">
        <div>
          <ion-searchbar
            @ion-input="filterList"
            :placeholder="$t('talkie.searchByName')"
            mode="ios"
          ></ion-searchbar>
        </div>
        <div class="ion-flex ion-justify-content-between">
          <p class="subtitle">{{ $t("talkie.yourTalks") }}</p>
          <div class="toggle-list ion-flex ion-align-items-center">
            <div
              @click="listMode = !listMode;"
              class="item-toggle-list"
              :class="{ 'item-toggle-list-active item-left': !listMode }"
            >
              <VsxIcon iconName="Category" />
            </div>
            <div
              ref="tourChat"
              @click="changeMenu"
              class="item-toggle-list"
              :class="{ 'item-toggle-list-active item-right': listMode }"
            >
              <div
                v-if="notificationsLocation > 0"
                class="item-toggle-list__notification"
              >
              </div>
              <VsxIcon iconName="Message" />
            </div>
          </div>
        </div>
        <div class="ion-padding-top" ref="tourChatView">
          <AvatarListComponent :listMode="listMode"></AvatarListComponent>
        </div>
      </ion-content>
      <div class="splash-record" v-if="recording"></div>
      <RecordBarComponent />
    </WrapperPageComponent>
  </ion-page>
</template>
<script setup lang="ts">
import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { computed, onMounted, ref } from "vue";
import { IonContent, IonPage, IonSearchbar } from "@ionic/vue";
import AvatarListComponent from "@/components/admin/talkie/AvatarListComponent.vue";
import useRecord from "@/composables/useRecord";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";
import DropdownMenuButtonNative from "@/components/admin/talkie/DropdownMenuButtonNative.vue";
import { RecordButton } from "capacitor-record-button/src";
import i18n from "@/plugins/i18n";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { TourStore, useTourStore } from "@/stores/tour";
import { RegisterStore, useRegisterStore } from "@/stores/register";

const { notifications, setNotifications } = useRegister();
const coreStore: CoreStore = useCoreStore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
import { useShepherd } from "vue-shepherd";
import { usePlatform } from "@/composables/usePlatform";
const { isPlatformAndroid } = usePlatform();

const listMode: Ref<boolean> = ref(false);
const { recording } = useRecord();
const { user, handleGetMe } = useRegister();

const notificationsLocation = computed(() => {
  const notification = notifications.value.find((notification) => {
    if ("location_id" in notification) {
      return notification;
    }
  });
  if (notification) {
    const total: number = notification["number notifications"] ?? 0;
    return total;
  } else {
    return 0;
  }
});

const filterList = (searchValue: any) => {
  coreStore.searchUser(searchValue.target.value);
};

const closeMenuContextual = () => {
  coreStore.setShowDropdown(false);
};

const openButton = () => {
  RecordButton.openButtonRecord();
  coreStore.setShowDropdown(false);
};

const tourChat = ref(null);
const tourChatView = ref(null);

const tourChatWrapper = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const changeMenu = () => {
  if (!user.value.is_talkie_chat && !listMode.value) {
    tourChatWrapper.addStep({
      attachTo: { element: tourChat.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieChatTour({ show: true, step: 1 });
            listMode.value = !listMode.value;
            tourChatWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_7.title"),
      text: i18n.global.t("tour.tour5_7.text"),
    });
    tourChatWrapper.addStep({
      attachTo: { element: tourChatView.value, on: "bottom" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieChatTour({ show: true, step: 1 });
            listMode.value = !listMode.value;
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_chat: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tourChatWrapper.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_8.title"),
      text: i18n.global.t("tour.tour5_8.text"),
    });
    tourChatWrapper.start();
  } else {
    listMode.value = !listMode.value;
  }
};

onMounted(() => {
  setNotifications();
});
</script>
<style lang="scss" scoped>
.splash-record {
  height: 100vh;
  position: absolute;
  width: 100vw;
  background-color: rgba(213, 211, 211, 0.543);
}

.subtitle {
  color: var(--ion-color-neutral-80);
  font-weight: 600;
  font-size: 16px;
}

ion-searchbar {
  padding: 0 !important;
  --background: white !important;
  --color: #5e6573 !important;
  --placeholder-color: #5e6573 !important;
  --placeholder-opacity: 1 !important;
}

.toggle-list {
  background-color: white;
  border-radius: 8px;
  color: #4178f5;

  .item-toggle-list {
    position: relative;
    padding: 0px 15px;
    position: relative;

    &__notification {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #4178f5;
      position: absolute;
      top: 0px;
      right: 15px;
    }
  }

  .item-toggle-list-active {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    border-radius: 8px 0px 0px 8px;
    background-color: #4178f5;
  }

  .item-left {
    border-radius: 8px 0px 0px 8px;
  }

  .item-right {
    border-radius: 0px 8px 8px 0px;
  }
}
</style>
