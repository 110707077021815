<template>
  <RegisterWrapper
    :show-progress="false"
    :title-button-back="$t('register.createProfile')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <h2 class="forgot-password-wrapper__title form-padding">
        {{ $t("register.verificationCode") }}
      </h2>
      <p class="forgot-password-wrapper__subtitle text-body-s">
        {{ $t("register.verificationCodeDescription") }}
      </p>
      <FormComponent
        ref="form"
        :model="model"
        :schema="schemaPinCode"
        no-cancel-button
        @success="pinCodeSubmit"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("register.continue") }}
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPinCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { CoreStore, useCoreStore } from "@/stores/core";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import { USER_TYPES } from "@/utils/constants";
import { usePlatform } from "@/composables/usePlatform";

const coreStore: CoreStore = useCoreStore();
const { isPlatformIOS } = usePlatform();

const { pin, pinCodeValid, sendPin, savePin } = useRegister();
const form: Ref<any> = ref(null);
const model: Ref<any> = ref({
  name: "pin_code",
  fields: [
    {
      name: "pin",
      label: "Escribe el código de verificación",
      type: "pin",
    },
  ],
});

const pinCodeSubmit = async (values: any) => {
  pin.value = values.pin;
  pinCodeValid.value = true;
  if (coreStore.userType == USER_TYPES.EMPLOYEE) {
    await sendPin().then(() => {
      form.value.handleResetForm();
    });
  } else {
    await savePin().then(() => {
      form.value.handleResetForm();
    });
  }
};
</script>
<style scoped lang="scss">
.form-padding {
  padding: 60px 0px 0px 0px;
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
