<template>
  <select-component
    :model-value="modelValue"
    :search-function="getModel"
    :on-select="selectOption"
    :item-title="itemTitle"
    :model="model"
    :icon="icon"/>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import { handleSearchReadModel } from "@/services/generic";
import SelectComponent from "./SelectComponent.vue";

export interface Props {
  modelValue: any;
  itemValue?: string;
  itemTitle?: string;
  model: string;
  filters?: any;
  fieldSearch?: string;
  icon?:string;
}

const props = withDefaults(defineProps<Props>(), {
  itemValue: "id",
  itemTitle: "name",
  model: "",
  filters: [],
});

const emit = defineEmits(["update:modelValue", "select"]);

const data: Ref<any> = ref([]);

const getModel = async (value = "") => {
  try {
    const search = props.fieldSearch || "";
    const payload = {
      model: props.model,
      fields: [props.itemTitle, props.itemValue, search],
    };
    const filter = [
      ...props.filters,
      ...[
        {
          field: props.itemTitle,
          op: "ilike",
          value: value,
        },
      ],
    ];

    const { items } = await handleSearchReadModel(payload, filter);
    data.value = items;
    return items;
  } catch (err) {
    console.error(err);
  }
};

const selectOption = (value: any) => {
  emit("select", value);
  emit("update:modelValue", value[props.itemValue]);
};
getModel();
</script>
