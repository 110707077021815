<template>
  <div>
    <div class="filter-search">
      <ion-searchbar
        v-model="search"
        :placeholder="$t('global.searchByName')"
      ></ion-searchbar>
    </div>
    <div class="filter-priority ion-padding-vertical">
      <span>
        {{ $t("tasks.priority") }}
      </span>
      <ion-item>
        <ion-checkbox
          slot="end"
          :checked="is_priority_all"
          @ion-change="(e:any) => is_priority_all = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <span class="label-black"> {{ $t("tasks.all") }}</span>
        </div>
      </ion-item>
      <ion-item>
        <ion-checkbox
          slot="end"
          :checked="is_priority_true"
          @ion-change="(e:any) => is_priority_true = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <VsxIcon iconName="ArrowCircleUp2" class="ion-margin-end"></VsxIcon>
          <span class="label-black">{{ $t("tasks.priorities") }}</span>
        </div>
      </ion-item>
      <ion-item>
        <ion-checkbox
          slot="end"
          :checked="is_priority_false"
          @ion-change="(e:any) => is_priority_false = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <VsxIcon iconName="MinusCirlce" class="ion-margin-end"></VsxIcon>
          <span class="label-black">{{ $t("tasks.no_priorities") }}</span>
        </div>
      </ion-item>
    </div>
    <hr />
    <div class="filter-priority ion-padding-vertical">
      <span>
        {{ $t("tasks.assigned_tasks") }}
      </span>
      <ion-item>
        <ion-checkbox
          slot="end"
          :checked="is_assigned_all"
          @ion-change="(e:any) => is_assigned_all = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <span class="label-black">{{ $t("tasks.all") }}</span>
        </div>
      </ion-item>
      <ion-item>
        <ion-checkbox
          slot="end"
          :checked="is_assigned_false"
          @ion-change="(e:any) => is_assigned_false = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <VsxIcon iconName="Information" class="ion-margin-end"></VsxIcon>
          <span class="label-black">{{ $t("tasks.no_assigned") }}</span>
        </div>
      </ion-item>
      <ion-item class="ion-padding-bottom">
        <ion-checkbox
          slot="end"
          :checked="is_assigned_true"
          @ion-change="(e:any) => is_assigned_true = e.detail.checked"
        ></ion-checkbox>
        <div class="ion-flex ion-align-items-center">
          <VsxIcon iconName="TickCircle" class="ion-margin-end"></VsxIcon>
          <span class="label-black">{{ $t("tasks.assigned") }}</span>
        </div>
      </ion-item>
      <span>
        {{ $t("tasks.assigned_to") }}
      </span>
      <div class="ion-margin-vertical">
        <SelectAutocompleteComponent
          v-model="assigned_filter_value"
          item-title="name"
          item-value="id"
          :field-search="assigned_filter.name"
          :model="assigned_filter.model"
          :filters="assigned_filter.filters"
          :icon="assigned_filter.icon"
        />
      </div>
    </div>
    <div
      class="buttons ion-flex ion-align-items-center ion-justify-content-center"
    >
      <ion-button class="button-clear" fill="outline" @click="clearFilters">
        Limpiar Filtros
      </ion-button>
      <ion-button class="button-submit" @click="getFilters">
        <VsxIcon iconName="Sort"></VsxIcon>
        <span> Filtrar </span>
      </ion-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import SelectAutocompleteComponent from "@/components/shared/form/SelectAutocompleteComponent.vue";
import { IonButton, IonItem, IonSearchbar, IonCheckbox } from "@ionic/vue";
import { useRegister } from "@/composables/useRegister";
import { useGenericStore } from "@/stores/generic";
import { ENUM_TASK, MODEL, TASKS_VIEW } from "@/utils/constants";
import { toRefs } from "vue";

interface Props {
  tab: string;
}

const props = defineProps<Props>();
const tabRef = toRefs(props).tab;
const search: Ref<any> = ref(null);
const is_priority_all: Ref<any> = ref(null);
const is_priority_true: Ref<any> = ref(null);
const is_priority_false: Ref<any> = ref(null);

const is_assigned_all: Ref<any> = ref(null);
const is_assigned_true: Ref<any> = ref(null);
const is_assigned_false: Ref<any> = ref(null);

const assigned_filter_value: Ref<any> = ref(null);

const { user } = useRegister();
const storeList = useGenericStore();

const assigned_filter: Ref<any> = ref({
  name: "assigned_user_id",
  label: "Asignado a ",
  icon: "SearchNormal",
  type: "autocomplete",
  model: "user",
  filters: [
    {
      model: "userlocation",
      foreign_key: "user_id",
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ],
});

const emit = defineEmits(["update:filters"]);

const getFilters = () => {
  const filters: any = [
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ];

  if (tabRef.value === TASKS_VIEW.PENDING) {
    const filterPriority = {
      field: "status",
      value: ENUM_TASK.pending,
      op: "==",
    };
    filters.push(filterPriority);
  } else {
    const filterPriority = {
      field: "status",
      value: ENUM_TASK.finished,
      op: "==",
    };
    filters.push(filterPriority);
  }

  if (is_priority_true.value) {
    const filterPriority = {
      field: "is_priority",
      op: "==",
      value: true,
    };
    filters.push(filterPriority);
  }
  if (is_priority_false.value) {
    const filterPriority = {
      field: "is_priority",
      op: "==",
      value: false,
    };
    filters.push(filterPriority);
  }

  if (is_assigned_true.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "!=",
      value: null,
    };
    filters.push(filterAssigned);
  }
  if (is_assigned_false.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "==",
      value: null,
    };
    filters.push(filterAssigned);
  }
  if (assigned_filter_value.value) {
    const filterAssigned = {
      field: "assigned_user_id",
      op: "==",
      value: assigned_filter_value.value,
    };
    filters.push(filterAssigned);
  }
  if (search.value) {
    const filterAssigned = {
      field: "title",
      op: "ilike",
      value: search.value,
    };
    filters.push(filterAssigned);
  }
  storeList.setFiltersModel(MODEL.TASK, filters);
  emit("update:filters");
};

const clearFilters = () => {
  is_priority_all.value = null;
  is_priority_true.value = null;
  is_priority_false.value = null;
  is_assigned_all.value = null;
  is_assigned_true.value = null;
  is_assigned_false.value = null;
  assigned_filter_value.value = null;
  search.value = null;
  storeList.setFiltersModel(MODEL.TASK, []);
  emit("update:filters");
};
</script>
<style lang="scss" scoped>
.button-filter {
  --background: #f8f9fc;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 5px;
  height: 40px;
  width: 60px !important;
  --border-radius: 8px;
}
ion-checkbox::part(container) {
  border-radius: 6px;
}
ion-checkbox {
  --size: 20px;
}
.filter-priority {
  ion-item {
    padding-top: 10px;
    --padding-start: 0px;
    --inner-border-width: 0px;
    div {
      svg {
        color: #5e6573;
        width: 21.5px;
      }
    }
  }
}
.label-black {
  font-size: 16px;
  font-weight: 600;
  color: #464c59;
}
hr {
  background-color: #c7ccd680;
}
.filter-search {
  ion-searchbar {
    padding: 0px !important;
  }
}
.button-clear,
.button-submit {
  width: 50%;
}
</style>
