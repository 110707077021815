<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <ol>
      <li @click="emit('click:edit')">
        <ion-item :button="true" :detail="false" class="options_actions">
          <VsxIcon iconName="Edit2" color="gray" />
          <span class="ion-padding-start">{{ $t(textEdit) }}</span>
        </ion-item>
      </li>
      <li @click="emit('click:delete')" class="item-delete">
        <ion-item :button="true" :detail="false" class="options_actions">
          <VsxIcon iconName="Trash" />
          <span class="ion-padding-start">{{ $t(textDelete) }}</span>
        </ion-item>
      </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import { VsxIcon } from "vue-iconsax";
import { IonItem } from "@ionic/vue";

interface Props {
  textEdit: string;
  textDelete: string;
}

defineProps<Props>();

const emit = defineEmits(["click:edit", "click:delete"]);
</script>
<style scoped lang="scss">
.item-delete {
  ion-item {
    color: #cf2617;
    svg {
      color: #cf2617;
    }
  }
}
ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
.options_actions {
  svg {
    margin-left: 10px;
  }
}
</style>
