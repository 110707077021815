<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="join-wrapper__back">
        <ion-back-button
          :text="$t('joinTeam.myWorkspaces')"
          color="neutral-80"
          defaultHref="/join/sent"
        ></ion-back-button>
      </div>
      <section class="join-wrapper__body">
        <ion-segment
          color="primary"
          :value="selected"
          mode="md"
          @ionChange="applicationSelected($event)"
        >
          <ion-segment-button :value="APPLICATION_STATUS.ACCEPTED">
            <ion-label>{{ $t("joinTeam.actives") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button :value="APPLICATION_STATUS.WAITING">
            <ion-label>{{ $t("joinTeam.requests") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </section>
      <section>
        <ApplicationActiveComponent
          v-if="selected == APPLICATION_STATUS.ACCEPTED"
        />
        <ApplicationRequestComponent
          v-if="selected == APPLICATION_STATUS.WAITING"
        />
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonBackButton,
  IonContent,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
} from "@ionic/vue";
import router from "@/router";
import { ref } from "vue";
import ApplicationRequestComponent from "@/components/shared/applications/ApplicationRequestComponent.vue";
import ApplicationActiveComponent from "@/components/shared/applications/ApplicationActiveComponent.vue";
import { APPLICATION_STATUS, MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";

import { useRegister } from "@/composables/useRegister";
import { handleMe } from "@/services/me";
import { IUser } from "@/interfaces/login/ILoginResponse";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useLocation } from "@/composables/useLocation";

const { user } = useRegister();
const registerStore: RegisterStore = useRegisterStore();
const { getLocation } = useLocation();
const { setLocation } = useRegister();

const selected = ref(APPLICATION_STATUS.WAITING);

const applicationSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? APPLICATION_STATUS.WAITING;
};

const handleRefresh = () => {
  const payload = {
    model: MODEL.APPLICATION,
    fields: ["id", "location_code", "user_id"],
  };
  const filter = [
    {
      field: "status",
      op: "in",
      value: ["accepted"],
    },
    {
      field: "user_id",
      op: "==",
      value: user.value.id,
    },
  ];
  handleSearchReadModel(payload, filter).then(async (data) => {
    if (data.items.length > 0) {
      handleMe().then(async (data: IUser) => {
        registerStore.setUser({
          user: data,
        });
        const location = await getLocation(
          registerStore.user.location_id ?? -1
        );
        if (location) {
          setLocation(location[0]);
        }
        await router.push({ name: "joinRequestList" });
      });
    } else {
      setTimeout(() => {
        router.go(0);
      }, 500);
    }
  });
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.join-wrapper {
  &__back {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }
}

.button-validate {
  width: 100%;
}

ion-fab-button {
  padding: 8px;
  --background: #4178f5;
  --background-activated: #4178f5;
  --background-hover: #4178f5;
  --border-radius: 12px;
  --box-shadow: none;
  --color: #dee6f7;
}
</style>
