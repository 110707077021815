<template>
  <ion-page>
    <ion-content class="ion-padding my-profile-wrapper">
      <div class="my-profile-wrapper__back">
        <ion-back-button
          :text="$t('myProfile.myProfile')"
          color="neutral-80"
          defaultHref="/home"
        ></ion-back-button>
      </div>
      <section class="my-profile-wrapper__body">
        <ion-segment
          color="primary"
          :value="selected"
          mode="md"
          @ionChange="optionSelected($event)"
        >
          <ion-segment-button :value="PROFILE_VIEW.MY_PROFILE">
            <ion-label>{{ $t("myProfile.myData") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button :value="PROFILE_VIEW.MY_BUSINESS">
            <ion-label>{{ $t("myProfile.myTeams") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </section>
      <section>
        <div v-if="selected == PROFILE_VIEW.MY_PROFILE">
          <MyProfileOwnerComponent v-if="mode == 'edit'" />
          <MyProfileOwnerDetailComponent v-else />
        </div>
        <div v-if="selected == PROFILE_VIEW.MY_BUSINESS">
          <MyBusinessOwnerComponent />
        </div>
      </section>
      <section class="my-profile-wrapper__terms caption">
        <router-link to="/register/terms" class="link small margin-top-20"
          >{{ $t("register.conditions") }}
        </router-link>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonBackButton,
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { PROFILE_VIEW } from "@/utils/constants";
import MyProfileOwnerComponent from "@/components/shared/my_profile/MyProfileOwnerComponent.vue";
import MyBusinessOwnerComponent from "@/components/shared/my_profile/MyBusinessOwnerComponent.vue";
import { useRoute } from "vue-router";
import MyProfileOwnerDetailComponent from "@/components/shared/my_profile/MyProfileOwnerDetailComponent.vue";

const route = useRoute();

const selected = ref(PROFILE_VIEW.MY_PROFILE);

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? PROFILE_VIEW.MY_PROFILE;
};

const mode = ref<string>("edit");

onMounted(() => {
  const paramMode = route.query.mode?.toString() ?? null;
  if (paramMode == "detail") {
    mode.value = "detail";
  } else {
    mode.value = "edit";
  }
});
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.my-profile-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }

  &__terms {
    width: 90%;
    text-align: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    text-align: center;
    font: {
      size: 14px;
    };
  }
}

.button-validate {
  width: 100%;
}
</style>
