<template>
  <section class="phone-wrapper">
    <ion-select
      aria-label="Pais"
      interface="popover"
      style="width: 25%"
      value="+34"
      @ionChange="setPrefix"
      class="prefix-wrapper"
    >
      <ion-select-option value="+34">+34</ion-select-option>
      <ion-select-option value="+57">+57</ion-select-option>
    </ion-select>
    <ion-input
      :value="valueInput"
      style="width: 75%"
      :placeholder="placeholder"
      type="tel"
      @ionBlur="setValue"
      class="phone-number-wrapper"
    ></ion-input>
  </section>
</template>

<script setup lang="ts">
import { IonInput, IonSelect, IonSelectOption } from "@ionic/vue";
import { ref, computed } from "vue";

const props = defineProps({
  value: {
    type: String,
  },
  placeholder: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:phoneNumber"]);

const prefix = ref("+34");

const valueInput = computed(() => {
  return props.value?.split("|")[1];
});

const setValue = (e: any) => {
  const phone = prefix.value + "|" + e.target.value;
  emit("update:phoneNumber", phone);
};
const setPrefix = (e: any) => {
  prefix.value = e.target.value;
};
</script>

<style scoped lang="scss">
.prefix-wrapper {
  width: 56px;
  border: 0px solid black;
  border-radius: 5px;
  font: {
    size: 16px;
    weight: 400;
  }
  background-color: white;
  text-align: center;

  &:focus {
    outline: 1px solid white;
  }
}
.phone-number-wrapper {
  margin-left: 5px;
  border: 0px solid black;
  border-radius: 5px;
  font: {
    size: 16px;
  }
  background-color: rgb(243, 245, 250);
  text-align: center;
  color: #0a1223;

  &:focus {
    outline: 1px solid white;
  }
}
</style>
