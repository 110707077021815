<template>
  <ion-page>
    <ion-content class="ion-padding my-profile-wrapper">
      <div class="my-profile-wrapper__back">
        <ion-back-button
          :text="$t('myProfile.myProfile')"
          color="neutral-80"
          defaultHref="/join"
        ></ion-back-button>
      </div>
      <section>
        <MyProfileEmployeeComponent v-if="mode == 'edit'" />
        <MyProfileEmployeeDetailComponent v-else />
      </section>
      <section class="my-profile-wrapper__terms">
        <router-link to="/register/terms" class="link margin-top-20"
          >{{ $t("register.conditions") }}
        </router-link>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonBackButton,
  IonContent,
  IonPage,
  SegmentCustomEvent,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { PROFILE_VIEW } from "@/utils/constants";
import MyProfileEmployeeComponent from "@/components/shared/my_profile/MyProfileEmployeeComponent.vue";
import { useRoute } from "vue-router";
import MyProfileEmployeeDetailComponent from "@/components/shared/my_profile/MyProfileEmployeeDetailComponent.vue";

const route = useRoute();

const selected = ref(PROFILE_VIEW.MY_PROFILE);

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? PROFILE_VIEW.MY_PROFILE;
};

const mode = ref<string>("edit");

onMounted(() => {
  const paramMode = route.query.mode?.toString() ?? null;
  if (paramMode == "detail") {
    mode.value = "detail";
  } else {
    mode.value = "edit";
  }
});
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.my-profile-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }

  &__terms {
    text-align: center;
    align-items: center;
  }
}

.button-validate {
  width: 100%;
}
</style>
