<template>
  <div class="grid-container" :style="listModeStyle">
    <div v-if="listMode" >
      <div
        class="item-container"
        :style="listDirectionStyle"
        @click="showLocaleAudio"
      >
        <AvatarLocaleComponent
          :image-id="registerStore.location.image_id"
          :locale-id="registerStore.location.id ?? -1"
          :locale-name="registerStore.location.name ?? ''"
          class="photo-wrapper"
        />
        <div></div>
        <div
          :class="{ 'ion-padding-start': listMode }"
          class="ion-flex ion-align-items-start"
          style="flex-direction: column"
        >
          <span class="name" :style="listNameStyle">
            {{ registerStore.location.name }}
          </span>
          <span class="name" v-if="listMode">
            {{ $t("talkie.generalChannel") }}
          </span>
        </div>
        <em
          v-if="notificationsLocation > 0"
          class="item-container__notification"
          >{{ notificationsLocation }}</em
        >
      </div>
    </div>

    <div v-for="item of items" :key="item.id">
      <div
        v-if="listMode"
        class="item-container"
        :class="classItem(item)"
        :style="listDirectionStyle"
        @click="showPersonalAudio(item)"
      >
        <UserPhoto
          :list-mode="listMode"
          :item="item"
          :item-recording="itemRecording ?? 0"
        />
        <div
          :class="{ 'ion-padding-start': listMode }"
          class="ion-flex ion-align-items-start"
          style="flex-direction: column"
        >
          <span class="name" :style="listNameStyle">
            {{ item.name }}
          </span>
          <span class="name role-name" v-if="listMode">
            {{ $t(`global.${item.role}`) }}
          </span>
        </div>
        <em
          class="item-container__notification"
          v-if="notificationsUser(item.id) > 0"
        >
          {{ notificationsUser(item.id) }}
        </em>
      </div>
      <div
        v-else
        class="item-container"
        :class="classItem(item)"
        ref="longPress"
        @click="onTouchStart(item)"
        @contextmenu.prevent
        :style="listDirectionStyle"
      >
        <UserPhoto
          :list-mode="listMode"
          :item="item"
          :item-recording="itemRecording ?? 0"
          :show-recording="true"
        />
        <div
          :class="{ 'ion-padding-start': listMode }"
          class="ion-flex ion-align-items-start"
          style="flex-direction: column"
        >
          <span class="name" :style="listNameStyle">
            {{ item.name }}
          </span>
          <span class="name" v-if="listMode">
            {{ $t(`global.${item.role}`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Ref } from "vue";
import { computed, onMounted, ref, watch } from "vue";
import { getListUsers } from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import router from "@/router";
import useRecord from "@/composables/useRecord";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import useCore from "@/composables/useCore";
import { CoreStore, useCoreStore } from "@/stores/core";
import UserPhoto from "@/components/admin/talkie/UserPhoto.vue";

interface Props {
  listMode: boolean;
  search: string;
}

const props = defineProps<Props>();

const registerStore: RegisterStore = useRegisterStore();

interface TouchInfo {
  startTime: number;
  timer: any;
}

const items: Ref<any[]> = ref([]);

const itemRecording: Ref<number | null> = ref(null);
const touchInfo: TouchInfo = { startTime: 0, timer: null };
const { recording, nameUser, playing, toggleRecord } = useRecord();
const coreStore: CoreStore = useCoreStore();
const { 
  searchUserInput, 
  refreshUserLocation, 
  setRefreshNotification,
  refreshNotification } = useCore();
const { user, notifications, setNotifications } = useRegister();

const classItem = (item: any) => {
  const classes = [];
  if (item.id === itemRecording.value) {
    classes.push("recording");
  } else if (item.id !== itemRecording.value && itemRecording.value) {
    classes.push("no-recording");
  }

  if (item.status !== "active") {
    classes.push("inactive");
  }
  return classes.join(" ");
};

const onTouchStart = (item: any) => {
  itemRecording.value = item.id === itemRecording.value ? null : item.id;
  toggleRecord({
    nameUser: item.name,
    durationAudio: 2,
    mode: NOTIFICATION_TYPE.USER,
    userId: item.id,
  });
};

const listModeStyle = computed(() => {
  return !props.listMode
    ? "grid-template-columns: repeat(4, 1fr);"
    : "grid-template-columns: repeat(1, 1fr);";
});

const listDirectionStyle = computed(() => {
  return !props.listMode
    ? "flex-direction: column;justify-content: center;"
    : "flex-direction: row; justify-content: start;";
});

const listNameStyle = computed(() => {
  return !props.listMode
    ? "font-size: 12px; font-weight:400;"
    : "font-size: 16px; font-weight:600; color:#2E333D;";
});

const listAvatarStyle = computed(() => {
  return !props.listMode
    ? ""
    : "width: 55px !important;height: 55px !important;";
});

const showLocaleAudio = () => {
  router.push({ name: "default.talkie.locale.audio" });
};

const showPersonalAudio = (item: any) => {
  router.push({
    name: "default.talkie.personal.audio",
    params: { id: item.id },
  });
};

const notificationsLocation = computed(() => {
  const notification = notifications.value.find((notification) => {
    if ("location_id" in notification) {
      return notification;
    }
  });
  if (notification) {
    return notification["number notifications"] ?? 0;
  } else {
    return 0;
  }
});
const notificationsUser = (userId: number) => {
  const notification = notifications.value.find(
    (notification) => notification.user_id === userId
  );
  return notification ? notification["number notifications"] : 0;
};

onMounted(async () => {
  await setNotifications();
  items.value = await getListUsers(user.value.location_id);
});

watch(searchUserInput, async () => {
  items.value = await getListUsers(
    user.value.location_id,
    searchUserInput.value
  );
});

watch(refreshUserLocation, async () => {
  if (refreshUserLocation.value) {
    items.value = await getListUsers(
        user.value.location_id,
        searchUserInput.value
    );
    await setNotifications();
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
    coreStore.setRefreshTask(false);
    coreStore.setRefreshDashboardOwner(false);
    coreStore.setRefreshDashboard(false);
    router.go(0);
  }
});

watch(refreshNotification, async () => {
  await setNotifications();
  items.value = await getListUsers(user.value.location_id);
});
</script>
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-column-gap: 10px;
  max-height: 75vh;
  overflow: auto;
  padding-bottom: 100px;

  div {
    padding-top: 5px;

    .inactive {
      filter: grayscale(100%);
    }

    .no-recording {
      filter: opacity(50%);
    }

    .recording {
      filter: opacity(100%);

      ion-avatar {
        padding: 4px !important;

        width: 70px !important;
        height: 70px !important;
        border: 3px solid #cf2617 !important;

        .gradient {
          height: 90% !important;
          width: 90% !important;
          bottom: 4px !important;
          left: 4px !important;
          background: rgba(105, 6, 6, 0.508) !important;
        }

        .icon-recording {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            width: 45px;
          }
        }
      }
    }

    .item-container {
      padding-top: 5px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      position: relative;

      &__notification {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #1c4197;
        position: absolute;
        top: 20px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 10px;
        font-style: normal;
      }

      .name {
        pointer-events: none;

        font-size: 12px;
        color: #5e6573;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      ion-avatar {
        width: 64px;
        height: 64px;
        padding: 2px;
        border-radius: 50%;
        border: 3px solid #64cde1;
        position: relative;
        display: inline-block;
        overflow: hidden;

        .gradient {
          position: absolute;
          bottom: 2px;
          left: 2px;
          width: 94%;
          height: 100%;
          border-radius: 50%;
          background: linear-gradient(
            to bottom,
            transparent 20%,
            rgba(0, 0, 0, 0.8) 100%
          );
        }

        .icon {
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate(-50%);

          svg {
            width: 15px;
          }
        }
      }
    }
  }
}

.photo-wrapper {
  width: 55px !important;
  height: 55px !important;
}
</style>
