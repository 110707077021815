<template>
  <ion-page>
    <WrapperPageComponent v-if="dashboardMember">
      <ion-content class="ion-padding">
        <section class="dashboard-wrapper">
          <ion-slides
            :pager="true"
            :options="slideOpts"
            v-if="advices || tasks"
          >
            <ion-slide v-for="advice in advices" :key="advice">
              <section class="notice-wrapper">
                <div class="notice-wrapper__icon">
                  <VsxIcon
                    size="20"
                    class="card-business-wrapper__icon"
                    iconName="Notification"
                    type="linear"
                  />
                </div>
                <div class="notice-wrapper__text">
                  <p>{{ advice.name }}</p>
                </div>
              </section>
            </ion-slide>
            <ion-slide v-for="task in tasks" :key="task">
              <section class="notice-wrapper">
                <div class="notice-wrapper__icon">
                  <VsxIcon
                    size="20"
                    class="card-business-wrapper__icon"
                    iconName="NoteText"
                    type="linear"
                  />
                </div>
                <div class="notice-wrapper__text">
                  <p>{{ task.title }}</p>
                </div>
              </section>
            </ion-slide>
          </ion-slides>
          <ion-row class="margin-top-20">
            <ion-col size="6">
              <CardBusinessInfo
                icon="Shop"
                :value="dashboardMember.number_locations"
                :text="$t('global.yourTeams')"
                color-white
                center
                @click.prevent="handleGetRoute('joinRequestList.accepted')"
              />
            </ion-col>
            <ion-col size="6">
              <CardBusinessInfo
                icon="Microphone2"
                :value="dashboardMember.number_message"
                :text="$t('global.unReadMessages')"
                color-white
                center
                @click.prevent="handleGetRoute('default.talkie.list')"
              />
            </ion-col>
            <ion-col size="6">
              <CardBusinessInfo
                icon="Send2"
                :value="dashboardMember.number_invitations"
                :text="$t('global.yourInvitations')"
                color-white
                center
                @click.prevent="handleGetRoute('joinRequestList.waiting')"
              />
            </ion-col>
            <ion-col size="6">
              <CardBusinessInfo
                icon="Note"
                :value="`${dashboardMember.number_tasks.pending}/${dashboardMember.number_tasks.total}`"
                :text="$t('global.pendingTask')"
                color-white
                center
                @click.prevent="handleGetRoute('default.tasks.list')"
              />
            </ion-col>
          </ion-row>
        </section>
        <section>
          <ion-button
            class="join-wrapper__button"
            fill="clear"
            expand="block"
            style="width: 100%"
            router-link="/join/new"
          >
            {{ $t("actions.joinAnotherTeam") }}
          </ion-button>
        </section>
      </ion-content>
    </WrapperPageComponent>
  </ion-page>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { VsxIcon } from "vue-iconsax";
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/vue";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
import { onMounted, ref, watch } from "vue";
import { handlerDashboardMember } from "@/services/dashboard";
import { IDashboardMember } from "@/interfaces/dashboard/IDashboardMember";
import { MODEL } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { IAdvice } from "@/interfaces/advice/IAdvice";
import { Task } from "@/interfaces/tasks/ITasks";
import { addOutline } from "ionicons/icons";
import router from "@/router";
import { APPLICATION_STATUS } from "@/utils/constants";
import useCore from "@/composables/useCore";

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const registerStore: RegisterStore = useRegisterStore();

const dashboardMember = ref<IDashboardMember>();
const advices = ref<IAdvice[]>();
const tasks = ref<Task[]>();

const { setJoinRequestTabSelected, setRefreshDashboard, refreshDashboard } =
  useCore();

///Get Advices
const getAdvices = async () => {
  try {
    if (registerStore.location.id) {
      const payload = {
        model: MODEL.ADVICE,
        fields: ["id", "name", "date_start", "date_end"],
      };

      handleSearchReadModel(payload, [
        { field: "location_id", op: "==", value: registerStore.location.id },
      ]).then((data) => {
        advices.value = data.items as IAdvice[];
      });
    } else {
      advices.value = [];
    }
  } catch (err) {
    advices.value = [];
    console.error(err.message);
  }
};

watch(refreshDashboard, async () => {
  try {
    const data: IDashboardMember = await handlerDashboardMember();
    dashboardMember.value = data;
    if (dashboardMember.value.number_locations == 0) {
      await router.push({ name: "joinHome" });
    }
  } catch (e) {
    console.error(e);
  }
});

onMounted(async () => {
  try {
    const data: IDashboardMember = await handlerDashboardMember();
    dashboardMember.value = data;
    if (dashboardMember.value.number_locations == 0) {
      await router.push({ name: "joinHome" });
    } else {
      await getAdvices();
    }
  } catch (e) {
    console.error(e);
  }
});

const handleGetRoute = (to: any) => {
  if (to === "joinRequestList.waiting") {
    setJoinRequestTabSelected(APPLICATION_STATUS.WAITING);
    router.push({ name: "joinRequestList" });
  }
  if (to === "joinRequestList.accepted") {
    setJoinRequestTabSelected(APPLICATION_STATUS.ACCEPTED);
    router.push({ name: "joinRequestList" });
  }
  if (to === "default.talkie.list") {
    router.push({ name: to });
  }
  if (to === "default.tasks.list") {
    router.push({ name: to });
  }
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.notice-wrapper {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    background: #f9e8e7;
    width: 30px;
    height: 30px;
    color: #cf2617;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-left: 10px;
    width: 80%;

    p {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: 100%;
  }
}

ion-slide {
  align-items: stretch;
}
</style>
