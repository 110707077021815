<template>
  <div>
    <ion-item
      fill="solid"
      class="input"
      v-if="
        !['pin', 'checkbox', 'autocomplete', 'tel', 'toggle', 'date'].includes(
          inputType
        )
      "
    >
      <VsxIcon
        :iconName="icon"
        type="linear"
        slot="start"
        v-if="icon"
        :size="24"
        :color="iconColor"
        class="ion-margin-end"
      />
      <template
        v-if="
          ['text', 'password', 'email', 'number', 'float', 'pin'].includes(
            inputType
          )
        "
      >
        <ion-input
          :id="dynamicId"
          class="input"
          :placeholder="placeholder ?? ''"
          :type="showType(type)"
          :value="inputValue"
          @ion-input="handleClickChange"
          @ion-change="handleClickBlur"
          step="0.01"
          @ion-blur="handleBlur"
          :clear-input="clearField"
        ></ion-input>
      </template>
      <template v-if="['textarea'].includes(inputType)">
        <ion-textarea
          :id="dynamicId"
          :rows="2"
          class="input"
          :placeholder="placeholder ?? ''"
          :value="inputValue"
          @ion-input="handleClickChange"
          @ion-change="handleClickBlur"
          @ion-blur="handleBlur"
        ></ion-textarea>
      </template>
      <template v-if="inputType === 'select'">
        <ion-select
          :id="dynamicId"
          :placeholder="label"
          :value="inputValue"
          @ion-change="handleClickChange"
          @ion-blur="handleClickBlur"
        >
          <ion-select-option
            :value="item"
            v-for="(item, index) of options"
            :key="index"
            >{{ item }}
          </ion-select-option>
        </ion-select>
      </template>

      <ion-note
        slot="helper"
        color="danger-80"
        v-if="errorMessage || meta.valid"
      >
        {{ errorMessage || successMessage }}
      </ion-note>
    </ion-item>
    <ion-item v-if="inputType === 'checkbox'">
      <ion-checkbox
        :id="dynamicId"
        :checked="inputValue"
        :value="inputValue"
        @ion-change="handleClickChange"
        @ion-blur="handleClickBlur"
        slot="start"
      ></ion-checkbox>
      <slot name="checkbox">
        <ion-label class="ion-text-wrap">{{ label }}</ion-label>
      </slot>
      <ion-note
        slot="helper"
        color="danger-80"
        v-if="errorMessage || meta.valid"
      >
        {{ errorMessage || successMessage }}
      </ion-note>
    </ion-item>
    <ion-item v-if="inputType === 'toggle'" class="ion-toggle-item">
      <ion-toggle
        :id="dynamicId"
        :checked="inputValue"
        :value="inputValue"
        @ion-change="handleClickChange"
        @ion-blur="handleClickBlur"
      >
      </ion-toggle>
      <ion-label class="ion-margin-start">{{ label }}</ion-label>
    </ion-item>
    <PinCode
      :digit-count="5"
      @update:otp="handleChange"
      v-if="inputType === 'pin'"
    ></PinCode>
    <PhoneNumberComponent
      :value="inputValue"
      :placeholder="placeholder ?? ''"
      v-if="inputType == 'tel'"
      @update:phoneNumber="handleChange"
    />
    <template v-if="inputType === 'autocomplete'">
      <SelectAutocompleteComponent
        :placeholder="placeholder"
        :value="inputValue"
        @update:modelValue="handleClickChange"
        @select="handleClickSelect"
        item-title="name"
        item-value="id"
        :field-search="fieldSearch"
        :model="modelName"
        :model-value="inputValue"
        :filters="filters"
        :icon="icon"
      />
    </template>
    <template v-if="inputType === 'date'">
      <VueDatePicker
        :model-value="inputValue"
        @update:model-value="handleClickChange"
        @cleared="handleClickChange"
        auto-apply
        :placeholder="placeholder"
        model-type="yyyy-MM-dd"
        locale="es"
        :enable-time-picker="false"
      />
      <ion-note
        slot="helper"
        color="danger-80"
        class="caption"
        style="padding-left: 20px"
        v-if="errorMessage || meta.valid"
      >
        {{ errorMessage || successMessage }}
      </ion-note>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, toRef } from "vue";
import { VsxIcon } from "vue-iconsax";
import { useField } from "vee-validate";
import { TextFieldTypes } from "@/interfaces/GenericModel";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import dayjsTimezone from "dayjs-timezone-iana-plugin";
import { Capacitor } from "@capacitor/core";
import PinCode from "@/components/shared/PinCode.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import SelectAutocompleteComponent from "@/components/shared/form/SelectAutocompleteComponent.vue";

import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonTextarea,
} from "@ionic/vue";
import PhoneNumberComponent from "@/components/shared/PhoneNumberComponent.vue";

dayjs.extend(dayjsPluginUTC);
dayjs.extend(dayjsTimezone);

interface Props {
  inputType: string;
  type?: TextFieldTypes;
  value?: string;
  options?: string[];
  name: string;
  label: string;
  icon?: string;
  iconColor?: string;
  successMessage?: string;
  placeholder?: string;
  modelName: string;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  filters?: any[];
  fieldSearch?: string;
  clearField?: true;
}

const props = withDefaults(defineProps<Props>(), {
  type: "text",
  minDate: dayjs().subtract(80, "year").format("YYYY-MM-DDTHH:mm:ss"),
  maxDate: dayjs().add(80, "year").format("YYYY-MM-DDTHH:mm:ss"),
  iconColor: "#787F8C",
  clearField: true,
});
const emit = defineEmits(["update"]);
const nameRef = toRef(props, "name");

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField<any>(nameRef, undefined, {
  initialValue: props.value,
});

const handleClickSelect = (value: any) => {
  emit("update", {
    model: props?.modelName,
    field: props.name,
    value: value[props.fieldSearch || "id"],
  });
};

const handleClickChange = (e: any) => {
  handleChange(e);
  if (props.inputType === "tel") {
    inputValue.value = e.prefix + "|" + e.phone;
  }
  if (props.inputType === "checkbox") {
    inputValue.value = e.detail.checked;
  }
  if (props.inputType === "number") {
    inputValue.value = e.target.value ? parseInt(e.target.value) : "";
  }
  if (props.inputType === "float") {
    inputValue.value = e.target.value ? parseFloat(e.target.value) : "";
  }
  if (props.inputType === "date") {
    if (e) {
      const date = dayjs(e).tz("Europe/Madrid").format("YYYY-MM-DD");
      inputValue.value = date;
    } else {
      inputValue.value = null;
    }
  }
  if (props.inputType === "toggle") {
    inputValue.value =
      e.target.value == "on" || e.target.value == "false" ? true : false;
  }
};
const handleClickBlur = (e: any) => {
  handleBlur(e);
};

const showType = (type: any) => {
  if (type === "textarea") {
    return "text";
  }
  if (type === "float") {
    return Capacitor.isNativePlatform() ? "number" : "text";
  }
  return type;
};

const dynamicId = computed(() => {
  return `input-${props.modelName}-${props.name}`;
});
</script>
<style scoped lang="scss">
ion-input.input {
  --color: #464c59;
  --placeholder-color: #464c59;
  --placeholder-opacity: 0.8;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
}

.item-fill-solid {
  --background: #f3f5fa !important;
  --background-hover: #f3f5fa !important;
}

ion-item {
  --border-radius: 8px 8px 0px 0px;
  --highlight-height: 2px;
}

ion-select {
  width: 100%;
}

ion-select::part(placeholder) {
  opacity: 1;
}

ion-datetime {
  color: black;
}

ion-checkbox {
  --size: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
}
.phone-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ion-toggle-item {
  --inner-border-width: 0px;
}
</style>
