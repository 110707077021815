<template>
  <div class="ion-flex ion-align-items-center" v-if="location">
    <div class="photo-wrapper">
      <AvatarLocaleComponent :locale-id="locationId" :image-id="imageId" :locale-name="locationName ?? ''" />
    </div>
    <div class="ion-padding-start">
      <ion-label>
        {{ location['name'] }}
        <p>
          {{ location['address'] }}
        </p>
      </ion-label>
    </div>
  </div>
</template>
<script setup lang="ts">
import {IonLabel} from "@ionic/vue";
import {computed, onMounted, Ref, ref} from "vue";
import {ILocation} from "@/interfaces/locale/ILocation";
import {MODEL} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";

interface Props {
  item: any;
}
const props = defineProps<Props>();

const location: Ref<ILocation | undefined> = ref<ILocation>()

const locationId = computed(() => location.value? location.value.id : undefined);
const imageId = computed(() => location.value? location.value?.image_id : undefined);
const locationName = computed(() => location.value? location.value?.name : undefined);

onMounted(() => {
    try {
        const payload = {
            model: MODEL.LOCATION,
            fields: ['id', 'name', 'address', 'country_id', 'phone_number', 'email', 'local_type', 'code', 'entity_id', 'image_id'],
        };
        const filter = [
            {
                field: 'code',
                op: "==",
                value: props.item.location_code,
            },
        ];
        handleSearchReadModel(payload, filter).then(async (data) => {
            location.value = data.items[0] as ILocation;
        })
    } catch (err) {
        console.error(err);
    }
})
</script>
<style scoped lang="scss">
.photo-wrapper {
  border-radius: 50%;
  background: #DEE6F7;
  color: #4178F5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

