<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <ion-content>
      <ion-row :class="isPlatformIOS ? 'safe-area' : ''">
        <ion-col
          :size="enableCol && !isMobile ? '8' : '12'"
          :class="{ 'ion-no-padding': isMobile }"
        >
          <ion-row>
            <template v-if="!isMobile">
              <ion-col
                size="6"
                class="
                  ion-flex ion-justify-content-start ion-padding-horizontal
                "
              >
                <h3>{{ $t("locale.localeManager") }}</h3>
              </ion-col>
              <ion-col size="6" class="ion-flex ion-justify-content-end">
                <h3>
                  <ion-button
                    color="primary"
                    mode="ios"
                    @click="openCreate"
                    v-if="canOwnerAdmin"
                  >
                    <VsxIcon iconName="Add" />
                    <span>Añadir</span>
                  </ion-button>
                </h3>
              </ion-col>
              <ion-col size="4">
                <ion-searchbar @ion-input="filterList"></ion-searchbar>
              </ion-col>
            </template>
            <template v-else>
              <ion-col size="12">
                <HeaderNavigationComponent
                  :title="$t('locale.localeManager')"
                />
              </ion-col>
            </template>

            <ion-col
              size="12"
              :class="{ 'ion-padding-top ion-no-padding': isMobile }"
            >
              <ListWrapper
                :model="model.name"
                :headers="headers"
                :hide-headers="hideHeaders"
              >
                <template #actions_desktop="props">
                  <div class="container" ref="menu">
                    <DropdownMenuComponent
                      :item="props"
                      @click:edit="openEdit"
                      @click:delete="openModalDelete"
                      @click:copy="copyCode"
                      @click:detail="openDetail"
                    />
                  </div>
                </template>
                <template #item_mobile="props">
                  <div
                    class="
                      ion-flex
                      ion-justify-content-between
                      ion-align-items-center
                      ion-padding
                      item__select
                    "
                  >
                    <ItemMobileComponent :item="props" />
                    <DropdownMenuComponent
                      :item="props"
                      @click:edit="openEdit"
                      @click:copy="copyCode"
                      @click:share="shareCode"
                      @click:delete="openModalDelete"
                      @click:detail="openDetail"
                    />
                  </div>
                </template>
              </ListWrapper>
            </ion-col>
          </ion-row>
        </ion-col>
        <Transition name="fade">
          <ion-col
            :class="isPlatformIOS ? 'safe-area' : ''"
            size="4"
            v-if="showDrawerForm && !isMobile"
            class="ion-no-padding"
          >
            <DrawerComponent
              v-model="showDrawerForm"
              :title="drawerTitle"
              :class="isPlatformIOS ? 'safe-area' : ''"
            >
              <p class="text-body-s">
                {{ $t("locale.localeDescriptionAdd") }}
              </p>
              <div class="photo-wrapper">
                <PhotoLocalComponent style="z-index: 9999" />
              </div>
              <FormComponent
                no-cancel-button
                :model="model"
                :form-mode="formModeTitle"
                :id-model="itemSelected?.id"
                :schema="schema"
                @success="savedLocation"
              >
                <template #submitButton>
                  <div class="ion-flex ion-justify-content-start">
                    <ion-button mode="ios" type="submit">
                      {{ buttonTitle }}
                    </ion-button>
                  </div>
                </template>
              </FormComponent>
            </DrawerComponent>
          </ion-col>
        </Transition>
        <Transition name="fade">
          <ion-col
            size="4"
            v-if="showDrawerDetail && !isMobile"
            class="ion-no-padding"
          >
            <DrawerComponent
              v-model="showDrawerDetail"
              :title="itemSelected.name"
            >
              <DrawerDetailComponent :item="itemSelected" />
            </DrawerComponent>
          </ion-col>
        </Transition>
      </ion-row>

      <ion-fab slot="fixed" vertical="bottom" horizontal="end" v-if="isMobile" class="wehop-class">
        <div ref="step2_2">
          <ion-fab-button @click="openCreate">
            <VsxIcon iconName="Add"></VsxIcon>
          </ion-fab-button>
        </div>
      </ion-fab>
    </ion-content>
    <ModalDeleteComponent
      v-model="showModalDelete"
      @confirm:delete="handleClickDeleteModel"
      :name="itemSelected?.name"
    />
    <ModalComponent
      noIconClose
      v-if="showDrawerForm && isMobile"
      v-model="showDrawerForm"
    >
      <HeaderNavigationComponent
        preventDefault
        @click:back="showDrawerForm = false"
        :title="drawerTitle"
      />
      <p class="text__description" v-if="!formMode">
        Indica, a continuación, los datos correspondientes para poder crear tu
        equipo
      </p>
      <div class="photo-wrapper">
        <PhotoLocalComponent />
      </div>
      <div class="ion-padding-top">
        <FormComponent
          no-cancel-button
          :model="model"
          :form-mode="formModeTitle"
          :id-model="itemSelected?.id"
          :schema="schema"
          :hide-headers="hideHeaders"
          @success="savedLocation"
        >
          <template #submitButton>
            <ion-button mode="ios" expand="block" type="submit">
              {{ buttonTitle }}
            </ion-button>
          </template>
        </FormComponent>
      </div>
    </ModalComponent>
    <ModalComponent
      noIconClose
      v-if="showDrawerDetail && isMobile"
      v-model="showDrawerDetail"
    >
      <HeaderNavigationComponent
        preventDefault
        @click:back="showDrawerDetail = false"
        :title="itemSelected.name"
        :class="isPlatformIOS ? 'safe-area' : ''"
      />
      <div class="ion-padding-top">
        <DrawerDetailComponent :item="itemSelected" />
      </div>
    </ModalComponent>
  </ion-page>
</template>
<script lang="ts" setup>
import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { computed, onMounted, ref, watch } from "vue";
import { mapModel, mapSchema } from "@/utils/getMapModel";
import {
  handleDeleteModel,
  handleGetDescribeModel,
  handleReadModel,
  handlePostModel,
  handlePutModel,
} from "@/services/generic";
import { useList } from "@/composables/useList";
import { useGenericStore } from "@/stores/generic";
import {
  actionSheetController,
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonPage,
  IonRow,
  IonSearchbar,
  toastController,
} from "@ionic/vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import DrawerComponent from "@/components/shared/drawer/DrawerComponent.vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import DropdownMenuComponent from "@/components/admin/location/DropdownMenuComponent.vue";
import ItemMobileComponent from "@/components/admin/location/ItemMobileComponent.vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import ModalDeleteComponent from "@/components/admin/location/ModalDeleteComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import DrawerDetailComponent from "@/components/admin/location/DrawerDetailComponent.vue";
import { useRole } from "@/composables/useRole";
import { MODEL, ROLE_ID } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import PhotoLocalComponent from "@/components/shared/PhotoLocalComponent.vue";
import i18n from "@/plugins/i18n";
import { CoreStore, useCoreStore } from "@/stores/core";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";
import { useRoute } from "vue-router";
import { useShepherd } from "vue-shepherd";
import { TourStore, useTourStore } from "@/stores/tour";
import useCore from "@/composables/useCore";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import router from "@/router";
import { getSubscriptionAvailability, handleSubscriptionLocationAllow } from "@/services/subscription";

const headers = [
  {
    label: "Nombre",
    name: "name",
  },
  {
    label: "Dirección",
    name: "address",
  },
  {
    label: "Teléfono",
    name: "phone_number",
  },
  {
    label: "Sector Empresarial",
    name: "local_type",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "City",
    name: "city_id",
  },
  {
    label: "Country",
    name: "country_id",
  },
  {
    label: "Image",
    name: "image_id",
  },
  {
    label: "Code",
    name: "code",
    hide: true,
  },
  {
    label: "",
    name: "actions",
  },
];
const hideHeaders = ["code"];

const showDrawerForm: Ref<boolean> = ref(false);
const showDrawerDetail: Ref<boolean> = ref(false);
const enableCol: Ref<boolean> = ref(false);
const addLocationResult: Ref<IActionShareResult | undefined> = ref();
const showModalDelete: Ref<boolean> = ref(false);
const itemSelected: Ref<any> = ref(null);
const { getModelData } = useList();
const schema: Ref<any> = ref(null);
const storeList = useGenericStore();
const { isMobile } = useNativePlatform();
const { canOwnerAdmin } = useRole();
const { user } = useRegister();
const coreStore: CoreStore = useCoreStore();
const { refreshLocation, setRefreshDashboard } = useCore();
const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();
const { sendInvitationLink } = useRegister();
const route = useRoute();
const { isPlatformIOS } = usePlatform();
const { handleGetMe } = useRegister();
const { notMoreLocations, setNotMoreLocation } = useCore();

const fieldQuery =
  "fields=name,country_id,city_id,address,phone_number,email,local_type,code";
const model: Ref<any> = ref({
  name: "location",
  fields: [],
});
storeList.setSortByModel(model.value.name, [
    {
      field: "created_datetime"
    },
]);


const step2_2 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth" },
  },
});

const handleGetModel = async () => {
  const describe = await handleGetDescribeModel(model.value.name, fieldQuery);
  const modelMap = mapModel(describe);
  const schemaMap = mapSchema(describe);
  schema.value = schemaMap;
  model.value.fields = modelMap;
};

const handleClickDeleteModel = async () => {
  try {
    await handleDeleteModel({
      model: model.value.name,
      id: itemSelected.value.id,
    });
    getModel({ reset: true });
    const toast = await toastController.create({
      message: "Local borrado",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDelete.value = false;
  } catch (error) {
    console.log(error);
  }
};

const openModalDelete = (item: any) => {
  itemSelected.value = item;
  showModalDelete.value = true;
};

const openCreate = async () => {
  if (await handleSubscriptionLocationAllow()) {
    itemSelected.value = null;
    showDrawerForm.value = true;
    coreStore.setNewLocal(true);
    coreStore.setUpdateUserPlan(false);
  } else {
    coreStore.setUpdateLocationPlan(true);
    await showActionSheet();
  }
};

const openDetail = async (item: any) => {
  itemSelected.value = await handleReadModel({
    model: MODEL.LOCATION,
    id: item.id,
  });
  showDrawerDetail.value = true;
  coreStore.setSelectedLocale(item);
};

const copyCode = async (item: any) => {
  await Clipboard.write({
    string: item.code,
  });
  const toast = await toastController.create({
    message: i18n.global.t("global.codeCopied"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  coreStore.setSelectedLocale(null);
  await toast.present();
};

const shareCode = async (item: any) => {
  await Share.share({
    title: i18n.global.t("global.shareCodeTitle"),
    text: i18n.global.t("global.shareCodeDescription", {
      platform: isPlatformIOS ? "iOS" : "Android",
      name: registerStore.user.name,
      code: item.code ?? "",
    }),
    dialogTitle: i18n.global.t("actions.share"),
  });
  coreStore.setSelectedLocale(null);
  await sendInvitationLink(registerStore.user.phone_number, item.code!);
};

const openEdit = (item: any) => {
  itemSelected.value = item;
  showDrawerForm.value = true;
  coreStore.setSelectedLocale(item);
};

const formMode = computed(() => {
  return showDrawerForm.value && itemSelected.value;
});

const formModeTitle = computed(() => {
  return formMode.value ? "update" : "create";
});

const drawerTitle = computed(() => {
  return formMode.value
    ? i18n.global.t("locale.editLocale")
    : i18n.global.t("locale.newLocale");
});

const buttonTitle = computed(() => {
  return formMode.value
    ? "Guardar cambios"
    : i18n.global.t("locale.createLocale");
});

const getModel = (payload?: any) => {
  getModelData({
    headers: headers,
    modelName: model.value.name,
    reset: payload?.reset,
  });
};

const savedLocation = async (values: any) => {
  let message;
  if (showDrawerForm.value && itemSelected.value) {
    message = i18n.global.t("global.changesSaved");
  } else {
    message = i18n.global.t("locale.localeCreated");
    coreStore.toggleLoading(true);
    try {
      await handlePostModel({
        model: MODEL.USERLOCATION,
        fields: {
          location_id: values.id,
          user_id: user.value.id,
          role_id: ROLE_ID.OWNER,
          shift_days: "Turno: Lunes, Miércoles, Viernes ",
          workdays: "Horario de Lunes a Viernes de 09:00 a 16:00",
        },
      }).then(async () => {

        //Get Amount Locals
        await getSubscriptionAvailability();

        handlePutModel({
          model: MODEL.USER,
          id: user.value.id,
          fields: {
            location_id: values.id,
            is_owner: false
          },
        }).then(() => {
          coreStore.setRefreshLocation(true);
          setRefreshDashboard(true);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      coreStore.toggleLoading(false);
    }
  }

  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  showDrawerForm.value = false;
  getModel({ reset: true });
};

const filterList = (evt: any) => {
  storeList.setFiltersModel(model.value.name, [
    {
      field: "name",
      op: "ilike",
      value: evt.target.value,
    },
  ]);
  getModel();
};

watch(showDrawerDetail, () => {
  if (showDrawerDetail.value) {
    enableCol.value = showDrawerDetail.value;
  } else {
    setTimeout(() => {
      enableCol.value = showDrawerDetail.value;
    }, 200);
  }
});

watch(showDrawerForm, () => {
  if (showDrawerForm.value) {
    enableCol.value = showDrawerForm.value;
  } else {
    setTimeout(() => {
      enableCol.value = showDrawerForm.value;
    }, 200);
  }
});

watch(refreshLocation, () => {
  if (refreshLocation.value) {
    storeList.setItemsModel(model.value.name, []);
    getModel();
  }
});

const showActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: i18n.global.t("global.notMoreLocations"),
    subHeader: i18n.global.t("global.notMoreLocationsDescription"),
    cssClass: "wehop-class",
    buttons: [
      {
        text: i18n.global.t("global.addLocations"),
        data: {
          action: "add",
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        cssClass: 'wehop-class__cancel_button',
        data: {
          action: "cancel",
        },
      },
    ],
  });

  await actionSheet.present();

  const res = await actionSheet.onDidDismiss();
  addLocationResult.value = res as IActionShareResult;
  if (addLocationResult.value.data.action === "add") {
    showDrawerForm.value = false;
    await router.replace({ name: "registerPlan" });
  }
  setNotMoreLocation(false);
};

watch(notMoreLocations, async () => {
  if (notMoreLocations.value) {
    await showActionSheet();
  }
});

onMounted(() => {
  const newLocal = route.params.new_local?.toString() ?? null;
  if (newLocal == "1") {
    openCreate();
  }
  if (!user.value.is_teams_body) {
    tour.addStep({
      attachTo: { element: step2_2.value, on: "bottom-start" },
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTeamTour({ show: true, step: 3 });
            coreStore.setShowDropdown(false);
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_teams_body: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour2_2.title"),
      text: i18n.global.t("tour.tour2_2.text"),
    });
    tour.start();
  }
});

handleGetModel();
</script>
<style scoped lang="scss">
@import "../../../theme/mixins_responsive";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(240px);
  opacity: 0;
}

td > .ion-container {
  position: relative;
}

ion-item {
  position: relative;
}

.div-item {
  width: 100%;
}

.item__select {
  border-bottom: 1px solid #c7ccd680;
}

.text__description {
  padding: 10px;
  color: var(--ion-color-neutral-60);
  font-size: 14px;
}

.photo-wrapper {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.safe-area {
  padding-top: 45px;
}

.wehop-class .action-sheet-group {
  background: white !important;
}

</style>
