<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <div class="header-task ion-padding">
      <HeaderNavigationComponent
        @click:back="emit('click:back')"
        preventDefault
        title=""
      />
      <DropdownMenuTaskComponent
        v-if="canOptionsMenu"
        text-edit="tasks.edit"
        text-delete="tasks.delete"
        @click:delete="showModalDeleteTask = true"
        @click:edit="showModalUpdateTask = true"
      />
    </div>
    <ion-content class="content-task ion-padding">
      <ion-chip v-if="task.is_priority">
        <ion-avatar>
          <VsxIcon iconName="Flash"></VsxIcon>
        </ion-avatar>
        <ion-label>{{ $t('tasks.priorityTask') }}</ion-label>
      </ion-chip>
      <h3>
        {{ task.title }}
      </h3>
      <p class="description-task">
        {{ task.description }}
      </p>
      <div class="avatar-container-task">
        <AvatarDescriptionComponent
          v-if="task.assigned_user_id"
          :user-id="task.assigned_user_id"
          :label="$t('tasks.assigned_to')"
        ></AvatarDescriptionComponent>
        <AvatarDescriptionComponent
          v-if="task.created_user_id"
          :user-id="task.created_user_id"
          :label="$t('tasks.created_by')"
        ></AvatarDescriptionComponent>
      </div>
      <div class="avatar-container-task">
        <AvatarDescriptionComponent
            v-if="task.finalized_user_id"
            :user-id="task.finalized_user_id"
            :label="$t('tasks.finalized_by')"
        ></AvatarDescriptionComponent>
      </div>
      <template v-if="canOptionsMenu">
        <ion-button
          v-if="task.status === ENUM_TASK.pending"
          fill="outline"
          expand="block"
          class="button-finish ion-margin-top"
          @click="finishTask"
        >
          <VsxIcon iconName="TickCircle"></VsxIcon>
          <span class="ion-padding-start">
            {{ $t("tasks.finish") }}
          </span>
        </ion-button>
        <ion-button
          v-else
          fill="outline"
          expand="block"
          class="button-finish ion-margin-top"
        >
          <VsxIcon iconName="TickCircle"></VsxIcon>
          <span class="ion-padding-start">
            {{ $t("tasks.finished_task") }}
          </span>
        </ion-button>
      </template>
      <div class="ion-margin-vertical">
        <CommentTask :task-id="task?.id" :task-status="task.status" />
      </div>
    </ion-content>
    <ModalDeleteComponent
      @confirm:delete="deleteTask"
      v-model="showModalDeleteTask"
      :nameItem="task.title"
    ></ModalDeleteComponent>
    <ModalComponent noIconClose v-model="showModalUpdateTask">
      <HeaderNavigationComponent
        preventDefault
        @click:back="showModalUpdateTask = false"
        :title="'Editar Tarea'"
        :class="isPlatformIOS ? 'safe-area' : ''"
      />

      <div class="ion-padding-top">
        <FormComponent
          v-if="showModalUpdateTask"
          no-cancel-button
          :model="model"
          form-mode="update"
          :id-model="task.id"
          :schema="schema"
          @success="updateTask"
        >
          <template #submitButton>
            <ion-button mode="ios" expand="block" type="submit">
              Guardar
            </ion-button>
          </template>
        </FormComponent>
      </div>
    </ModalComponent>
  </ion-page>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import HeaderNavigationComponent from "@/components/admin/location/HeaderNavigationComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import ModalDeleteComponent from "@/components/shared/modal/ModalSimpleDeleteComponent.vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import { Task } from "@/interfaces/tasks/ITasks";
import AvatarDescriptionComponent from "./AvatarDescriptionComponent.vue";
import { handleDeleteModel, handlePutModel } from "@/services/generic";
import { MODEL, ENUM_TASK, ROLES } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { schemanewTask } from "@/utils/getMapModel";
import { useRegister } from "@/composables/useRegister";
import CommentTask from "./CommentTask.vue";

import {
  IonPage,
  IonChip,
  IonContent,
  IonLabel,
  IonAvatar,
  IonButton,
  toastController,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";

interface Props {
  task: Task;
}

const schema: Ref<any> = ref(schemanewTask);
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();
const showModalDeleteTask: Ref<boolean> = ref(false);
const showModalUpdateTask: Ref<boolean> = ref(false);
const props = defineProps<Props>();
const emit = defineEmits(["click:back", "update:tasks"]);

const model: Ref<any> = ref({
  name: MODEL.TASK,
  fields: [
    {
      name: "title",
      label: "Nombre de la tarea",
      type: "textarea",
    },
    {
      name: "description",
      label: "Escribe una descripción",
      type: "textarea",
    },
    {
      name: "assigned_user_id",
      label: "Asignar a (Opcional)",
      type: "autocomplete",
      model: "user",
      filters: [
        {
          model: "userlocation",
          foreign_key: "user_id",
          field: "location_id",
          value: user.value.location_id,
          op: "==",
        },
      ],
    },
    {
      name: "is_priority",
      label: "Tarea prioritaria",
      type: "toggle",
    },
    {
      name: "location_id",
      label: "location",
      type: "text",
      default: user.value.location_id,
      display: false,
    },
    {
      name: "date_start",
      label: "Fecha de inicio",
      type: "date",
      default: new Date(),
      display: false,
    },
  ],
});

const finishTask = async () => {
  try {
    const payload = {
      model: MODEL.TASK,
      id: props.task.id,
      fields: {
        status: ENUM_TASK.finished,
      },
    };
    await handlePutModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("tasks.success_finished"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    emit("update:tasks");
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const deleteTask = async () => {
  try {
    const payload = {
      model: MODEL.TASK,
      id: props.task.id,
    };
    await handleDeleteModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("tasks.success_removed"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDeleteTask.value = false;
    setTimeout(() => {
      emit("update:tasks");
    }, 0);
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const updateTask = async () => {
  showModalUpdateTask.value = false;
  const toast = await toastController.create({
    message: i18n.global.t("tasks.updated_task"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
  emit("update:tasks");
};

const canOptionsMenu = computed(() => {
  return (
    props.task.status === ENUM_TASK.pending &&
    ((props.task.assigned_user_id === user.value.id || props.task.assigned_user_id != "") ||
      user.value.role !== ROLES.EMPLOYEE)
  );
});
</script>
<style lang="scss" scoped>
.header-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
ion-chip {
  --background: #dee6f7;
  --color: #1c4197;
  ion-avatar {
    svg {
      margin-left: 10px;
      width: 15px;
    }
  }
}
.avatar-container-task {
  display: flex;
  gap: 10px;
}
.description-task {
  color: #5e6573;
  font-size: 14px;
}
.safe-area {
  padding-top: 35px;
}
</style>
