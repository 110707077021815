<template>
  <section class="request-wrapper" v-if="user">
    <div class="request-wrapper__user">
      <AvatarComponent
        class="request-wrapper__user__photo"
        :image-id="user.image_id ?? -1"
        :user-id="user.id"
      />
      <div class="request-wrapper__user__info">
        <h6>{{ user.name }}</h6>
        <p class="caption">{{ user?.prefix_number }}{{ user.phone_number }}</p>
      </div>
    </div>
    <hr class="request-wrapper__division" />
    <div class="request-wrapper__actions">
      <div class="request-wrapper__actions__action" style="padding-right: 2px">
        <ion-button
          id="reject_request"
          mode="ios"
          fill="outline"
          expand="block"
          type="button"
          @click="reject"
          >{{ $t("actions.reject") }}
        </ion-button>
      </div>
      <div class="request-wrapper__actions__action" style="padding-left: 2px">
        <ion-button
          mode="ios"
          expand="block"
          type="button"
          @click="toggleModal(true)"
          name="confirm"
          >{{ $t("actions.confirm") }}
        </ion-button>
        <ion-modal
          :is-open="isOpen"
          :initial-breakpoint="0.6"
          :breakpoints="[0, 0.25, 0.5, 0.6, 0.75]"
        >
          <ion-content class="ion-padding">
            <div class="request-wrapper__confirm">
              <AvatarComponent
                class="request-wrapper__confirm__photo"
                :image-id="user.image_id ?? -1"
                :user-id="user.id"
              />
              <div class="request-wrapper__confirm__info">
                <h6>{{ user.name }}</h6>
                <p class="caption">
                  {{ user.prefix_number }}{{ user.phone_number }}
                </p>
              </div>
              <section class="request-wrapper__confirm__body">
                <h6>{{ $t("joinTeam.assignRole") }}</h6>
                <p class="caption">
                  {{ $t("joinTeam.assignRoleDescription") }}
                </p>
              </section>
            </div>
            <ion-radio-group
              :value="role"
              style="margin-top: 20px"
              mode="md"
              @ionChange="getEmployee($event)"
            >
              <ion-item>
                <ion-label>{{ $t("joinTeam.employee") }}</ion-label>
                <ion-radio slot="end" value="employee"></ion-radio>
              </ion-item>
              <ion-item>
                <ion-label>{{ $t("joinTeam.manager") }}</ion-label>
                <ion-radio slot="end" value="manager"></ion-radio>
              </ion-item>
            </ion-radio-group>
            <ion-button
              style="margin-top: 40px"
              mode="ios"
              expand="block"
              type="button"
              @click="confirm"
              >{{ $t("actions.save") }}
            </ion-button>
          </ion-content>
        </ion-modal>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref, Ref } from "vue";
import { MODEL, ROLES } from "@/utils/constants";
import { handleSearchReadModel } from "@/services/generic";
import { IUser } from "@/interfaces/login/ILoginResponse";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {
  IonButton,
  IonModal,
  IonContent,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  RadioGroupCustomEvent,
  actionSheetController,
} from "@ionic/vue";
import { useJoin } from "@/composables/useJoin";
import { getSubscriptionAvailability, getTeamMembers, handlerUserAvailability } from "@/services/subscription";
import i18n from "@/plugins/i18n";
import { IActionShareResult } from "@/interfaces/IActionShareResult";
import router from "@/router";
import { CoreStore, useCoreStore } from "@/stores/core";
import { RegisterStore, useRegisterStore } from "@/stores/register";

interface Props {
  item: any;
}

const role = ref(ROLES.EMPLOYEE);

const props = defineProps<Props>();

const user: Ref<IUser | undefined> = ref<IUser>();
const isOpen: Ref<boolean> = ref(false);
const { rejectSent, rejectRequest, confirmRequest } = useJoin();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();
const addLocationResult: Ref<IActionShareResult | undefined> = ref();

const emit = defineEmits(["click:update"]);

const reject = async () => {
  await rejectRequest(props.item.id);
  emit("click:update");
  if (rejectSent.value == true) {
    await getApplications();
  }
};

const showActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: i18n.global.t("global.notMoreUsers"),
    subHeader: i18n.global.t("global.notMoreUsersDescription"),
    cssClass: "wehop-class",
    id: "wehop-id",
    buttons: [
      {
        text: i18n.global.t("global.addUsers"),
        cssClass: "wehop-class__flex",
        data: {
          action: "addUsers",
        },
      },
      {
        text: i18n.global.t("global.changePlan"),
        cssClass: "wehop-class__flex",
        data: {
          action: "changePlan",
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        cssClass: "wehop-class__cancel_button",
        data: {
          action: "cancel",
        },
      },
    ],
  });

  await actionSheet.present();

  const res = await actionSheet.onDidDismiss();
  addLocationResult.value = res as IActionShareResult;
  if (addLocationResult.value.data.action === "addUsers") {
    coreStore.setUpdateLocationPlan(false);
    coreStore.setUpdateUserPlan(true);
    await router.replace({ name: "registerPlan" });
  }
  if (addLocationResult.value.data.action === "changePlan") {
    coreStore.setUpdateLocationPlan(true);
    coreStore.setUpdateUserPlan(false);
    await router.replace({ name: "registerPlan" });
  }
};

const toggleModal = async (status: boolean) => {
  isOpen.value = false;
  if (await handlerUserAvailability()) {
    coreStore.setUpdateUserPlan(false);
    coreStore.setUpdateLocationPlan(true);
    isOpen.value = status;
  } else {
    coreStore.setUpdateLocationPlan(false);
    coreStore.setUpdateUserPlan(true);
    await showActionSheet();
  }
};

const getEmployee = async (event: RadioGroupCustomEvent) => {
  role.value = event.target.value;
};

const confirm = async () => {
  await toggleModal(false);
  await confirmRequest(props.item.id, role.value).then(async () => {
    if (registerStore.user.location_id) {
      //Get Team Members (Only for Owners)
      await getTeamMembers(registerStore.user.location_id);
      //Get Subscription Availability (Only for Owners)
      await getSubscriptionAvailability();
    }
    //Get Applications
    await getApplications();
    emit("click:update");

  });
};

const getApplications = async () => {
  try {
    const payload = {
      model: MODEL.USER,
      fields: [
        "name",
        "last_name",
        "phone_number",
        "prefix_number",
        "image_id",
      ],
    };
    const filter = [
      {
        field: "id",
        op: "==",
        value: props.item.user_id,
      },
    ];
    handleSearchReadModel(payload, filter).then(async (data) => {
      user.value = data.items[0] as IUser;
    });
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getApplications();
});
</script>

<style scoped lang="scss">
.request-wrapper {
  margin: 0px 20px !important;
  margin-bottom: 10px !important;
  padding: 20px;
  background: white;
  border-radius: 10px;
  width: 100%;

  &__confirm {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__body {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      flex-direction: column;

      h6 {
        padding: 0px;
        margin: 10px 0px 0px 0px;
      }

      p {
        padding: 0px;
        margin: 10px 0px 0px 0px;
      }
    }

    &__photo {
      width: 80px;
      height: 80px;
    }

    &__info {
      text-align: center;

      h6 {
        text-align: center;
        padding: 0px;
        margin: 10px 0px 0px 0px;
      }

      p {
        text-align: center;
        padding: 0px;
        margin: 0px;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__photo {
      width: 40px;
      height: 40px;
    }

    &__info {
      padding-left: 10px;
      margin-right: 10px;

      h6 {
        padding: 0px;
        margin: 0px;
      }

      p {
        padding: 0px;
        margin: 0px;
      }
    }
  }

  &__division {
    background: #c7ccd680;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    &__action {
      width: 50%;
    }
  }
}
</style>
