<template>
    <section class="register-option-wrapper" @click="redirectLink">
        <div class="register-option-wrapper__icon">
            <ion-icon :icon="icon"></ion-icon>
        </div>
        <div class="register-option-wrapper__body">
            <h6 class="register-option-wrapper__body__title">{{ title }}</h6>
            <p class="register-option-wrapper__body__description">{{ description }}</p>
        </div>
        <div class="register-option-wrapper__arrow">
            <ion-buttons slot="primary">
                <ion-button>
                    <ion-icon :icon="chevronForward" color="neutral-80"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </section>
</template>

<script setup lang="ts">

import {IonIcon, IonButtons, IonButton} from '@ionic/vue';
import {chevronForward} from 'ionicons/icons';
import {useRouter} from "vue-router";
import {CoreStore, useCoreStore} from "@/stores/core";
import {USER_TYPES} from "@/utils/constants";

const router = useRouter();
const coreStore: CoreStore = useCoreStore()

const props = defineProps(
    {
        icon: String,
        title: String,
        description: String,
        to: String,
        userType: {
            type: String,
            required: true,
            default: USER_TYPES.EMPLOYEE
        }
    }
)

const redirectLink = async () => {
    coreStore.setUserType(props.userType)
    await router.push({name: props.to});
}

</script>

<style scoped lang="scss">
.register-option-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    color: #5E6573;
    margin-right: 10px;
  }

  &__body {
    margin-bottom: 10px;

    &__title {
      padding: 0px;
      margin: 5px 0px !important;
    }

    &__description {
      margin: 0px;
      padding: 0px;
      color: #5E6573;
      font-size: 14px;
    }
  }
}
</style>
