<template>
  <RegisterWrapper
      :show-progress="false"
      :title-button-back="$t('register.uploadPhoto')"
      :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <section class="photo-wrapper__body">
        <div class="photo-wrapper__logo">
          <Cropper
              ref="cropper"
              class="photo-wrapper__background"
              :src="img"
              :auto-zoom="true"
              :stencil-props="{
                handlers: {},
                movable: true,
                resizable: true,
            }"
              :stencil-size="{
              width: cropperWidth,
              height: cropperHeight,
            }"
              @change="onChange"
          />
        </div>
        <div class="photo-wrapper__cropper__options">
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="decreaseCropper"
          >
            <ion-icon :icon="removeOutline"></ion-icon>
          </ion-button>
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="photoSubmit"
          >
            <ion-icon :icon="scanOutline"></ion-icon>
          </ion-button>
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="clear"
              color="neutral-40"
              @click="increaseCropper"
          >
            <ion-icon :icon="addOutline"></ion-icon>
          </ion-button>
        </div>
        <div class="photo-wrapper__options">
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              fill="outline"
              @click="handleFileImport"
          >{{ $t("actions.changePhoto") }}
          </ion-button>
          <input
              accept="image/jpeg, image/png"
              ref="uploader"
              style="display: none"
              type="file"
              @change="onFileChanged"
          />
          <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="button"
              @click="photoSubmit"
          >{{ $t("actions.save") }}
          </ion-button>
        </div>
      </section>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import {useRegister} from "@/composables/useRegister";
import {IonButton, IonIcon, toastController} from "@ionic/vue";
import {addOutline, removeOutline, scanOutline} from "ionicons/icons";
import {Cropper, CropperResult} from "vue-advanced-cropper";
import {onMounted, ref} from "vue";

import {RegisterStore, useRegisterStore} from "@/stores/register";
import {useRoute} from "vue-router";
import {usePlatform} from "@/composables/usePlatform";
import {getImageLetter} from "@/utils/getImageLetter";
import {Camera, CameraResultType} from "@capacitor/camera";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {CoreStore, useCoreStore} from "@/stores/core";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const {isPlatformIOS} = usePlatform();
const {photo, sendPhoto} = useRegister();
const route = useRoute();
const isSelecting = ref<boolean>(false);
const uploader = ref<HTMLInputElement>();
const cropper = ref<CropperResult>();
const goBack = ref<boolean>(false);
const newPhoto = ref(false);
const fileName = ref("");
const newPhotoBase64 = ref<string>();
const cropperWidth = ref(200);
const cropperHeight = ref(200);
const {isMobile} = useNativePlatform();
const img = ref<string | ArrayBuffer | null | undefined>("");

const photoSubmit = async () => {
  coreStore.toggleLoading(true);
  try {
    photo.value = newPhotoBase64.value ?? "";
    await sendPhoto(fileName.value, goBack.value);

    const toast = await toastController.create({
      message: "Imagen cargada con éxito.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  } catch (e) {
    const toast = await toastController.create({
      message: "Ocurrió un error al cargar la imagen, intente de nuevo.",
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }  finally {
    coreStore.toggleLoading(false);
  }
};
const increaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height + 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width + 10;
};
const decreaseCropper = () => {
  cropper.value!.coordinates.height = cropper.value!.coordinates.height - 10;
  cropper.value!.coordinates.width = cropper.value!.coordinates.width - 10;
};
const onChange = (canvas: CropperResult) => {
  newPhotoBase64.value = canvas.canvas?.toDataURL() ?? undefined;
};

const onFileChanged = (e: any) => {
  isSelecting.value = false;
  if (e.target.files && e.target.files[0]) {
    newPhoto.value = true;
    fileName.value = e.target.files[0].name;
    getBase64(e.target.files[0]);
  }
};

const getBase64 = (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img.value = reader.result;
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

const takePicture = async () => {
  const permissions = await Camera.requestPermissions();
  if (permissions.photos === "granted" || permissions.camera === "granted") {
    await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
    }).then((photo) => {
      newPhoto.value = true;
      fileName.value = "photo.jpg";
      img.value = "data:image/jpeg;base64," + photo.base64String;
    });
  }
};

const handleFileImport = () => {
  isSelecting.value = true;
  window.addEventListener(
      "focus",
      () => {
        isSelecting.value = false;
      },
      {once: true}
  );
  if (isMobile) {
    takePicture();
  } else {
    uploader.value?.click();
  }
};

onMounted(() => {
  goBack.value = route.query.return == "true";
  img.value = getImageLetter(registerStore.user.last_name ?? 'LL');
});
</script>
<style scoped lang="scss">
.photo-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__cropper {
    width: 100%;

    &__options {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  &__options {
    width: 100%;
  }

  &__logo {
    margin-top: 20px;
    width: 90%;
  }

  &__background {
    background: #d4d9d9;
    height: 400px;
  }
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
