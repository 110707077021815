<template>
  <ion-page @click.prevent="closeContextualMenu">
    <ion-content class="ion-padding">
      <ion-header class="talkie-wrapper">
        <div class="talkie-wrapper__back">
          <ion-back-button
            text=""
            color="neutral-80"
            defaultHref="/talkie"
          ></ion-back-button>
        </div>
        <div class="talkie-wrapper__locale">
          <avatar-locale-component
            :locale-name="registerStore.location.name"
            :image-id="registerStore.location.image_id"
            :locale-id="registerStore.location.id ?? -1"
          />
          <div class="locale-info-wrapper">
            <h3 id="locale_name" class="ion-padding">
              {{ registerStore.location.name }}
            </h3>
            <p class="caption">{{ total }} personas activas</p>
          </div>
        </div>
        <div class="talkie-wrapper__options">
          <DropdownMenuAudioComponent
            @click:button="showButton"
            @click:mark="deleteNotifications"
          />
        </div>
      </ion-header>
      <ion-content class="message-container">
        <div class="message-container__grouped" v-for="key in keys" :key="key">
          <p
            class="caption message-container__grouped__title"
            v-if="grouped[key].length"
          >
            {{ $t(`global.${key}`) }}
          </p>
          <MessageWrapper
            :notifications="notifications"
            :message="message"
            v-for="message in grouped[key]"
            :key="message"
          />
        </div>
        <div
          class="message-container__delete-message"
          v-if="notificationList.length > 0"
        >
          <span class="register-link" @click="deleteNotifications">{{
            $t("talkie.markAsListened")
          }}</span>
        </div>
        <ion-infinite-scroll @ionInfinite="ionInfinite">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
      <RecordBarComponent v-if="showRecord" :show-all-options="false" />
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  InfiniteScrollCustomEvent,
  IonBackButton,
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/vue";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { computed, onMounted, ref } from "vue";
import { useUserLocation } from "@/composables/useUserLocation";
import DropdownMenuAudioComponent from "@/components/admin/talkie/DropdownMenuAudioComponent.vue";
import { useMessage } from "@/composables/useMessage";
import MessageWrapper from "@/components/admin/talkie/MessageWrapper.vue";
import RecordBarComponent from "@/components/admin/talkie/RecordBarComponent.vue";
import {
  handleRemoveNotifications,
} from "@/services/talkie";
import { useRegister } from "@/composables/useRegister";
import { CoreStore, useCoreStore } from "@/stores/core";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { total, getUserLocations } = useUserLocation();
const {
  notifications,
  grouped,
  getLocationMessages,
  incrementPage,
  resetPage,
} = useMessage();
const { setNotifications } = useRegister();

const keys = computed(() => {
  return Object.keys(grouped.value);
});

const notificationList = computed(() => {
  return notifications.value;
});

const showRecord = ref(false);

/**
 * Close contextual menu
 */
const closeContextualMenu = () => {
  coreStore.setShowDropdown(false);
};

/**
 * Show Button
 */
const showButton = () => {
  coreStore.setShowDropdown(false);
  showRecord.value = !showRecord.value;
};

/**
 * Delete Notifications
 */
const deleteNotifications = async () => {
  coreStore.setShowDropdown(false);
  await handleRemoveNotifications(notifications.value).then(async () => {
    if (registerStore.location.id) {
      // Reset page
      resetPage();
      await getLocationMessages(registerStore.location.id);
      coreStore.setRefreshLocation(true);
      coreStore.setRefreshUserLocation(true);
    }
  });
};

/**
 * Infinite Scroll
 * @param ev
 */
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
  if (registerStore.location.id) {
    incrementPage();
    await getLocationMessages(registerStore.location.id);
    coreStore.setRefreshLocation(false);
    coreStore.setRefreshUserLocation(false);
  }
};

onMounted(async () => {
  if (registerStore.location.id) {
    await getUserLocations(registerStore.location.id);
    await getLocationMessages(registerStore.location.id);
  }
});
</script>
<style scoped lang="scss">
.message-container {
  overflow: scroll;
  height: 85%;

  &__grouped {
    p {
      text-align: center;
    }

    &__title {
      text-transform: capitalize;
    }
  }

  &__delete-message {
  }
}

.locale-info-wrapper {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  h3 {
    margin: 0px;
    padding: 0px;
  }

  p {
    margin: 0px;
    padding: 0px;
  }
}

.talkie-wrapper {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__locale {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    h3 {
      text-align: left;
    }
  }

  &__options {
    position: absolute;
    right: 10px;
    top: 30px;
  }
}

.register-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-underline: none;
  color: #4178f5;
  cursor: pointer;
  font: {
    weight: 600;
  }
}
</style>
