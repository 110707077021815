<template>
  <ion-page>
    <WrapperPageComponent v-if="dashboardOwner">
      <div class="ion-padding">
        <ion-row>
          <ion-col size="10">
            <CurrentPlan />
          </ion-col>
          <ion-col size="2">
            <div style="margin-botton: 10px">
              <ion-button
                mode="ios"
                expand="block"
                class="button--login ion-margin-top"
                type="button"
                style="width: 100%"
                router-link="/location"
              >
                <VsxIcon iconName="ShopAdd"></VsxIcon>
              </ion-button>
            </div>
            <div>
              <ion-button
                mode="ios"
                expand="block"
                class="button--login ion-margin-top"
                type="button"
                style="width: 100%"
                router-link="/tasks"
              >
                <VsxIcon iconName="Notification"></VsxIcon>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <CardBusinessInfo
              icon="Shop"
              :value="dashboardOwner.number_locations"
              :text="$t('global.locals')"
              color-white
              center
              @click.prevent="handleGetRoute('admin.locations.list')"
            />
          </ion-col>
          <ion-col size="6">
            <CardBusinessInfo
              icon="People"
              :value="dashboardOwner.number_users"
              :text="$t('global.users')"
              color-white
              center
              @click.prevent="handleGetRoute('admin.groups.list.members')"
            />
          </ion-col>
          <ion-col size="6">
            <CardBusinessInfo
              icon="Send2"
              :value="dashboardOwner.number_invitations"
              :text="$t('global.invitations')"
              color-white
              center
              @click.prevent="handleGetRoute('admin.groups.list.requests')"
            />
          </ion-col>
          <ion-col size="6">
            <CardBusinessInfo
              icon="Note"
              :value="`${dashboardOwner.number_tasks.pending}/${dashboardOwner.number_tasks.total}`"
              :text="$t('global.tasks')"
              color-white
              center
              @click.prevent="handleGetRoute('default.tasks.list')"
            />
          </ion-col>
        </ion-row>
      </div>
      <MainButtonBarComponent />
    </WrapperPageComponent>
  </ion-page>
</template>
<script lang="ts" setup>
import { IonButton, IonPage, IonRow, IonCol, alertController } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import MainButtonBarComponent from "@/components/admin/MainButtonBarComponent.vue";
import { onMounted, ref, watch } from "vue";
import useCore from "@/composables/useCore";
import router from "@/router";
import CurrentPlan from "@/components/shared/CurrentPlan.vue";
import CardBusinessInfo from "@/components/shared/my_profile/CardBusinessInfo.vue";
const coreStore: CoreStore = useCoreStore();
import { handlerDashboardOwner } from "@/services/dashboard";
import { IDashboardOwner } from "@/interfaces/dashboard/IDashboardOwner";
import { GROUP_OWNER_VIEW } from "@/utils/constants";
import {CoreStore, useCoreStore} from "@/stores/core";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";


const { user } = useRegister();

const { showTask, refreshDashboardOwner, setGroupTabSelected } = useCore();

const dashboardOwner = ref<IDashboardOwner>();

watch(showTask, () => {
  if (showTask.value) {
    router.push({ name: "default.tasks.list" });
  }
});

watch(refreshDashboardOwner, async () => {
  try {
    if (refreshDashboardOwner.value) {
      const data: IDashboardOwner = await handlerDashboardOwner();
      dashboardOwner.value = data;
      coreStore.setRefreshLocation(false);
      coreStore.setRefreshUserLocation(false);
      coreStore.setRefreshTask(false);
      coreStore.setRefreshDashboardOwner(false);
      coreStore.setRefreshDashboard(false);
    }
  } catch (e) {
    console.error(e);
  }
});

onMounted(async () => {
  try {
    const data: IDashboardOwner = await handlerDashboardOwner();
    dashboardOwner.value = data;
    if (user.value.is_owner) {
      await handlerIncompleteOwnerOnboarding();
    }
  } catch (e) {
    console.error(e);
  }
});

const handleGetRoute = (to: any) => {
  if (to === "admin.groups.list.members") {
    setGroupTabSelected(GROUP_OWNER_VIEW.MEMBERS);
    router.push({ name: "admin.groups.list" });
  }
  if (to === "admin.groups.list.requests") {
    setGroupTabSelected(GROUP_OWNER_VIEW.REQUESTS);
    router.push({ name: "admin.groups.list" });
  }
  if (to === "default.tasks.list") {
    router.push({ name: to });
  }
  if (to === "admin.locations.list") {
    router.push({ name: to });
  }
};

const handlerIncompleteOwnerOnboarding = async () => {
  try {
    await presentAlert();
  } catch (e) {
    console.error(e);
  }
};

const presentAlert = async () => {
  const alert = await alertController.create({
    header: i18n.global.t("global.atention"),
    message: i18n.global.t("global.createTeamMessage"),
    cssClass: "wehop-alert",
    buttons: [
      {
        text: i18n.global.t("register.createTeam"),
        cssClass: "wehop-alert-cancel",
        handler: async () => {
          try {
            await router.push({ name: "admin.locations.list" });  
          } catch (e) {
            console.error(e);
          }
        },
      },
    ],
  });
  await alert.present();

  console.log(alert);
}
</script>
<style scoped lang="scss"></style>
