<template>
  <RegisterWrapper
    :show-progress="false"
    :title-button-back="$t('register.createProfile')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <h2 class="forgot-password-wrapper__title form-padding">
        {{ $t("register.changePassword") }}
      </h2>
      <p class="forgot-password-wrapper__subtitle text-body-s">
        {{ $t("register.changePasswordMessage") }}
      </p>
      <FormComponent
        :model="model"
        :schema="schemaPassword"
        no-cancel-button
        @success="passwordSubmit"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >Guardar
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaPassword } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import { usePlatform } from "@/composables/usePlatform";

const { password, sendOwnerInfo } = useRegister();
const { isPlatformIOS } = usePlatform();

const model: Ref<any> = ref({
  name: "change_password",
  fields: [
    {
      name: "password",
      label: "Nueva contraseña",
      type: "password",
      icon: "key",
    },
    {
      name: "confirm_password",
      label: "Repite la contraseña",
      type: "password",
      icon: "key",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  password.value = values.password;
  await sendOwnerInfo();
};
</script>
<style scoped lang="scss">
.form-padding {
  padding: 60px 0px 0px 0px;
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
