<template>
  <ion-accordion-group
    value="first"
    v-show="storeList.generic[`items_${model}`].length"
  >
    <ion-accordion value="first">
      <ion-item slot="header">
        <ion-label class="advice-title">Avisos</ion-label>
      </ion-item>
      <div class="container-list" slot="content">
        <ListWrapper :model="model" :headers="headers">
          <template #item_mobile="props">
            <CardItemNotification
              :advice="props"
              @update:advices="getModel({ reset: true, showLoading: true })"
            />
          </template>
        </ListWrapper>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>
<script lang="ts" setup>
import CardItemNotification from "./CardItemNotification.vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useList } from "@/composables/useList";
import { useGenericStore, GenericStore } from "@/stores/generic";
import { MODEL } from "@/utils/constants";
import dayjs from "dayjs";
import { IonAccordionGroup, IonAccordion } from "@ionic/vue";
import { useRegister } from "@/composables/useRegister";

const model = MODEL.ADVICE;
const { getModelData } = useList();
const storeList: GenericStore = useGenericStore();
const { user } = useRegister();
storeList.setItemsModel(model, []);
storeList.setSortByModel(model, [
  {
    field: "date_start",
    order: "asc",
  },
]);

const headers = [
  {
    label: "Nombre",
    name: "name",
  },
  {
    label: "Fecha inicio",
    name: "date_start",
  },
  {
    label: "Fecha fin",
    name: "date_end",
  },
  {
    label: "User id",
    name: "created_user_id",
  },
];

const getModel = (payload: any) => {
  getModelData({
    headers: headers,
    modelName: model,
    reset: true,
    showLoading: payload?.showLoading,
  });
};

const setDefaultFilter = () => {
  storeList.setFiltersModel(MODEL.ADVICE, [
    {
      field: "date_start",
      value: dayjs().format("YYYY-MM-DD"),
      op: "<=",
    },
    {
      field: "date_end",
      value: dayjs().format("YYYY-MM-DD"),
      op: ">=",
    },
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ]);
};
setDefaultFilter();
defineExpose({ getModel });
</script>
<style scoped lang="scss">
.advice-title {
  font-weight: 600;
  font-size: 16px;
  color: #2e333d;
}
ion-accordion {
  padding-bottom: 0px;
  ion-item {
    --padding-start: 0px !important;
  }
}
</style>
