<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <section class="join-wrapper__header">
        <div>
          <img :src="wehopBrand" alt="WeHop Brand" />
        </div>
        <div>
          <DropdownMenuComponent
            @click:profile="openProfile"
            @click:settting="openSetting"
            @click:logout="openLogout"
          />
        </div>
      </section>
      <section class="join-wrapper__body">
        <img :src="joinTeam" />
        <h3 class="join-wrapper__title">
          {{ $t("global.hi") }} {{ registerStore.user.name }}
        </h3>
        <p class="join-wrapper__description caption">
          Aún no estás en ningún equipo
        </p>
        <div ref="tour1_4" style="width: 100%; height: 100px; padding: 0px;margin: 0px;">
        <ion-button
          class="join-wrapper__button"
          fill="outline"
          expand="block"
          style="width: 100%"
          router-link="/join/validate"
        >
          <ion-icon :icon="addOutline"></ion-icon>
          {{ $t("actions.joinTeam") }}
        </ion-button>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";

const registerStore: RegisterStore = useRegisterStore();

import { IonButton, IonContent, IonPage, IonIcon } from "@ionic/vue";
import { addOutline } from "ionicons/icons";
import wehopBrand from "@/assets/wehop-brand.svg";
import joinTeam from "@/assets/join-team.svg";
import DropdownMenuComponent from "@/components/shared/avatar/DropdownMenuComponent.vue";
import router from "@/router";
import { handleLogout } from "@/services/auth";
import {onMounted, ref} from "vue";
import {useShepherd} from "vue-shepherd";
import i18n from "@/plugins/i18n";
import useCore from "@/composables/useCore";

const { setResetPinDigit, setResetForm } = useCore();
const tour1_4 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const openProfile = () => {
  router.push({ name: "myProfileEmployee" });
};
const openSetting = () => {
  router.push({ name: "mySettingEmployee" });
};
const openLogout = async () => {
  try {
    await handleLogout().then(async () => {
      setResetPinDigit(true);
      setResetForm(true);
      localStorage.clear();
      setTimeout(async () => {
        await router.push({ name: "login" });
      }, 500);
    });
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_4.value, on: "top" },
    title: i18n.global.t("tour.tour1_4.title"),
    text: i18n.global.t("tour.tour1_4.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});

</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.join-wrapper {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    margin: 30px 0px 0px 0px;
  }

  &__button {
    margin: 30px 0px 0px 0px;
  }
}
</style>
