<template>
  <RegisterWrapper
    :show-button-back="false"
    :show-progress="false"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div v-if="teams">
        <h3 class="downgrade-title">
          {{ $t("payment.planChangeTitle") }}
        </h3>
        <p
          v-if="teams"
          class="downgrade-description caption"
          v-html="$t('payment.planChangeTeamTitleDescriptionOption')"
        ></p>
        <section v-if="teams">
          <ul class="downgrade-users">
            <li v-for="team in teams" :key="team">
              <div v-if="registerStore.user.location_id">
                <div></div>
                <TeamInfo
                  :team="team"
                  :location-id="registerStore.user.location_id"
                />
              </div>
            </li>
          </ul>
        </section>
        <ion-row>
          <ion-col size="12">
            <ion-button
              color="primary"
              expand="block"
              :disabled="
                coreStore.subscriptionAvailability?.teams_status?.number_teams >
                1
              "
              @click="goNextStep"
              >{{ $t("actions.continue") }}
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { IonButton } from "@ionic/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import "cordova-plugin-purchase";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
const { setLocation } = useRegister();
import { RegisterStore, useRegisterStore } from "@/stores/register";

import TeamInfo from "@/components/shared/TeamInfo.vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handleLocationData } from "@/services/location";
import { useRegister } from "@/composables/useRegister";

const router = useRouter();

const { isPlatformIOS } = usePlatform();
const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();

const teams = ref<ILocation[]>([]);
const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: "auto",
  setWrapperSize: true,
  spaceBetween: 0,
};

const goNextStep = async () => {
  coreStore.setNewLocal(true);
  handleLocationData().then(async (data) => {
    ///Set Location
    setLocation(data[0]);
    if (
      coreStore.subscriptionAvailability &&
      coreStore.subscriptionAvailability?.users_status?.number_users <= 5
    ) {
      await router.replace({ name: "registerPlanTeamsFinishDowngrade" });
    } else {
      await router.replace({ name: "registerPlanOptionTwoDowngrade" });
    }
  })
};

onMounted(async () => {
  if (registerStore.user.entity_id) {
    await handleLocationData().then((res: ILocation[]) => {
      teams.value = res;
    });
  }
});
</script>
<style scoped lang="scss">
.plan-wrapper {
  &__body {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__options {
    width: 100%;
  }
}

.button--login {
  width: 100%;
}

ion-slides {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  &::v-deep .swiper-wrapper {
    height: auto;
  }

  &::v-deep .swiper-slide {
    width: auto;

    &.center {
      width: 100% !important;
    }
  }
}

ion-slide {
  align-items: stretch;
}

.safe-area {
  padding-top: 10px;
}

.plan-blocked {
  background-color: #d3d3d3;
  color: #a1a1a1;
  pointer-events: none;
  opacity: 0.7;
}

.downgrade-title {
  text-align: center;
  margin-top: 20px;
}

.downgrade-description {
  text-align: center;
}

.downgrade-button {
  margin-top: 40px;
}

.downgrade-users {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  border: 1px solid blue;
  padding: 10px;
  border-radius: 10px;

  > li {
    list-style: none;
    width: 85%;
    height: 35px;
    display: flex;
  }
}

.user-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__checkbox {
    width: 10%;
    margin-right: 10px;
  }

  &__name {
    width: 90%;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}
</style>
