<template>
  <div>
    <ion-accordion-group value="first">
      <ion-accordion value="first">
        <ion-item slot="header">
          <ion-label class="comments-title"
            >Comentarios ({{ comments.length }})</ion-label
          >
        </ion-item>
        <div class="ion-padding-top comments-content" slot="content">
          <hr class="divider" />
          <div
            class="comments-item ion-padding-top"
            v-for="(
              { comment, created_datetime, id, created_user_id }, index
            ) of comments"
            :key="index"
          >
            <div class="comments-text-container">
              <AvatarCommentComponent :userId="created_user_id" v-slot="props">
                <div class="comments-text">
                  <ion-label class="comments-user">
                    {{ props.fullName }}
                  </ion-label>
                  <ion-label class="comments-message" v-if="idComment != id">
                    {{ comment }}
                  </ion-label>
                  <div class="comment-input ion-margin-vertical" v-else>
                    <ion-textarea
                      :rows="1"
                      color="dark"
                      :cols="1"
                      placeholder="Editar comentario"
                      :value="comment"
                      @ionInput="updateInputComment"
                      @keypress.enter="handleUpdateComment"
                    >
                    </ion-textarea>
                    <VsxIcon
                      iconName="Back"
                      color="#cf2617"
                      @click="idComment = null"
                    ></VsxIcon>
                    <VsxIcon
                      iconName="Send"
                      @click="handleUpdateComment"
                    ></VsxIcon>
                  </div>
                  <ion-note class="comments-date">
                    {{ created_datetime }}</ion-note
                  >
                </div>
              </AvatarCommentComponent>
            </div>
            <div>
              <DropdownMenuTaskComponent
                v-if="canOptionsMenu(created_user_id)"
                text-edit="Editar"
                text-delete="Eliminar"
                @click:delete="handleDeleteComment(id)"
                @click:edit="handleEnableEditComment(id)"
              />
            </div>
          </div>
        </div>
      </ion-accordion>
    </ion-accordion-group>
    <div class="comments-input">
      <div class="comment-input" v-if="taskStatus !== ENUM_TASK.finished">
        <ion-textarea
          :rows="1"
          color="dark"
          :cols="1"
          placeholder="Añadir comentario"
          v-model="message"
          @keypress.enter="handleNewComment"
        >
        </ion-textarea>
        <VsxIcon iconName="Send" @click="handleNewComment"></VsxIcon>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import AvatarCommentComponent from "@/components/admin/tasks/AvatarCommentComponent.vue";
import DropdownMenuTaskComponent from "@/components/admin/tasks/DropdownMenuTaskComponent.vue";
import { ENUM_TASK, MODEL, ROLES } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import dayjs from "dayjs";
import {
  handleDeleteModel,
  handlePostModel,
  handlePutModel,
  handleSearchReadModel,
} from "@/services/generic";
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonNote,
  IonTextarea,
  toastController,
} from "@ionic/vue";

interface Props {
  taskId: number | undefined;
  taskStatus?: string;
}

interface Comment {
  created_user_id?: number | undefined;
  comment?: string;
  task_id?: string;
  id?: number;
  created_datetime?: string;
}

const props = defineProps<Props>();
const message: Ref<string> = ref("");
const comments: Ref<Comment[]> = ref([]);
const idComment: Ref<any> = ref(null);
const { user } = useRegister();

const handleGetComments = async () => {
  try {
    const payload = {
      model: MODEL.COMMENT,
      size: 200,
      fields: [
        "created_user_id",
        "comment",
        "task_id",
        "id",
        "created_datetime",
      ],
    };
    const filters = [
      {
        value: props.taskId,
        field: "task_id",
        op: "==",
      },
    ];
    const sortBy = [
      {
        field: "created_datetime",
        order: "asc",
      },
    ];
    const { items } = await handleSearchReadModel(payload, filters, sortBy);
    comments.value = items.map((item: any) => {
      return {
        ...item,
        created_datetime: dayjs(item.created_datetime).format("DD MMM YYYY"),
      };
    });
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleNewComment = async () => {
  try {
    if (message.value.length) {
      const payload = {
        model: MODEL.COMMENT,
        fields: {
          task_id: props.taskId,
          comment: message.value,
        },
      };
      await handlePostModel(payload);
      message.value = "";
      handleGetComments();
    }
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleDeleteComment = async (id: any) => {
  try {
    const payload = {
      model: MODEL.COMMENT,
      id: id,
    };
    await handleDeleteModel(payload);
    handleGetComments();
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleEnableEditComment = (id: any) => {
  idComment.value = id;
};

const handleUpdateComment = async () => {
  const item = comments.value.find(
    (comment: any) => comment.id === idComment.value
  );
  try {
    if (item?.comment?.length) {
      const payload = {
        model: MODEL.COMMENT,
        id: idComment.value,
        fields: {
          comment: item.comment,
        },
      };
      await handlePutModel(payload);
      idComment.value = null;
      handleGetComments();
    }
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("tasks.fail_finish"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const updateInputComment = (e: any) => {
  const index = comments.value.findIndex(
    (comment: any) => comment.id === idComment.value
  );
  comments.value[index].comment = e.target.value;
};

handleGetComments();

const canOptionsMenu = (user_created_user: any) => {
  return (
    user_created_user === user.value.id || user.value.role !== ROLES.EMPLOYEE
  );
};
</script>
<style lang="scss">
.comments-title {
  font-weight: 600;
  font-size: 16px;
  color: #2e333d;
}
ion-accordion {
  padding-bottom: 40px;
  ion-item {
    --padding-start: 0px !important;
  }
}

.comments-item {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  div {
    ion-avatar {
      width: 24px;
      height: 24px;
    }
  }
  .comments-text-container {
    width: 100%;
    display: flex;
    gap: 15px;
    .container-avatar {
      width: 100%;
      .label-avatar {
        width: 100%;

        .comments-text {
          padding-bottom: 20px;
          .comments-user {
            font-size: 14px;
            color: #464c59;
          }
          .comments-message {
            margin-top: 5px;
            display: flex !important;
            word-break: break-word;
            font-size: 14px;
            color: #5e6573;
            margin-bottom: 10px;
          }
          .comments-date {
            color: #787f8c;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.divider {
  height: 1px;
  background-color: rgba(199, 204, 214, 0.5);
}
.comments-input {
  position: fixed;
  bottom: 15px;
  left: 10px;
  right: 10px;
}
.comment-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fc;
  color: #787f8c;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
}
.comments-content {
  padding-bottom: 30px;
}
</style>
