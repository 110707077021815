<template>
  <ion-card
    class="ion-no-margin ion-margin-vertical"
    @click="showDetail = true"
  >
    <ion-item lines="none">
      <ion-avatar slot="start" class="icon-notification ion-margin-start">
        <VsxIcon iconName="Notification"></VsxIcon>
      </ion-avatar>
      <p>
        {{ advice.name }}
      </p>
    </ion-item>
  </ion-card>
  <ModalDeleteComponent
    v-if="showModalDeleteAdvice"
    @confirm:delete="deleteAdvice"
    v-model="showModalDeleteAdvice"
    :nameItem="advice.name"
  >
    <template #title>
      <h3>Vas a eliminar el aviso del listado</h3>
    </template>
  </ModalDeleteComponent>
  <modal-component
    v-model="showDetail"
    noIconClose
    :initial-breakpoint="0.4"
    :breakpoints="[0, 0.25, 0.5, 0.6, 0.75]"
  >
    <ModalDetailAdvice
      :advice="advice"
      @click:back="showDetail = false"
      @update:advices="handleUpdateAdvices"
      @click:delete="handleDeleteAdvice"
    />
  </modal-component>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import { IonCard, IonAvatar, IonItem, toastController } from "@ionic/vue";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import ModalDetailAdvice from "./ModalDetailAdvice.vue";
import ModalDeleteComponent from "@/components/shared/modal/ModalSimpleDeleteComponent.vue";
import { MODEL } from "@/utils/constants";
import { handleDeleteModel } from "@/services/generic";
import i18n from "@/plugins/i18n";

interface Props {
  advice: any;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:advices"]);
const showModalDeleteAdvice: Ref<boolean> = ref(false);

const showDetail: Ref<boolean> = ref(false);

const handleUpdateAdvices = () => {
  showDetail.value = false;
  emit("update:advices");
};

const deleteAdvice = async () => {
  try {
    const payload = {
      model: MODEL.ADVICE,
      id: props.advice.id,
    };
    await handleDeleteModel(payload);
    const toast = await toastController.create({
      message: i18n.global.t("advice.success_removed"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
    showModalDeleteAdvice.value = false;
    setTimeout(() => {
      emit("update:advices");
    }, 0);
  } catch (error) {
    const toast = await toastController.create({
      message: i18n.global.t("advice.fail_remove"),
      duration: 1500,
      position: "bottom",
      mode: "ios",
    });
    await toast.present();
  }
};

const handleDeleteAdvice = () => {
  showModalDeleteAdvice.value = true;
  setTimeout(() => {
    showDetail.value = false;
  }, 0);
};
</script>
<style scoped lang="scss">
.icon-notification {
  background-color: #f9e8e7;
  color: #cf2617;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 60%;
  }
}
p {
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #5e6573;
}
ion-card {
  box-shadow: none;
  ion-item {
    --background: #f8f9fc;
  }
}
</style>
