<template>
  <ion-page>
    <ion-content class="ion-padding payment-success">
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        "
      >
        <div class="ion-text-center">
          <img :src="successImage" alt="" />
          <h3 class="payment-success__title">
            {{ $t("payment.success_title") }}
          </h3>
          <p class="payment-success__description">
            {{ $t("payment.success_description") }}
          </p>
          <ion-button
            mode="ios"
            expand="block"
            class="button--select ion-margin-top"
            @click="createBusiness()"
            type="button"
          >
            {{ $t("payment.action_create_local") }}
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import { IonContent, IonPage, useBackButton } from "@ionic/vue";
import successImage from "@/assets/success_payment.png";
import { useRouter } from "vue-router";
import { useRegister } from "@/composables/useRegister";

const router = useRouter();
const { user } = useRegister();

const createBusiness = async () => {
  if (user.value.entity_id == null) {
    await router.replace({ name: "registerBusinessInfo" });
  } else {
    await router.replace({
      name: "admin.locations.list",
      params: { new_local: 1 },
    });
  }
};

useBackButton(10, () => {
  return router.replace({ name: "registerBusinessInfo" });
});
</script>
<style scoped lang="scss">
.payment-success {
  --padding-start: 24px;
  --padding-end: 24px;

  &__title {
    margin-top: 32px;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 56px;
    color: #5e6573;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
