<template>
  <ion-page v-if="user.id">
    <ion-content>
      <SnackBarComponent />
      <div class="admin__panel">
        <div class="admin__navigation__drawer">
          <div class="admin__user__item">
            <div class="admin__user__item__dropdown">
              <DropdownLocationMenuComponent @to:locations="closeMenu" />
            </div>
          </div>
          <div class="admin__items">
            <div>
              <router-link
                exact-active-class="active"
                v-for="(item, index) of items"
                :key="index"
                :to="{ name: item.to }"
              >
                <VsxIcon :iconName="item.icon"></VsxIcon>
                <span>{{ item.label }}</span>
              </router-link>
            </div>
            <hr />
          </div>
        </div>
        <div class="admin__content">
          <NavigationComponent v-show="routesEnable"></NavigationComponent>
          <ion-content>
            <ion-refresher slot="fixed" @ionRefresh="handleRefresh">
              <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <ion-router-outlet id="main-content"></ion-router-outlet>
          </ion-content>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import type { Ref } from "vue";
import { VsxIcon } from "vue-iconsax";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import links from "@/layouts/navigation/links";
import SnackBarComponent from "@/components/shared/SnackBarComponent.vue";
import NavigationComponent from "@/components/shared/NavigationComponent.vue";
import { VoiceRecorder } from "capacitor-voice-recorder";
import DropdownLocationMenuComponent from "@/components/shared/DropdownLocationMenuComponent.vue";
import { Item } from "@/interfaces/GenericModel";
import { useRegister } from "@/composables/useRegister";
import { getNotificationsQueue } from "@/services/talkie";
import useRecord from "@/composables/useRecord";
import { RecordButton } from "capacitor-record-button/src";

import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterOutlet,
} from "@ionic/vue";
import { NOTIFICATION_TYPE, ROLES } from "@/utils/constants";
import employee_links from "@/layouts/navigation/employee_links";
import {useTokenNotification} from "@/composables/useTokenNotification";

const route = useRoute();
const router = useRouter();
const routesEnable: Ref<boolean> = ref(false);
const { handleGetMe } = useRegister();
const { user } = useRegister();
const items: Ref<Array<Item>> = ref(
  user.value.role == ROLES.OWNER ? links : employee_links
);

const { playing, toggleRecord } = useRecord();
const { initPushNotifications } = useTokenNotification();

const verifyRoutes = (route: any) => {
  routesEnable.value = [
    "admin.home",
    "employee.home",
    "admin.groups.list",
    "default.talkie.list",
  ].includes(route as string);
};
const intervalId: any = ref();
const closeMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "";
  menu.style.inset = "";
  menu?.close();
};

watch(playing, () => {
  if (playing.value) {
    getNotificationsQueue();
  }
});

watch(route, (to) => {
  if (to.name != "login") {
    if (localStorage.getItem("access_token")) {
      handleGetMe();
    }
  }
  verifyRoutes(to.name);
});
handleGetMe();
verifyRoutes(route.name);

const requestRecord = () => {
  VoiceRecorder.requestAudioRecordingPermission();
};

const handleRefresh = () => {
  setTimeout(() => {
    router.go(0);
  }, 500);
};

requestRecord();

onMounted(async () => {
  RecordButton.addListener("toggleRecord", (event: any) => {
    console.log("event", event);
    toggleRecord(
        {
          nameUser: NOTIFICATION_TYPE.LOCATION,
          durationAudio: 2,
          mode: "all",
          userId: user.value.id,
        },
        {
          button: true,
        }
    );
  });
  RecordButton.addListener("buttonPressed", () => {
    toggleRecord({
      nameUser: NOTIFICATION_TYPE.LOCATION,
      durationAudio: 2,
      mode: "all",
      userId: user.value.id,
    });
  });

  await initPushNotifications();
});

onBeforeUnmount(() => {
  RecordButton.removeAllListeners();
});
</script>

<style scoped lang="scss">
@import "../theme/mixins_responsive";

.admin__panel {
  display: flex;
}

.admin__navigation__drawer {
  display: none;
  height: 100vh;
  @include laptop {
    display: initial;
    min-width: 240px;
    max-width: 240px;
  }

  .admin__items {
    @include laptop {
      min-width: 240px;
      max-width: 240px;
    }
    background-color: #f8f9fc;
    height: calc(100vh - 72px);
    padding-top: 5px;

    div {
      :hover {
        background-color: rgba(143, 163, 203, 0.3);
      }

      padding: 10px;

      a {
        text-decoration: none;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 400;
        color: var(--ion-color-neutral-60);
        cursor: pointer;

        span {
          padding-left: 5px;
        }

        :hover {
          background-color: transparent;
        }

        &.active {
          background-color: rgba(182, 203, 251, 0.3);
          color: var(--ion-color-primary-60);
          font-weight: 600;
        }
      }
    }

    hr {
      background: rgba(199, 204, 214, 0.5);
    }
  }
}

.admin__content {
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 100vw;
  @include laptop {
    width: calc(100vw - 240px);
  }
}

.admin__user__item {
  display: flex;
  align-items: center;
  height: 72px;
  background-color: var(--ion-color-neutral-90);
  position: relative;

  ion-item {
    --background: var(--ion-color-neutral-90);
    --color: #fff;
    --border-color: var(--ion-color-neutral-90);

    ion-label {
      display: flex;
      flex-direction: column;

      .user__name {
        font-weight: 600;
        font-size: 16px;
      }

      .user__role {
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
}
</style>
