<template>
  <ion-card class="plan-wrapper" :class="{'selected': plan?.best_plan}">
    <ion-card-content class="plan-wrapper__content">
        <div class="plan-wrapper__best" v-if="plan?.best_plan">
          <div class="plan-wrapper__best__rounded" >
            <ion-icon class="plan-wrapper__best__icon" :icon="flashOutline"></ion-icon>
            <p>{{ $t('register.theMostOutstanding') }}</p>
          </div>
        </div>
        <div class="plan-wrapper__header">
          <div class="plan-wrapper__price">
            <span class="plan-wrapper__price__amount">{{ plan.price == 'free' ? '0' : plan.price }}€</span>/{{ $t('global.month') }}
          </div>
          <h2 class="plan-wrapper__title">{{ plan.name }}</h2>
          <p class="plan-wrapper__include caption">{{ $t('register.planInclude') }}</p>
        </div>
        <section class="plan-wrapper__options">
          <div class="plan-wrapper__options__option">
            <div class="plan-wrapper__option__icon">
              <VsxIcon
                  iconName="Shop"
                  type="linear"/>
            </div>
            <p v-if="plan?.location_max == 'unlimited'">{{ $t('register.planLocalUnlimited') }}</p>
            <p v-else>{{ $tc('register.planLocals', parseInt(plan!.location_max), {local: plan?.location_max}) }}</p>
          </div>
          <div class="plan-wrapper__options__option">
            <div class="plan-wrapper__option__icon">
              <VsxIcon
                  iconName="People"
                  type="linear"/>
            </div>
            <p v-if="plan?.user_max == 'unlimited'">{{ $t('register.planUserUnlimited') }}</p>
            <p v-else>{{ $t('register.planUsers', {user: plan?.user_max}) }}</p>
          </div>          
          <div class="plan-wrapper__options__option">
            <div class="plan-wrapper__option__icon">
              <VsxIcon
                  iconName="ProfileAdd"
                  type="linear"/>
            </div>
              <p>{{ plan?.price_per_user }}€/{{ $t('register.planCostPerUser') }}</p>
          </div>
          <div class="plan-wrapper__options__option">
            <div class="plan-wrapper__option__icon">
              <VsxIcon
                  iconName="Microphone2"
                  type="linear"/>
            </div>
            <p v-if="plan?.audio_life_time == 'unlimited'">{{ $t('register.planMessageUnlimited') }}</p>
            <p v-else>{{ $t('register.planMessages', {message: plan?.audio_life_time}) }}</p>
          </div>
          <div class="plan-wrapper__options__option">
            <div class="plan-wrapper__option__icon">
              <VsxIcon
                  iconName="TickCircle"
                  type="linear"/>
            </div>
            <p v-if="plan?.task_max == 'unlimited'">{{ $t('register.planTaskBoardUnlimited') }}</p>
            <p v-else>{{ $t('register.planTaskBoard', {taskBoard: plan?.task_max}) }}</p>
          </div>
        </section>
      <ion-button
          :disabled="planDisabled"
          mode="ios"
          expand="block"
          class="button--select ion-margin-top"
          type="button"
          @click="selectPlan(plan?.id ?? PLAN_TYPE.FREE)"
      >{{ $t('actions.select') }}
      </ion-button>
    </ion-card-content>
  </ion-card>

</template>

<script setup lang="ts">
import {IonButton, IonCard, IonCardContent, IonIcon} from '@ionic/vue';
import { VsxIcon } from "vue-iconsax";
import {flashOutline} from 'ionicons/icons';
import {PropType} from "vue";
import {IPlan} from "@/interfaces/plan/IPlan";
import {CoreStore, useCoreStore} from "@/stores/core";
import {PLAN_TYPE} from "@/utils/constants";

const props = defineProps({
  plan: Object as PropType<IPlan>,
  planDisabled: {type: Boolean, default: false}
})

const coreStore: CoreStore = useCoreStore()
const emit = defineEmits(['selected']);

const selectPlan = (plan: number) => {
  
  coreStore.setPlanType(plan)
  emit("selected", props.plan)
}

</script>

<style scoped lang="scss">

ion-icon {
  font-size: 20px;
}

.plan-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  min-width: 246px;
  &.selected {
    border: 2px solid #64CDE1;
  }

  &__best {

    &__icon {
      width: 15px;
      height: 16px;
    }

    &__rounded {
      margin-bottom: 0;
      padding: 6px 14px;
      border-radius: 50px;
      background: #DEE6F7;
      color: #1C4197;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        text-transform: uppercase;
      }
    }
  }

  &__header{
    flex: 1;
  }

  &__price {
    &__amount {
      font-weight: bold;
      color: #464C59;
    }
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--ion-color-primary-500);
  }

  &__content {
    padding: 24px;
    width: 100%;
    text-align: left;
  }
  &__option__icon{
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    svg {
      height: 100%;
    }
  }

  &__options {
    margin-top: 30px;
    flex: 1;
    font: {
      size: 12px;
    }
    &__option {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;    
      p {
        padding-left: 10px;
      }
    }
  }

  &__title {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font: {
      weight: 600;
      size: 30px;
    }
    line-height: 35px;
    color: #2E333D
  }

  &__include {
    margin-top: 10px;
  }

}
ion-card{
  --background: #F8F9FC;
  border-radius: 16px;
  max-width: 230px;

  ion-card-content {
    display: flex;
    flex-direction: column;
  }
}

.button--select {
  margin-top: 10px;
}

</style>
