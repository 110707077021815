<template>
  <ion-card class="ion-margin" @click="showDetail = true">
    <ion-item lines="none">
      <ion-avatar
          slot="start"
          class="ion-margin-vertical"
          v-if="task.assigned_user_id"
      >
        <img alt="Wehop User" :src="image"/>
      </ion-avatar>
      <div class="item-task">
        <p>
          {{ task.title }}
        </p>
        <div class="item-footer">
          <div class="item-icons">
            <VsxIcon
                iconName="Flash"
                class="icon-flash"
                v-if="task.is_priority"
            ></VsxIcon>
            <div class="ion-flex ion-align-items-center">
              <VsxIcon iconName="Message" class="icon-message"></VsxIcon>
              <span class="number-comments">
                {{ task.comments.length }}
              </span>
            </div>
          </div>
          <div class="item-timeago">
            <timeago
                v-if="date"
                :datetime="date"
                :locale="es"
                :converter-options="{
                includeSeconds: true,
                addSuffix: false,
                useStrict: false,
              }"
                auto-update
            />
          </div>
        </div>
      </div>
    </ion-item>
  </ion-card>
  <modal-component v-model="showDetail" noIconClose>
    <ModalDetailTask
        :task="task"
        @click:back="showDetail = false"
        @update:tasks="handleUpdateTask"
    />
  </modal-component>
</template>
<script lang="ts" setup>
import {ref, onMounted, watch} from "vue";
import { VsxIcon } from "vue-iconsax";
import type {Ref} from "vue";
import {Task} from "@/interfaces/tasks/ITasks";
import {IonCard, IonItem, IonAvatar} from "@ionic/vue";
import {es} from "date-fns/locale";
import {handleReadModel} from "@/services/generic";
import {getImageLetter} from "@/utils/getImageLetter";
import {useRegister} from "@/composables/useRegister";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import ModalDetailTask from "@/components/admin/tasks/ModalDetailTask.vue";
import moment from "moment";
import useCore from "@/composables/useCore";

const {showTask, taskId} = useCore();

interface Props {
  task: Task;
}

const {getPhoto} = useRegister();
const date: Ref<string | null> = ref(null);
const image: Ref<string | undefined> = ref(undefined);
const showDetail: Ref<boolean> = ref(false);
const props = defineProps<Props>();
const emit = defineEmits(["update:tasks"]);

const getInfoUser = async () => {
  if (props.task.assigned_user_id) {
    const payload = {
      id: props.task.assigned_user_id,
      model: "user",
    };
    const {id, name, image_id} = await handleReadModel(payload);
    if (image_id) {
      const data = await getPhoto(id, image_id, true);
      if (data.url) {
        image.value = data.url;
      } else {
        image.value = getImageLetter(name);
      }
    } else {
      image.value = getImageLetter(name);
    }
  }
};

const handleUpdateTask = () => {
  showDetail.value = false;
  emit("update:tasks");
};

onMounted(() => {
  if (props.task.date_start) {
    const timezoneOffset = moment(props.task.date_start).utc(false);
    date.value = timezoneOffset.format();
  }
  getInfoUser();
});

watch(props.task, () => {
  getInfoUser();
});

watch(showTask, () => {
  if (showTask.value && props.task.id == taskId.value) {
    showDetail.value
  }
});
</script>
<style scoped lang="scss">
.icon-notification {
  background-color: #f9e8e7;
  color: #cf2617;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 60%;
  }
}

p {
  margin: 0px;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #5e6573;
}

ion-card {
  box-shadow: none;

  ion-item {
    --background: #f8f9fc;
  }
}

.item-task {
  width: 100%;
  padding-top: 5px;
  display: flex;
  flex-direction: column;

  .item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-icons {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      color: #787f8c;

      svg {
        width: 20px;
      }

      .icon-flash {
        color: #1c4197;
      }
    }

    .item-timeago {
      timeago {
        font-size: 12px;
        color: #9499a4;
      }
    }
  }
}

.number-comments {
  font-size: 12px;
  padding-left: 5px;
}
</style>
