<template>
  <RegisterWrapper
    :total-steps="4"
    :show-progress="false"
    :title-button-back="$t('register.signUp')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <h2 class="business-wrapper__title">{{ $t("register.businessInfo") }}</h2>
      <p class="business-wrapper__subtitle text-body-s">
        {{ $t("register.businessInfoDescription") }}
      </p>
      <FormComponent
        :model="model"
        :schema="schemaRegisterEntity"
        no-cancel-button
        @success="businessInfoSubmit"
        prevent-default
      >
        <template #submitButton>
          <ion-button
            mode="ios"
            expand="block"
            class="button--login ion-margin-top"
            type="submit"
            >{{ $t("actions.continue") }}
          </ion-button>
        </template>
      </FormComponent>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton } from "@ionic/vue";
import { ref, Ref } from "vue";
import { schemaRegisterEntity } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";

const {
  name,
  commercial_name,
  address,
  vat,
  city_id,
  country_id,
  saveEntityInfo,
} = useRegister();

const { isPlatformIOS } = usePlatform();

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.businessName"),
      type: "text",
      icon: "Shop",
    },
    {
      name: "commercial_name",
      label: i18n.global.t("register.companyName"),
      type: "text",
      icon: "Edit",
    },
    {
      name: "vat",
      label: "CIF / NIF",
      type: "text",
      icon: "ClipboardText",
    },
    {
      name: "address",
      label: i18n.global.t("register.address"),
      type: "text",
      icon: "Location",
    },
    {
      icon: "Send2",
      name: "country_id",
      type: "autocomplete",
      label: i18n.global.t("register.country"),
      model: "country",
      field_search: "iso_code",
    },
    {
      icon: "Location",
      name: "city_id",
      type: "autocomplete",
      label: i18n.global.t("register.city"),
      model: "city",
      depends_on: "country",
      foreign_key: "iso_code",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  name.value = values.name;
  commercial_name!.value = values.commercial_name;
  address!.value = values.address;
  city_id!.value = values.city_id;
  country_id!.value = values.country_id;
  vat!.value = values.vat;
  await saveEntityInfo();
};
</script>
<style scoped lang="scss">
.business-wrapper {
  &__title {
    margin-top: 60px;
  }
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
