<template>
  <ion-page>
    <ion-content>
      <div class="circle--login">
        <img :src="wehopLogoCircle" alt="" />
      </div>
      <div class="ion-padding ion-margin-top">
        <FormComponent
          ref="form"
          :model="model"
          :schema="schemaLogin"
          @success="doLogin"
          no-cancel-button
          prevent-default
        >
          <template #options>
            <router-link to="/forgot-password" class="link margin-top-20"
              >{{ $t("login.forgotPassword") }}
            </router-link>
            <ion-button fill="clear" expand="block" router-link="">
            </ion-button>
          </template>
          <template #submitButton>
            <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="submit"
              >{{ $t("actions.logIn") }}
            </ion-button>
          </template>
        </FormComponent>
        <div class="ion-flex ion-justify-content-center ion-padding">
          <ion-text color="neutral-60">
            {{ $t("login.iDontHave") }}
          </ion-text>
          <router-link to="/register" class="link register-link"
            >{{ $t("login.register") }}
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import { IonPage, IonContent, IonButton, IonText } from "@ionic/vue";
import { schemaLogin } from "@/utils/getMapModel";
import wehopLogoCircle from "@/assets/wehop-logo-circle.svg";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useAuth } from "@/composables/useAuth";
import "cordova-plugin-purchase";
import FormComponent from "@/components/shared/form/FormComponent.vue";

const { phone, password, login } = useAuth();
const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "login",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("login.phone"),
      type: "tel",
      icon: "Call",
      default: localStorage.getItem("phone_number"),
    },
    {
      name: "password",
      label: i18n.global.t("login.password"),
      type: "password",
      icon: "Key",
    },
  ],
});

const doLogin = (values: any) => {
  const phoneNumber = values.phone.split("|");
  phone.value = phoneNumber[1];
  password.value = values.password;
  login().then(() => {
    form.value.handleResetForm();
  });
};
</script>
<style scoped lang="scss">
.register-link {
  padding-left: 5px;
}

.circle--login {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.circle--login > h2 {
  margin-top: 0;
  color: #464c59;
}

.button--login {
  width: 100%;
}

.register--login {
  text-decoration: underline;
  font-weight: 600;
  padding-left: 5px;
}
</style>
