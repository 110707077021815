<template>
  <div>
    <div v-if="recording || listening">
      <div v-if="userRecording == user.id">
        <div
          class="circle--record"
          :class="{recordingClass, 'ios': isPlatformIOS}"
          @click="handleRecordAudio"
        >
          <div class="fab-record">
            <VsxIcon :iconName="recordingIcons"></VsxIcon>
          </div>
        </div>
      </div>
      <div v-else>
        <div  class="circle--record" :class="{'ios': isPlatformIOS}" ref="tour5_3">
          <div class="fab-record fab-disabled fab-no-recording">
            <VsxIcon iconName="MicrophoneSlash"></VsxIcon>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        ref="tour5_2"
        class="circle--record"
        :class="{recordingClass, 'ios': isPlatformIOS}"
        @click="handleRecordAudio"
      >
        <div class="fab-record">
          <VsxIcon :iconName="recordingIcons"></VsxIcon>
        </div>
      </div>
    </div>
    <div class="bar--record" :class="{'ios': isPlatformIOS}" v-if="showAllOptions">
      <div class="tabs">
        <div class="tab tab--left">
          <div @click="handleMenuResend">
            <VsxIcon iconName="Home2" class="color-icon"></VsxIcon>
          </div>
        </div>
        <div class="tab tab--fab"></div>
        <div class="tab tab--right">
          <router-link :to="{ name: 'default.tasks.list' }">
            <VsxIcon iconName="ClipboardText" class="color-icon"></VsxIcon>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import { VsxIcon } from "vue-iconsax";
import { ref, computed, onMounted } from "vue";
import type { Ref } from "vue";
import useRecord from "@/composables/useRecord";
import { useRegister } from "@/composables/useRegister";
import { useShepherd } from "vue-shepherd";
import i18n from "@/plugins/i18n";
import { TourStore, useTourStore } from "@/stores/tour";
import { handlePutModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { usePlatform } from "@/composables/usePlatform";

defineProps({
  showAllOptions: {
    type: Boolean,
    default: true,
  },
});

const tourStore: TourStore = useTourStore();
const registerStore: RegisterStore = useRegisterStore();

const { recording, listening, userId, toggleRecord } = useRecord();
const { isPlatformIOS } = usePlatform();
const { user, handleGetMe } = useRegister();

const router = useRouter();

const disabledRecord: Ref<boolean> = ref(false);

const recordingClass = computed(() => {
  if (disabledRecord.value) return "fab-disabled";
  return recording.value ? "fab-recording" : "fab-no-recording";
});

const recordingIcons = computed(() => {
  if (disabledRecord.value) return "MicrophoneSlash";
  return recording.value ? "Sound" : "Microphone2";
});

const userRecording = computed(() => {
  return userId.value;
});

const tour5_2 = ref(null);
const tour5_3 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "end" },
  },
});

const handleRecordAudio = () => {
  toggleRecord({
    nameUser: "Todos",
    durationAudio: 2,
    mode: "all",
    userId: user.value.id,
  });
};

onMounted(() => {
  if (!user.value.is_talkie_body) {
    tour.addStep({
      attachTo: {element: tour5_2.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 1});
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_2.title"),
      text: i18n.global.t("tour.tour5_2.text"),
    });
    tour.addStep({
      attachTo: {element: tour5_2.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 2});
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_3.title"),
      text: i18n.global.t("tour.tour5_3.text"),
    });
    tour.addStep({
      attachTo: {element: tour5_3.value, on: "top-center"},
      buttons: [
        {
          text: i18n.global.t("actions.accept"),
          action() {
            tourStore.setTalkieBodyTour({show: true, step: 3});
            handlePutModel({
              model: MODEL.USER,
              id: registerStore.user.id,
              fields: {
                is_talkie_body: true,
              },
            }).then(async () => {
              await handleGetMe();
            });
            tour.next();
          },
        },
      ],
      title: i18n.global.t("tour.tour5_4.title"),
      text: i18n.global.t("tour.tour5_4.text"),
    });
    tour.start();
  }
});

const handleMenuResend = () => {
  if (user.value.role === 'owner') {
    router.push({ name: 'admin.home' })
  } else {
    router.push({ name: 'employee.home' })
  }  
};
</script>
<style scoped lang="scss">
.color-icon {
  color: #787f8c;
}

.bar--record {
  border-radius: 10px;
  margin: 0 20px;
  position: absolute;
  right: 0;
  bottom: 30px;
  left: 0;
  height: 56px;
  background: transparent;
  overflow: hidden;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.3);
  display: flex;
  &.ios {
    bottom: 70px;
  }

  .tabs {
    color: #787f8c;
    display: flex;
    width: 100%;
    justify-content: space-around;
    z-index: 1;
    align-items: center;
  }
}

.bar--record::before {
  content: "";
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1000%;
  width: 95px;
  height: 78px;
  box-shadow: 0px 0px 0px 5000px #fff;
}

.circle--record {
  display: flex;
  background-color: #bface2;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: auto;
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  margin: 0 auto;

  &.ios {
    bottom: 100px;
  }

  .fab-record {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    font-weight: bold;
    color: #fff;
    position: relative;
    justify-content: center;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}

.fab-no-recording {
  background-color: #cf2617;
}

.fab-disabled {
  background-color: #c7ccd6;
  color: #9499a4;
}

.fab-recording {
  outline: 3px solid red;
  outline-radius: 0;
  outline-offset: 3px;
  height: 80px;
  width: 80px;
  bottom: 65px;

  .fab-record {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #73150d;
  }
}
</style>
