<template>
  <RegisterWrapper
    :show-progress="false"
    :title-button-back="$t('register.createProfile')"
    :class="isPlatformIOS ? 'safe-area' : ''"
  >
    <template #step>
      <div ref="tour1_1" >
        <FormComponent
          class="form-padding"
          ref="form"
          :model="model"
          :schema="schemaRegisterOwner"
          no-cancel-button
          @success="passwordSubmit"
          prevent-default
        >
          <template #checkbox>
            <ion-label class="ion-text-wrap">
              {{ $t("register.iAccept") }}
              <router-link
                to="/register/policy-privacy"
                class="link margin-top-20"
                >{{ $t("register.thePrivacyPolicy") }}
              </router-link>
              {{ $t("register.and") }}
              <router-link to="/register/terms" class="link margin-top-20"
                >{{ $t("register.conditions") }}
              </router-link>
            </ion-label>
          </template>
          <template #submitButton>
            <div style="width: 100%; padding: 0px;margin: 0px;">
              <ion-button
                id="register-button"
                mode="ios"
                expand="block"
                class="button--login ion-margin-top onBoarding"
                type="submit"
                >{{ $t("actions.continue") }}
              </ion-button>
            </div>
          </template>
        </FormComponent>
      </div>
    </template>
  </RegisterWrapper>
</template>
<script setup lang="ts">
import { IonButton, IonLabel } from "@ionic/vue";
import { onMounted, ref, Ref } from "vue";
import { schemaRegisterOwner } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import RegisterWrapper from "@/views/register/RegisterWrapper.vue";
import { useRegister } from "@/composables/useRegister";
import i18n from "@/plugins/i18n";
import { usePlatform } from "@/composables/usePlatform";
import "shepherd.js/dist/css/shepherd.css";

const { phone, phonePrefix, name, email, birthday, last_name, saveOwnerInfo } =
  useRegister();
const { isPlatformIOS } = usePlatform();
import { useShepherd } from "vue-shepherd";

const tour1_1 = ref(null);

const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: { behavior: "smooth", block: "center" },
  },
});

const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "register",
  fields: [
    {
      name: "phone",
      label: i18n.global.t("register.phone"),
      type: "tel",
      icon: "call",
    },
    {
      name: "name",
      label: i18n.global.t("register.fullName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t("register.lastName"),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "email",
      icon: "sms",
    },
    {
      name: "birthday",
      label: i18n.global.t("register.birthday"),
      type: "date",
      icon: "calendar",
    },
    {
      name: "accept",
      label: i18n.global.t("register.privacyPolicyMessage"),
      type: "checkbox",
    },
  ],
});

const passwordSubmit = async (values: any) => {
  const phoneNumber = values.phone.split("|");

  phonePrefix.value = phoneNumber[0];
  phone.value = phoneNumber[1];
  name.value = values.name;
  last_name.value = values.last_name;
  email.value = values.email;
  birthday.value = values.birthday;
  await saveOwnerInfo().then(() => {
    form.value.handleResetForm();
  });
};

onMounted(() => {
  tour.addStep({
    attachTo: { element: tour1_1.value, on: "bottom" },
    title: i18n.global.t("tour.tour1_1.title"),
    text: i18n.global.t("tour.tour1_1.text"),
    buttons: [
      {
        text: "Aceptar",
        action: tour.next,
      },
    ],
  });

  tour.start();
});
</script>
<style scoped lang="scss">
.form-padding {
  padding: 60px 0px 0px 0px;
}

.button--login {
  width: 100%;
}

.safe-area {
  padding-top: 10px;
}
</style>
