<template>
  <ion-avatar
    v-if="user"
    class="avatar-wrapper"
    :style="`width:${width}px;height:${height}px`"
  >
    <img :src="photo" :alt="user.name" />
  </ion-avatar>
</template>

<script setup lang="ts">
import { IonAvatar } from "@ionic/vue";
import defaultPhoto from "@/assets/avatar_default.svg";
import defaultPhotoAdd from "@/assets/default_photo.svg";
import { onMounted, ref, watch } from "vue";
import { useRegister } from "@/composables/useRegister";
import { RegisterStore, useRegisterStore } from "@/stores/register";

const props = defineProps({
  width: {
    type: Number,
    default: 45,
  },
  height: {
    type: Number,
    default: 45,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
});

const photo = ref<string | null>(props.isNew ? defaultPhotoAdd : defaultPhoto);
const registerStore: RegisterStore = useRegisterStore();
const { getPhoto, user, userPhoto } = useRegister();

watch(userPhoto, () => {
  if (userPhoto.value) {
    photo.value =
      registerStore.userPhoto !== "" ? registerStore.userPhoto : defaultPhoto;
  }
});

onMounted(async () => {
  if (user.value.image_id == null) {
    photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
  } else {
    //Logged User
    if (registerStore.userPhoto == "") {
      const data = await getPhoto(user.value.id, user.value.image_id, true);
      if (data.url) {
        registerStore.setUserPhoto(data.url);
        photo.value = data.url;
      } else {
        photo.value = props.isNew ? defaultPhotoAdd : defaultPhoto;
      }
    } else {
      photo.value =
        registerStore.userPhoto !== "" ? registerStore.userPhoto : defaultPhoto;
    }
  }
});
</script>

<style scoped lang="scss">
.avatar-wrapper {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}
</style>
