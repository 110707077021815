<template>
  <div id="application-pending" :class="isPlatformIOS ? 'safe-area' : ''">
    <ion-col size="12" :class="{ 'ion-padding-top ion-no-padding': isMobile }">
      <ListWrapper :model="model.name" :headers="headers">
        <template #item_mobile="props">
          <div
            class="ion-flex ion-justify-content-between ion-align-items-center"
          >
            <RequestWrapper @click:update="refresh" :item="props" />
          </div>
        </template>
      </ListWrapper>
    </ion-col>
  </div>
</template>

<script setup lang="ts">
import { IonCol } from "@ionic/vue";
import ListWrapper from "@/components/shared/list/ListWrapper.vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { ref, Ref, watch } from "vue";
import { APPLICATION_STATUS, MODEL } from "@/utils/constants";
import i18n from "@/plugins/i18n";
import { useGenericStore } from "@/stores/generic";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import RequestWrapper from "@/components/admin/group/RequestWrapper.vue";
import { useGeneric } from "@/composables/useGeneric";
import useCore from "@/composables/useCore";
import {usePlatform} from "@/composables/usePlatform";
const {isPlatformIOS} = usePlatform()

const { isMobile } = useNativePlatform();
const storeList = useGenericStore();
const registerStore: RegisterStore = useRegisterStore();

const headers = [
  {
    label: "Id",
    name: "id",
  },
  {
    label: i18n.global.t("joinTeam.userId"),
    name: "user_id",
  },
  {
    label: i18n.global.t("joinTeam.code"),
    name: "location_code",
  },
  {
    label: i18n.global.t("joinTeam.status"),
    name: "status",
  },
  {
    label: "",
    name: "actions",
  },
];

const model: Ref<any> = ref({
  name: MODEL.APPLICATION,
  fields: [],
});

const { refreshRequest } = useCore();

storeList.setItemsModel(MODEL.APPLICATION, []);
storeList.setFiltersModel(MODEL.APPLICATION, [
  {
    field: "location_code",
    op: "==",
    value: registerStore.location.code,
  },
  {
    field: "status",
    op: "==",
    value: APPLICATION_STATUS.WAITING,
  },
]);

const refresh = (item: boolean) => {
  storeList.setItemsModel(MODEL.APPLICATION, []);
  storeList.setFiltersModel(MODEL.APPLICATION, [
    {
      field: "location_code",
      op: "==",
      value: registerStore.location.code,
    },
    {
      field: "status",
      op: "==",
      value: APPLICATION_STATUS.WAITING,
    },
  ]);
  storeList.setItemsModel(MODEL.APPLICATION, []);
};

watch(refreshRequest, async () => {
  try {
    refresh(true);
  } catch (e) {
    console.error(e);
  }
});
</script>

<style scoped>

.safe-area {
  padding-top: 20px;
}

#application-pending {
  margin-bottom: 5px;
}
</style>
