<template>
  <div class="photo-edit-wrapper">
    <AvatarMemberComponent v-if="coreStore.selectedUser" :width=120 :height=120 is-new  />
    <AvatarUserComponent v-else :width=120 :height=120 is-new  />
    <div class="photo-edit-wrapper__icon" @click="uploadPhoto">
      <router-link :to="`/register/upload-photo?return=${goBack}`">
        <VsxIcon iconName="Camera"></VsxIcon>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonAvatar } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import AvatarUserComponent from "@/components/shared/avatar/AvatarUserComponent.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import AvatarMemberComponent from "@/components/shared/avatar/AvatarMemberComponent.vue";
import {RegisterStore, useRegisterStore} from "@/stores/register";

const coreStore: CoreStore = useCoreStore();
const registerStore: RegisterStore = useRegisterStore();

const props = defineProps({
  goBack: {
    type: Boolean,
    default: false,
  },
});

const uploadPhoto = () => {
  registerStore.setUserSelectedPhoto("");
};
</script>

<style scoped lang="scss">
ion-avatar {
  width: 120px;
  height: 120px;
}

.photo-edit-wrapper {
  position: relative;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    right: 0px;
    background: #1c4197;
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;

    a {
      color: #fff;
    }
  }
}
</style>
