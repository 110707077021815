<template>
  <ListComponentMobile
      v-if="isMobile"
      v-bind="$attrs"
      :items="data"
      :total="pagination?.total"
      :page="pagination?.page"
      :hide-headers="hideHeaders"
      @page="handleGetDataPage"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </ListComponentMobile>
  <ListComponentDesktop
      v-else
      @page="handleGetDataPage"
      v-bind="$attrs"
      :items="data"
      :headers="headers"
      :hide-headers="hideHeaders"
      :pagination="pagination"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
  </ListComponentDesktop>
</template>
<script setup lang="ts">
import {computed, reactive, watch} from "vue";
import {Item} from "@/interfaces/GenericModel";
import ListComponentDesktop from "@/components/shared/list/ListComponentDesktop.vue";
import ListComponentMobile from "@/components/shared/list/ListComponentMobile.vue";
import {useList} from "@/composables/useList";
import {useNativePlatform} from "@/composables/useNativePlatform";
import {useGeneric} from "@/composables/useGeneric";

interface Props {
  headers: Array<Item>;
  hideHeaders?: Array<string> | [] | undefined;
  model: string;
  filters?: Array<Item>;
}

const props = defineProps<Props>();
const {generic, getModelData} = useList();
const {refresh} = useGeneric();
const {isMobile} = useNativePlatform();
const {setRefresh} = useGeneric();

const defaultPagination = reactive({
  page: 1,
  total: 1,
  size: 10,
});

const pagination = computed(() => {
  const pagination = generic.value[`pagination_${props.model}`];
  return pagination || defaultPagination;
});

const data = computed(() => {
  return generic.value[`items_${props.model}`] || [];
});

getModelData({headers: props.headers, modelName: props.model});

const handleGetDataPage = (payload: any) => {
  const {page} = payload || {};
  getModelData({headers: props.headers, modelName: props.model, page});
};


watch(refresh, () => {
  getModelData({headers: props.headers, modelName: props.model});
});


</script>
