<template>
  <ion-app>
    <ion-router-outlet />
    <ModalLogoutComponent />
    <ModalLoadingComponent v-if="isMobile" />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import ModalLogoutComponent from "./components/shared/ModalLogoutComponent.vue";
import ModalLoadingComponent from "./components/shared/modal/ModalLoadingComponent.vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import {enableBackgroundMode} from "@/services/background_services";
const { isMobile } = useNativePlatform();

enableBackgroundMode();

</script>
