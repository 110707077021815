<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <div class="join-wrapper__back">
        <ion-back-button
          :text="$t('joinTeam.joinATeam')"
          color="neutral-80"
          defaultHref="/join"
        ></ion-back-button>
      </div>
      <section class="join-wrapper__body">
        <img :src="joinValidate" alt="" />
        <h3 class="join-wrapper__title">{{ $t("joinTeam.youKnowCode") }}</h3>
        <p class="join-wrapper__description">
          {{ $t("joinTeam.youKnowCodeDescription") }}
        </p>
        <FormComponent
          ref="form"
          :model="model"
          :schema="schemaValidateCode"
          no-cancel-button
          @success="validateCode"
          prevent-default
        >
          <template #checkbox>
            <ion-label class="ion-text-wrap">
              {{ $t("register.iAccept") }}
              <router-link
                to="/register/policy-privacy"
                class="link margin-top-20"
                >{{ $t("register.thePrivacyPolicy") }}
              </router-link>
              {{ $t("register.and") }}
              <router-link to="/register/terms" class="link margin-top-20"
                >{{ $t("register.conditions") }}
              </router-link>
            </ion-label>
          </template>
          <template #submitButton>
            <ion-button
              mode="ios"
              expand="block"
              class="button-validate ion-margin-top"
              type="submit"
              fill="outline"
              >{{ $t("actions.validateCode") }}
            </ion-button>
          </template>
        </FormComponent>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonBackButton,
  IonButton,
  IonContent,
  IonLabel,
  IonPage,
} from "@ionic/vue";
import joinValidate from "@/assets/join-validate.svg";
import { schemaValidateCode } from "@/utils/getMapModel";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useJoin } from "@/composables/useJoin";

const { code, sendCode } = useJoin();
const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "validate",
  fields: [
    {
      name: "code",
      label: i18n.global.t("joinTeam.whiteCode"),
      type: "text",
    },
  ],
});

const validateCode = (values: any) => {
  code.value = values.code;
  sendCode().then(() => {
    form.value.handleResetForm();
  });
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.join-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }
}

.button-validate {
  width: 100%;
}
</style>
