<template>
  <ion-card class="plan-wrapper" v-if="plan">
    <ion-card-content class="plan-wrapper__content">
      <img :src="PlanSelected" alt=""/>
      <div class="plan-wrapper__content__body">
        <div class="plan-wrapper__content__body__wrapper">
          <div class="plan-wrapper__content__body__title">
            <img :src="plan.name == PLAN_TYPE_NAME.FREE ? PlanFree : PlanPro" alt=""/> <span></span>
          </div>
          <div class="plan-wrapper__price">
            <span class="plan-wrapper__price__amount">{{ plan.price }}€</span>/{{ $t('global.month') }}
          </div>
        </div>
        <ion-button
            mode="ios"
            fill="outline"
            class="button--select ion-margin-top"
            type="button"
            expand="block"
            @click="selectPlan">
          <ion-icon v-if="plan.name == PLAN_TYPE_NAME.FREE" :icon="arrowUp"></ion-icon>
          <ion-icon v-else :icon="arrowDown"></ion-icon>
          {{ plan.name == PLAN_TYPE_NAME.FREE ? $t('actions.improvePlan') : $t('actions.downgradePlan')}}
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</template>
<script setup lang="ts">
import {IonCard, IonCardContent, IonButton, IonIcon} from "@ionic/vue";
import {arrowUp, arrowDown} from "ionicons/icons";
import PlanFree from "@/assets/plan_free.svg";
import PlanPro from "@/assets/plan_pro.svg";
import PlanSelected from "@/assets/plan_selected.svg";
import router from "@/router";
import {MODEL, PLAN_TYPE_NAME} from "@/utils/constants";
import {handleSearchReadModel} from "@/services/generic";
import {IPlan} from "@/interfaces/plan/IPlan";
import {onMounted, ref, watch} from "vue";
import {useRegister} from "@/composables/useRegister";
import {ISubscription} from "@/interfaces/subscription/ISubscription";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";


const {user} = useRegister()
const {refreshPlan, setRefreshPlan} = useCore()

const plan = ref<IPlan>()

const selectPlan = async () => {

  if (plan.value?.name == PLAN_TYPE_NAME.FREE) {
    await router.replace({ name: "registerPlan" });
  } else {
    await router.replace({ name: "registerPlanDowngrade" });
  }
}


const getSubscriptionPlan = async () => {
  try {
    const payload = {
      model: MODEL.SUBSCRIPTION,
      fields: ['id', 'subscription_plan_id'],
    };
    const filter = [
      {
        field: 'user_id',
        op: "==",
        value: user.value.id,
      },
      {
        field: 'entity_id',
        op: "==",
        value: user.value.entity_id,
      },
    ];
    handleSearchReadModel(payload, filter).then((data) => {
      const subscription = data.items[0] as ISubscription;

      if (subscription) {
        const payload = {
          model: MODEL.SUBSCRIPTION_PLAN,
          fields: ['id', 'name', 'price', 'location_max', 'user_max', 'audio_life_time', 'task_max', 'best_plan'],
        };
        const filter = [
          {
            field: 'id',
            op: "==",
            value: subscription.subscription_plan_id,
          },
        ];
        handleSearchReadModel(payload, filter).then((data) => {
          plan.value = data.items[0] as IPlan;
        })
      }

    })
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  getSubscriptionPlan()
})

watch(refreshPlan, () => {
  if (refreshPlan.value) {
    getSubscriptionPlan();
    setRefreshPlan(false);
  }
});


</script>
<style scoped lang="scss">
.plan-wrapper {
  width: 100%;
  display: flex;
  justify-content: left;
  min-width: 100%;
  margin: 0px;

  &__content {
    padding: 15px;
    width: 100%;
    text-align: left;
    background-color: #fff;

    &__body {
      display: flex;
      align-items: center;

      &__wrapper {
        padding-right: 10px;
      }

      &__title {
        width: 100%;
        margin: 0px;
        padding: 0px;
        font: {
          weight: 500;
          size: 14px;
        }
        display: flex;
        align-items: center;
        color: linear-gradient(91.96deg, #64CDE1 -2.72%, #4178F5 121.42%);

        span {
          padding: 5px;
        }
      }
    }
  }

  &__price {
    &__amount {
      font-weight: bold;
      color: #464C59;
    }

    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 10px;
    color: var(--ion-color-primary-500);
  }
}
</style>
