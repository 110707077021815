<template>
  <ion-page>
    <ion-content class="ion-padding my-profile-wrapper">
      <div class="my-profile-wrapper__back">
        <ion-back-button
          :text="$t('myProfile.updateData')"
          color="neutral-80"
          defaultHref="/my/profile/owner"
        ></ion-back-button>
      </div>
      <section class="my-profile-wrapper__form">
        <FormComponent
          ref="form"
          :model="model"
          :schema="schemaUpdateEntity"
          form-mode="update"
          :id-model="registerStore.user.entity_id"
          no-cancel-button
          @success="businessInfoSubmit"
          prevent-default
        >
          <template #submitButton>
            <ion-button
              mode="ios"
              expand="block"
              class="button--login ion-margin-top"
              type="submit"
              >{{ $t("actions.update") }}
            </ion-button>
          </template>
        </FormComponent>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { schemaUpdateEntity } from "@/utils/getMapModel";
import { IonButton, IonBackButton, IonContent, IonPage } from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import { ref, Ref } from "vue";
import i18n from "@/plugins/i18n";
import { useRegister } from "@/composables/useRegister";

const registerStore: RegisterStore = useRegisterStore();
const { user } = useRegister();

const {
  name,
  commercial_name,
  address,
  vat,
  city_id,
  country_id,
  email,
  phone,
  number_employees,
  number_locals,
  updateEntityInfo,
} = useRegister();

const form: Ref<any> = ref(null);

const model: Ref<any> = ref({
  name: "entity",
  fields: [
    {
      name: "name",
      label: i18n.global.t("register.businessName"),
      type: "text",
      icon: "Shop",
    },
    {
      name: "commercial_name",
      label: i18n.global.t("register.companyName"),
      type: "text",
      icon: "Edit",
    },
    {
      name: "vat",
      label: "CIF / NIF",
      type: "text",
      icon: "ClipboardText",
    },
    {
      name: "address",
      label: i18n.global.t("register.address"),
      type: "text",
      icon: "Location",
    },
    {
      icon: "Send2",
      name: "country_id",
      type: "autocomplete",
      label: i18n.global.t("register.country"),
      model: "country",
      field_search: "iso_code",
    },
    {
      icon: "Location",
      name: "city_id",
      type: "autocomplete",
      label: i18n.global.t("register.city"),
      model: "city",
      depends_on: "country",
      foreign_key: "iso_code",
    },
    {
      name: "phone_number",
      label: i18n.global.t("register.phone"),
      type: "text",
      icon: "Call",
    },
    {
      name: "email",
      label: i18n.global.t("register.email"),
      type: "text",
      icon: "Sms",
    },
    {
      name: "number_locals",
      label: i18n.global.t("register.locals"),
      type: "text",
      icon: "Copy",
    },
    {
      name: "number_employees",
      label: i18n.global.t("register.employees"),
      type: "text",
      icon: "People",
    },
  ],
});

const businessInfoSubmit = async (values: any) => {
  console.log(values);
  name.value = values.name;
  commercial_name!.value = values.commercial_name ?? undefined;
  address!.value = values.address;
  city_id!.value = values.city_id;
  country_id!.value = values.country_id;
  vat!.value = values.vat;
  email.value = values.email;
  phone.value = values.phone_number;
  number_employees.value = values.number_employees;
  number_locals.value = values.number_locals;
  await updateEntityInfo(user.value.entity_id ?? "");
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.my-profile-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__form {
    margin-top: 20px;
  }

  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }
}

.button-validate {
  width: 100%;
}
</style>
