<template>
  <ion-page>
    <ion-content class="welcome-wrapper">
      <div class="welcome-wrapper__image">
        <img :src="welcomeBackground" alt="" />
      </div>
      <section class="welcome-wrapper__body">
        <div class="welcome-wrapper__logo">
          <img :src="wehopLogo" alt="" />
        </div>
        <h2 class="welcome-wrapper__title">{{ $t("welcome.title") }}</h2>
        <p class="welcome-wrapper__subtitle text-body-s">
          {{ $t("welcome.subtitle") }}
        </p>
        <section class="welcome-wrapper__actions">
          <ion-button
            class="welcome-wrapper__register"
            fill="outline"
            expand="block"
            router-link="/register"
            >{{ $t("welcome.register") }}
          </ion-button>
          <ion-button
            class="welcome-wrapper__login"
            expand="block"
            router-link="/auth/login"
            >{{ $t("welcome.login") }}
          </ion-button>
        </section>
      </section>
      <div
        class="caption app-version"
        v-if="appVersion"
        :class="isPlatformIOS ? 'version-ios' : 'version-android'"
      >
        v{{ appVersion }}
      </div>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import { IonButton, IonContent, IonPage } from "@ionic/vue";
import welcomeBackground from "@/assets/welcome_background.png";
import wehopLogo from "@/assets/wehop-logo.svg";
import { App } from "@capacitor/app";
import { onMounted, ref } from "vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { usePlatform } from "@/composables/usePlatform";

const { isMobile } = useNativePlatform();
const { isPlatformIOS } = usePlatform();

const appVersion = ref<string>();

onMounted(() => {
  if (isMobile) {
    App.getInfo().then((info) => {
      appVersion.value = isPlatformIOS
        ? info.version + "." + info.build
        : info.version;
    });
  } else {
    appVersion.value = "1.0.122";
  }
});
</script>
<style scoped lang="scss">
.version-ios {
  position: absolute;
  bottom: 20px;
  right: 25px;
}

.version-android {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.welcome-wrapper {
  &__body {
    padding: 20px;
  }

  &__image {
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: #5e6573 !important;
    margin-bottom: 10px;
  }

  h2 {
    color: var(--ion-color-neutral-90);
    margin-bottom: 10px;
  }

  &__register {
    margin-top: 60px;
  }

  &__login {
    margin-top: 15px;
  }
}
</style>
