vue
<template>
  <div class="dropdown" ref="dropdown">
    <div id="dropdown" @click="toggleOpen">
      <slot name="button"></slot>
    </div>
    <div
      :class="isMobile ? 'dropdown-menu-mobile' : 'dropdown-menu'"
      ref="menu"
      @click="onClickInside"
      v-show="isOpen"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useOnClickOutside } from "@/composables/useOnClickOutside";

import { ref } from "vue";
import type { Ref } from "vue";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";

const isOpen = ref(false);
const dropdown = ref(null);
const menu: Ref<any> = ref(null);

const { isMobile } = useNativePlatform();

const coreStore: CoreStore = useCoreStore();

const toggleOpen = () => {
  coreStore.setShowDropdown(true);
  if (coreStore.showDropdown) {
    isOpen.value = true;
  } else {
    isOpen.value = !isOpen.value;
  }
};

useOnClickOutside(dropdown, () => {
  if (!coreStore.showDropdown) {
    if (isOpen.value) {
      coreStore.setShowDropdown(false);
      isOpen.value = false;
    }
  }
});

const onClickInside = (e: Event) => {
  if (!coreStore.showDropdown) {
    if (menu.value.contains(e.target)) {
      coreStore.setShowDropdown(false);
      isOpen.value = false;
    }
  }
};
</script>

<style lang="scss">
.dropdown {
  position: relative;
}

.dropdown-button {
  display: block;
  padding: 0.5rem 1rem;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0%;
  left: initial;
  margin-top: 2rem;
  padding: 0;
  list-style: none;

  ol {
    li {
      box-shadow: 0px 4px 4px -3px rgba(28, 65, 151, 0.1),
        0px 8px 8px -3px rgba(28, 65, 151, 0.15);
      list-style-type: none;
      min-width: 200px;
    }
  }
}

.dropdown-menu-mobile {
  position: absolute;
  top: 0;
  right: 0%;
  left: -250px;
  margin-top: 0.5rem;
  padding: 0;
  list-style: none;

  ol {
    li {
      background-color: white;
      list-style-type: none;
      min-width: 200px;
    }

    li:last-child {
      box-shadow: 0px 4px 4px -3px rgba(28, 65, 151, 0.1),
        0px 8px 8px -3px rgba(28, 65, 151, 0.15);
    }
  }
}
</style>
