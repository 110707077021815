<template>
  <section class="my-profile-wrapper" v-if="user">
    <PhotoEditComponent
        :user-id="user.id"
        :image-id="user.image_id ?? -1"
        class="my-profile-wrapper__photo" go-back
    />
    <p class="caption">{{ user.prefix_number }}{{ user.phone_number }}</p>
    <section class="my-profile-wrapper__form">
      <ion-radio-group
        :value="role"
        style="margin-top: 20px"
        mode="md"
        @ionChange="getEmployee($event)"
      >
        <ion-item>
          <ion-label>{{ $t("joinTeam.employee") }}</ion-label>
          <ion-radio slot="end" :value="ROLE_ID.EMPLOYEE"></ion-radio>
        </ion-item>
        <ion-item>
          <ion-label>{{ $t("joinTeam.manager") }}</ion-label>
          <ion-radio slot="end" :value="ROLE_ID.MANAGER"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-button
        style="margin-top: 40px"
        mode="ios"
        expand="block"
        type="button"
        @click="confirm"
      >{{ $t("actions.save") }}
      </ion-button>
    </section>
  </section>
</template>

<script setup lang="ts">
import {RegisterStore, useRegisterStore} from "@/stores/register";
import PhotoEditComponent from "@/components/shared/PhotoEditComponent.vue";
import {schemaUpdateRegisterManager, schemaUpdateRegisterOwner} from "@/utils/getMapModel";
import { IonButton, IonItem, IonLabel, IonRadio, IonRadioGroup, RadioGroupCustomEvent, toastController } from "@ionic/vue";
import FormComponent from "@/components/shared/form/FormComponent.vue";
import {onMounted, ref, Ref} from "vue";
import i18n from "@/plugins/i18n";
import {useRegister} from "@/composables/useRegister";
import {CoreStore, useCoreStore} from "@/stores/core";
import {IUser} from "@/interfaces/login/ILoginResponse";
import { MODEL, ROLE_ID } from "@/utils/constants";
import { ILocation } from "@/interfaces/locale/ILocation";
import { IUserLocation } from "@/interfaces/user_location/IUserLocation";
import { handlePutModel, handleSearchReadModel } from "@/services/generic";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const {updateManagerInfo, name, email, last_name} = useRegister();

const role = ref<number>(ROLE_ID.EMPLOYEE);
const locations = ref<ILocation[]>([]);
const userLocation = ref<IUserLocation>();
import router from "@/router";


const model: Ref<any> = ref({
  name: "user",
  fields: [
    {
      name: "name",
      label: i18n.global.t('register.fullName'),
      type: "text",
      icon: "profile",
    },
    {
      name: "last_name",
      label: i18n.global.t('register.lastName'),
      type: "text",
      icon: "profile",
    },
    {
      name: "email",
      label: i18n.global.t('register.email'),
      type: "email",
      icon: "sms",
    },
  ],
});

const user = ref<IUser>()

const confirm = async () => {
  if (userLocation.value) {
    await handlePutModel({
      model: MODEL.USERLOCATION,
      id: userLocation.value.id,
      fields: {
        role_id: role.value,
      },
    }).then(async (_) => {
      const toast = await toastController.create({
        message: i18n.global.t("global.updateSuccess"),
        duration: 1500,
        position: "bottom",
        mode: "ios",
      });
      await toast.present();
      coreStore.setRefreshUserLocation(true);
      router.back();
    });
  }
};

const getEmployee = async (event: RadioGroupCustomEvent) => {
  role.value = event.target.value;
};

onMounted(async () => {
  if (coreStore.selectedUser) {
    const payload = {
      model: MODEL.USERLOCATION,
      fields: ["id", "user_id", "location_id", "role_id"],
    };
    const filter = [
      {
        field: "user_id",
        op: "==",
        value: coreStore.selectedUser.id,
      },
      {
        field: "location_id",
        op: "==",
        value: registerStore.location.id,
      },
    ];
    const data = await handleSearchReadModel(payload, filter);
    const userLocations = data.items as IUserLocation[];
    userLocation.value = userLocations[0];

    //Assign Role
    role.value = parseInt(userLocation.value?.role_id.toString());

  }

  registerStore.setUserSelectedPhoto("");
  if (coreStore.selectedUser != null) {
    user.value = coreStore.selectedUser;
  } else {
    user.value = registerStore.user;
  }
});

</script>

<style scoped lang="scss">

.my-profile-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__photo {
    margin-top: 40px;
  }

  &__form {
    margin-top: 20px;
    width: 100%;
  }
}

.button--login {
  margin-top: 60px;
  width: 100%;
}

</style>
