<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <ion-content class="ion-padding register-wrapper">
      <div class="register-wrapper__back">
        <ion-back-button text="Darse de alta" color="neutral-80" defaultHref="/auth/login"></ion-back-button>
      </div>
      <div class="register-wrapper__body">
        <div class="register-wrapper__logo">
          <img :src="wehopLogoCircle" alt="">
        </div>
        <h2 class="register-wrapper__title">{{ $t('register.whatDoYouWantToDo') }}</h2>
      </div>
      <ion-list lines="inset" class="register-wrapper__options">
        <ion-item>
          <RegisterOption
              :icon="storefrontOutline"
              title="Quiero crear mi equipo"
              description="Busco poder crear y gestionar mi equipo."
              to="registerStore"
              :user-type=USER_TYPES.OWNER
          >
          </RegisterOption>
        </ion-item>
        <ion-item>
          <RegisterOption
              :icon="peopleOutline"
              title="Quiero unirme a un equipo"
              description="Quiero formar parte de un equipo y compartir con sus miembros."
              to="registerEmployee"
              :user-type=USER_TYPES.EMPLOYEE
          >
          </RegisterOption>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script setup lang="ts">
import {IonContent, IonPage, IonBackButton, IonList, IonItem} from "@ionic/vue";
import {storefrontOutline, peopleOutline} from 'ionicons/icons';
import wehopLogoCircle from "@/assets/wehop-logo-circle.svg";
import RegisterOption from "@/components/RegisterOption.vue";
import {USER_TYPES} from "@/utils/constants";
import {usePlatform} from "@/composables/usePlatform";

const { isPlatformIOS } = usePlatform();

</script>
<style scoped lang="scss">

.register-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__logo {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__title {
    color: #464c59;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__options {
    margin-top: 20px;
    width: 100%;
    .item-native {
      margin-left: 0px;
    }
  }
}
.safe-area {
  padding-top: 15px;
}
</style>
