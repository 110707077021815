<template>
  <ion-header v-if="showSnackBar">
    <ion-toolbar :class="[recording ? 'toolbar-danger' : 'toolbar-success', isPlatformIOS ? 'toolbar-ios' : '']">
      <div class="container-toolbar">
        <div class="container-title">
          <VsxIcon :iconName="recording ? 'Sound' : 'Pause'"></VsxIcon>
          <span>{{ message }}</span>
        </div>
        <div class="container-time" v-if="recording">
          {{ minutes }}:{{ seconds < 10 ? "0" + seconds : seconds }}
        </div>
      </div>
    </ion-toolbar>
  </ion-header>
</template>
<script setup lang="ts">
import { watch, computed, onMounted } from "vue";
import { VsxIcon } from "vue-iconsax";
import { IonHeader, IonToolbar } from "@ionic/vue";
import useCountdown from "@/composables/useCountdown";
import useRecord from "@/composables/useRecord";
import { useAudio } from "@/composables/useAudio";
import { CoreStore, useCoreStore } from "@/stores/core";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import i18n from "@/plugins/i18n";
import {usePlatform} from "@/composables/usePlatform";

const { recording, listening, nameUser, userId, mode, showSnackBar } = useRecord();
const { notificationMode, notificationReceiptId } = useCore();
const { startTimer, startCountdown, minutes, seconds } = useCountdown();
const { verifySpeaking } = useAudio();
const coreStore: CoreStore = useCoreStore();
const { user } = useRegister();
const { isPlatformIOS, isPlatformAndroid } = usePlatform();

const message = computed(() => {
  return recording.value
    ? `${i18n.global.t("talkie.talkingTo")} ${nameUser.value} `
    : `${nameUser.value} ${i18n.global.t("talkie.isTalkingToYou")}`;
});


const isOnlyForUser = computed(() => {
  if (mode.value == NOTIFICATION_TYPE.USER && userId.value == user.value.id) {
    return true;
  } else {
    return false;
  }
});

watch(recording, () => {
  if (recording.value) {
    startTimer();
  }
});

onMounted(() => {
  verifySpeaking();
});



</script>
<style scoped lang="scss">
.toolbar-danger {
  background: #e4f5e0;
  --border-color: #cf2617;
  color: #cf2617;
}

.toolbar-success {
  background: #f9e8e6;
  --border-color: #367a29;
  color: #367a29;
}

ion-toolbar {
  padding: 6px 16px 6px 12px !important;
  position: absolute;
  --border-width: 0 0 2px !important;

  .container-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container-title {
      padding-left: 10px;
      display: flex;
      align-items: center;

      span {
        padding-left: 5px;
      }
    }
  }
}

.toolbar-ios {
  height: 80px !important;
  padding: 22px 16px 13px 12px !important;
}
</style>
