<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <ol>
      <li>
        <ion-item
          :button="true"
          :detail="false"
          class="options_actions"
          @click="emitClick('click:detail')"
        >
          <VsxIcon iconName="InfoCircle" color="gray" />
          <span class="ion-padding-start"> {{ $t('global.viewDetail') }} </span>
        </ion-item>
      </li>
        <li>
            <ion-item
                    :button="true"
                    :detail="false"
                    class="options_actions"
                    @click="emitClick('click:leave')"
            >
                <VsxIcon iconName="Logout" class="leave-icon" />
                <span class="ion-padding-start leave-text"> {{ $t('global.leave') }} </span>
            </ion-item>
        </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";

interface Props {
  item: any;
}
const props = defineProps<Props>();
const emit = defineEmits(["click:edit", "click:leave"]);

const emitClick = (event: any) => {
  emit(event, props.item);
};
</script>
<style scoped lang="scss">
.leave-icon {
  color: var(--ion-color-danger-50-shade) !important;
}
.leave-text {
  color: var(--ion-color-danger-50-shade) !important;
}
ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
