<template>
  <div class="photo-edit-wrapper">
    <AvatarCurrentLocaleComponent  :width=120 :height=120 is-new />
    <div class="photo-edit-wrapper__icon">
      <ion-button
        shape="circle"
        class="photo-edit-wrapper__icon__button"
        mode="ios"
        expand="block"
        type="button"
        id="open-modal"
        name="confirm"
        color="primary-90-shade"
      >
        <VsxIcon iconName="Camera"></VsxIcon>
      </ion-button>
      <ion-modal
        ref="modal"
        trigger="open-modal"
        :initial-breakpoint="1"
        :breakpoints="[0, 0.25, 0.5, 0.75]"
      >
        <LocaleUploadPhotoView @close="closeDialog" />
      </ion-modal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonAvatar, IonButton, IonModal } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { ref } from "vue";
import LocaleUploadPhotoView from "@/views/admin/location/LocaleUploadPhotoView.vue";
import AvatarCurrentLocaleComponent from "@/components/shared/avatar/AvatarCurrentLocaleComponent.vue";


const modal = ref();

const closeDialog = async (values: any) => {
  modal.value.$el.dismiss();
};
</script>

<style scoped lang="scss">
ion-button[shape="circle"] {
  --border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #1c4197;
}

ion-avatar {
  width: 120px;
  height: 120px;
}

.photo-edit-wrapper {
  width: 120px;
  height: 120px;
  position: relative;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    right: 0px;
    background: #1c4197;
    color: #fff;
    border-radius: 50%;
    width: 35px;
    height: 35px;

    &__button {
      border-radius: 50%;
      background: #1c4197;
    }

    a {
      color: #fff;
    }
  }
}
</style>
