<template>
  <div class="user-wrapper">
    <div class="user-wrapper__checkbox" :class="{isDeleted: 'disabled'}">
      <ion-checkbox :disabled="isDeleted" :checked="isChecked" @click="removeTeam"></ion-checkbox>
    </div>
    <div class="user-wrapper__name">{{ team.name }}</div>
  </div>
</template>
<script setup lang="ts">
import { PropType, ref } from "vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { CoreStore, useCoreStore } from "@/stores/core";
import { IonCheckbox, toastController } from "@ionic/vue";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handleDeleteModel, handlePostModel } from "@/services/generic";
import { MODEL } from "@/utils/constants";
import { getSubscriptionAvailability } from "@/services/subscription";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();

const props = defineProps({
  team: {
    type: Object as PropType<ILocation>,
    required: true,
  },
  locationId: {
    type: Number,
    required: true,
  },
});

const isChecked = ref<boolean>(props.team?.id != null);
const isDeleted = ref<boolean>(false);
const locationId = ref<number|undefined>(props.team?.id);

const removeTeam = async () => {
    ///Remove User (Only allow remove when team number is greater than 1)
  if (coreStore.subscriptionAvailability && coreStore.subscriptionAvailability?.teams_status?.number_teams > 1) {
    await handleDeleteModel({
      model: MODEL.LOCATION,
      id: locationId.value,
    }).then(async () => {
      isChecked.value = false;
      isDeleted.value = true;
      await getSubscriptionAvailability();
    });
  }
};
</script>
<style scoped lang="scss">
ion-checkbox {
  --size: 20px;
  --checkbox-background-checked: #6815ec;
}

.user-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__checkbox {
    width: 15%;
    margin-right: 20px;
  }

  &__name {
    width: 85%;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
}
.disabled {
  opacity: 0.5;
}
</style>
