<template>
  <ModalDeleteComponent
    v-if="modelValue"
    :name-item="name"
    :model-value="modelValue"
    @update:modelValue="handleCloseModal"
    @confirm:delete="handleClickDeleteModel"
  >
    <template #title>
      <h3>¿Estás seguro? Confirma la eliminación de tu equipo</h3>
    </template>
    <template #content>
      <div>
        Al eliminar el equipo
        <span class="text-bold">"{{ name }}"</span> perderás toda la información
        y los miembros asociados a este, quedarán desvinculados.
      </div>
      <div class="ion-padding-top">
        <span class="text-bold">
          Para confirmar el borrado debes escribir el nombre del equipo
        </span>
        tal y como está registrado
      </div>
    </template>
  </ModalDeleteComponent>
</template>
<script setup lang="ts">
import ModalDeleteComponent from "@/components/shared/modal/ModalDeleteComponent.vue";

interface Props {
  modelValue: boolean;
  name?: string;
}
defineProps<Props>();
const emit = defineEmits(["update:modelValue", "confirm:delete"]);

const handleCloseModal = (value: boolean) => {
  emit("update:modelValue", value);
};

const handleClickDeleteModel = () => {
  emit("confirm:delete");
};
</script>
