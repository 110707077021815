<template>
  <div>
    <template v-for="(item, index) of items" :key="index">
      <slot :name="`item_mobile`" v-bind="item">
        {{item[titleKey]}}
        {{titleKey}}
        <ion-item detail button>
          <ion-label>
            <h3>{{ item[titleKey] }}</h3>
            <p v-if="descriptionKey">{{ item[descriptionKey] }}</p>
          </ion-label>
        </ion-item>
      </slot>
    </template>
    <ion-infinite-scroll v-if="items.length < total" @ionInfinite="ionInfinite">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</template>
<script setup lang="ts">
import {
  IonItem,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { Item } from "@/interfaces/GenericModel";

interface Props {
  items: Array<Item>;
  hideHeaders: Array<string> | [] | undefined;
  titleKey?: string;
  descriptionKey?: string;
  page: number;
  total: number;
}

const props = withDefaults(defineProps<Props>(), {
  titleKey: "name",
});
const emit = defineEmits(["page"]);

const ionInfinite = (ev: any) => {
  emit("page", { page: props.page + 1 });
  setTimeout(() => ev.target.complete(), 500);
};
</script>
<style scoped lang="scss">
ion-content {
  width: 100vw !important;
  height: 78vh !important;
}
</style>
