<template>
  <div>
    <div
      class="ion-padding ion-flex ion-justify-content-between wrapper-page-header"
      :class="isPlatformIOS ? 'safe-area' : ''"
    >
      <div v-if="isMobile">
        <div class="ion-flex ion-align-items-center">
          <VsxIcon
            iconName="Category"
            color="#787F8C"
            @click="handleOpenMenu"
          />
          <h3 class="ion-no-margin ion-padding-horizontal">
            {{
              registerStore.location.id
                ? registerStore.location.name
                : $t("global.home")
            }}
          </h3>
          <ion-button fill="clear" @click="setOpen(!isOpen)">
            <VsxIcon iconName="ArrowDown2" color="#787F8C" />
          </ion-button>
        </div>
      </div>
      <div v-else>
        <h3 class="ion-no-margin ion-padding-horizontal">
          {{
            registerStore.location.id
              ? registerStore.location.name
              : $t("global.home")
          }}
        </h3>
      </div>
      <div>
        <slot name="options"></slot>
      </div>
    </div>
    <ion-content class="wrapper-content">
      <slot></slot>
    </ion-content>
    <Teleport to="body">
      <section class="location-select-wrapper" v-if="isOpen">
        <div class="location-select-wrapper__options">
          <div>
            <ol>
              <li
                v-for="(location, index) in locationList"
                :key="location.id"
                :class="{ 'item-start': index === 0 }"
              >
                <ion-item
                  :button="true"
                  :detail="false"
                  @click="setCurrentLocation(location)"
                >
                  <span style="margin-right: 10px">
                    <AvatarLocaleComponent
                      :locale-id="location.id ?? -1"
                      :image-id="location.image_id"
                      :locale-name="location.name"
                      :width="35"
                      :height="35"
                    />
                  </span>
                  <div class="content">
                    <div class="box-title-item">
                      <span class="title-item"> {{ location.name }} </span>
                    </div>
                    <div class="box-address-item">
                      <span class="address-item">
                        {{ location.address }}
                      </span>
                    </div>
                  </div>
                  <VsxIcon
                    v-if="registerStore.user.location_id === location.id"
                    iconName="TickCircle"
                    color="#4178F5"
                    slot="end"
                    type="bold"
                  />
                </ion-item>
              </li>
              <li class="item-end" v-if="isOwner">
                <ion-item
                  :button="true"
                  :detail="false"
                  @click="handleGoLocations"
                >
                  <div class="ion-flex ion-justify-content-center">
                    <VsxIcon iconName="Setting4" color="#4178F5" slot="end" />
                    <span class="title-item-admin">
                      {{ $t("locale.adminLocales") }}</span
                    >
                  </div>
                </ion-item>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Teleport>
  </div>
</template>
<script setup lang="ts">
import { IonButton, IonContent, IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { useNativePlatform } from "@/composables/useNativePlatform";
import { usePlatform } from "@/composables/usePlatform";
import { CoreStore, useCoreStore } from "@/stores/core";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";
import { useGeneric } from "@/composables/useGeneric";

const { locations, getLocations, getLocation } = useLocation();
const { refreshLocation, refreshDashboardOwner } = useCore();
const { refresh, setRefresh } = useGeneric();

import { computed, onMounted, ref, watch } from "vue";
import { useLocation } from "@/composables/useLocation";
import useCore from "@/composables/useCore";
import { useRegister } from "@/composables/useRegister";
import { ILocation } from "@/interfaces/locale/ILocation";
import { handlePutModel } from "@/services/generic";
import { MODEL, ROLES } from "@/utils/constants";
import router from "@/router";
import { IUser } from "@/interfaces/login/ILoginResponse";

const registerStore: RegisterStore = useRegisterStore();
const coreStore: CoreStore = useCoreStore();
const { isMobile } = useNativePlatform();
const { isPlatformIOS } = usePlatform();
const { handleGetMe, getLocalePhoto } = useRegister();
const { user } = useRegister();
const isOpen = ref(false);

const locationList = computed(() => {
  return locations.value;
});

const isOwner = computed(() => {
  return user.value.role == ROLES.OWNER;
});

const setOpen = (open: boolean) => (isOpen.value = open);

const handleGoLocations = () => {
  coreStore.setShowDropdown(false);
  setOpen(false);
  router.push({ name: "admin.locations.list" });
};

/**
 * Handle open menu
 */
const handleOpenMenu = () => {
  const menu: any = document.querySelector("ion-menu");
  menu.style.display = "block";
  menu.style.inset = 0;
  menu?.open();
};

onMounted(async () => {
  await refreshView();
});

const refreshView = async () => {
  await getLocations();
  let location = null;
  await handleGetMe().then(async () => {
    if (registerStore.user.location_id != null) {
      location = await getLocation(registerStore.user.location_id);
      if (location) {
        registerStore.setLocation(location[0]);
      }
    }
  });
};

watch(refreshDashboardOwner, async () => {
  await refreshView();
});

watch(refreshLocation, async () => {
  await refreshView();
  coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
  coreStore.setRefreshTask(!coreStore.refreshTask);
  coreStore.setRefreshDashboardOwner(!coreStore.refreshDashboardOwner);
  coreStore.setRefreshDashboard(!coreStore.refreshDashboard);
  coreStore.setRefreshRequest(!coreStore.refreshRequest);
  setRefresh(!refresh);
});

const setCurrentLocation = async (location: ILocation) => {
  await handlePutModel({
    model: MODEL.USER,
    id: registerStore.user.id,
    fields: {
      location_id: location.id,
    },
  }).then(async () => {
    registerStore.setLocation(location);
    setOpen(false);
    await handleGetMe();
    if (location.image_id) {
      const data = await getLocalePhoto(
        location.id ?? -1,
        location.image_id,
        true
      );
      if (data.url) {
        registerStore.setLocalePhoto(data.url);
      }
    }
    coreStore.setShowDropdown(false);
    coreStore.setRefreshLocation(!coreStore.refreshLocation);
    coreStore.setRefreshUserLocation(!coreStore.refreshUserLocation);
    coreStore.setRefreshTask(!coreStore.refreshTask);
    coreStore.setRefreshDashboardOwner(!coreStore.refreshDashboardOwner);
    coreStore.setRefreshDashboard(!coreStore.refreshDashboard);
    coreStore.setRefreshRequest(!coreStore.refreshRequest);
    setRefresh(!refresh.value);
  });
};
</script>
<style scoped lang="scss">
.safe-area {
  padding-top: 55px;
}

#main-content {
  height: 65px;
}

.wrapper-content {
  height: calc(100vh - 65px);
}

.wrapper-page-header {
  background-color: var(--ion-background-color);
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
  --min-height: 60px;
}

.item-start {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.item-end {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.box-title-item {
  padding-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.title-item {
  font-size: 16px;
}

.box-address-item {
  padding-bottom: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.address-item {
  font-size: 14px;
  color: #5e6573;
}

.title-item-admin {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #4178f5;
}

.content {
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.item-select-menu {
  width: 100%;
}

.location-select-wrapper {
  position: relative;
  margin-top: 60px;
  margin-left: 20px;

  &__options {
    box-shadow: 0px 4px 4px -3px rgba(28, 65, 151, 0.1),
      0px 8px 8px -3px rgba(28, 65, 151, 0.15);
    list-style: none;
    width: 300px;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    background-color: white;

    div {
      border-radius: 10px;
      background-color: white;
      height: auto;
      width: 280px;

      ol {
        width: 100%;
        max-height: 250px;
        overflow-y: scroll;

        li {
          list-style-type: none;
          min-width: 200px;
        }
      }
    }
  }
}
</style>
