<template>
  <ion-page>
    <WrapperPageComponent>
      <ion-content class="ion-padding join-wrapper">
        <ion-refresher slot="fixed" @ionRefresh="handleRefresh">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <div class="join-wrapper__back">
          <ion-back-button
            :text="$t('joinTeam.myWorkspaces')"
            color="neutral-80"
            defaultHref="/join"
          ></ion-back-button>
        </div>
        <section class="join-wrapper__body">
          <ion-segment
            color="primary"
            :value="selected"
            mode="md"
            @ionChange="applicationSelected($event)"
          >
            <ion-segment-button :value="APPLICATION_STATUS.ACCEPTED">
              <ion-label>{{ $t("joinTeam.actives") }}</ion-label>
            </ion-segment-button>
            <ion-segment-button :value="APPLICATION_STATUS.WAITING">
              <ion-label>{{ $t("joinTeam.requests") }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </section>
        <section>
          <ApplicationActiveComponent
            v-if="selected == APPLICATION_STATUS.ACCEPTED"
          />
          <ApplicationRequestComponent
            v-if="selected == APPLICATION_STATUS.WAITING"
          />
        </section>
      </ion-content>
    </WrapperPageComponent>
  </ion-page>
</template>

<script setup lang="ts">
import { useNativePlatform } from "@/composables/useNativePlatform";

import {
  IonBackButton,
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  SegmentCustomEvent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import router from "@/router";
import { ref, computed } from "vue";
import ApplicationRequestComponent from "@/components/shared/applications/ApplicationRequestComponent.vue";
import ApplicationActiveComponent from "@/components/shared/applications/ApplicationActiveComponent.vue";
import { APPLICATION_STATUS } from "@/utils/constants";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";
const { setJoinRequestTabSelected } = useCore();

const coreStore: CoreStore = useCoreStore()

const selected = computed(() => {
  return coreStore.tabJoinRequestSelected;
});

const applicationSelected = (event: SegmentCustomEvent) => {
  setJoinRequestTabSelected(event.detail.value);
};

const handleRefresh = () => {
  setTimeout(() => {
    router.go(0);
  }, 500);
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.join-wrapper {
  &__back {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }
}

.button-validate {
  width: 100%;
}

ion-fab-button {
  padding: 8px;
  --background: #4178f5;
  --background-activated: #4178f5;
  --background-hover: #4178f5;
  --border-radius: 12px;
  --box-shadow: none;
  --color: #dee6f7;
}
</style>
