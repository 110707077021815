<template>
  <ion-page :class="isPlatformIOS ? 'safe-area' : ''">
    <WrapperPageComponent>
      <template #options>
        <div class="more">
          <DropdownMenuGroupComponent
            @click:copy="copyCode"
            @click:share="shareCode"
          />
        </div>
      </template>
      <section class="my-profile-wrapper__body">
        <div class="ion-padding">
          <ion-segment
            color="primary"
            :value="selected"
            mode="md"
            @ionChange="optionSelected($event)"
          >
            <ion-segment-button :value="GROUP_OWNER_VIEW.MEMBERS">
              <ion-label>{{ $t("group.members") }}</ion-label>
            </ion-segment-button>
            <ion-segment-button :value="GROUP_OWNER_VIEW.REQUESTS">
              <ion-label>{{ $t("group.requests") }}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>
        <section>
          <GroupMemberComponent v-if="selected == GROUP_OWNER_VIEW.MEMBERS" />
          <GroupRequestComponent v-if="selected == GROUP_OWNER_VIEW.REQUESTS" />
        </section>
      </section>
    </WrapperPageComponent>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  toastController,
} from "@ionic/vue";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { Share } from "@capacitor/share";
import WrapperPageComponent from "@/components/shared/WrapperPageComponent.vue";
import i18n from "@/plugins/i18n";
import DropdownMenuGroupComponent from "@/components/admin/group/DropdownMenuGroupComponent.vue";
import { GROUP_OWNER_VIEW } from "@/utils/constants";
import {computed, ref} from "vue";
import GroupRequestComponent from "@/components/admin/group/GroupRequestComponent.vue";
import GroupMemberComponent from "@/components/admin/group/GroupMemberComponent.vue";
import { useRegister } from "@/composables/useRegister";
import { Clipboard } from "@capacitor/clipboard";
import {usePlatform} from "@/composables/usePlatform";
import {CoreStore, useCoreStore} from "@/stores/core";
import useCore from "@/composables/useCore";

const registerStore: RegisterStore = useRegisterStore();
const {isPlatformIOS} = usePlatform()
const { sendInvitationLink } = useRegister();
const { setGroupTabSelected } = useCore();

const coreStore: CoreStore = useCoreStore()

const selected = computed(() => {
  return coreStore.tabGroupSelected;
});

const optionSelected = (event: SegmentCustomEvent) => {
  setGroupTabSelected(event.detail.value ?? "");
};

const copyCode = async () => {
  await Clipboard.write({
    string: registerStore.location.code ?? "",
  });
  const toast = await toastController.create({
    message: i18n.global.t("global.codeCopied"),
    duration: 1500,
    position: "bottom",
    mode: "ios",
  });
  await toast.present();
};

const shareCode = async () => {
  await Share.share({
    title: i18n.global.t("global.shareCodeTitle"),
    text: i18n.global.t("global.shareCodeDescription", {
      platform: isPlatformIOS ? "iOS" : "Android",
      name: registerStore.user.name,
      code: registerStore.location.code ?? "",
    }),
    dialogTitle: i18n.global.t("actions.share"),
  });
  await sendInvitationLink(
    registerStore.user.phone_number,
    registerStore.location.code!
  );
};
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.safe-area {
  padding-top: 0px;
}

.groups-wrapper {
  position: relative;

  &__menu {
    width: 100%;
  }
}

.avatar {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 165px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.admin__panel {
  display: flex;
}

.admin__navigation__drawer {
  height: 100vh;
  @include laptop {
    display: initial;
    min-width: 240px;
    max-width: 240px;
  }

  .admin__items {
    @include laptop {
      min-width: 240px;
      max-width: 240px;
    }
    background-color: #f8f9fc;
    height: calc(100vh - 72px);
    padding-top: 5px;

    div {
      :hover {
        background-color: rgba(143, 163, 203, 0.3);
      }

      padding: 10px;

      a {
        text-decoration: none;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 400;
        color: var(--ion-color-neutral-60);
        cursor: pointer;

        span {
          padding-left: 5px;
        }

        :hover {
          background-color: transparent;
        }

        &.active {
          background-color: rgba(182, 203, 251, 0.3);
          color: var(--ion-color-primary-60);
          font-weight: 600;
        }
      }
    }

    hr {
      background: rgba(199, 204, 214, 0.5);
    }
  }
}

.admin__content {
  display: flex;
  height: 100vh;
  width: 100vw;
  @include laptop {
    width: calc(100vw - 240px);
  }
}

.admin__user__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 72px;
  background-color: var(--ion-color-neutral-90);

  ion-item {
    --background: var(--ion-color-neutral-90);
    --color: #fff;
    --border-color: var(--ion-color-neutral-90);

    ion-label {
      display: flex;
      flex-direction: column;

      .user__name {
        font-weight: 600;
        font-size: 16px;
      }

      .user__role {
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
</style>
