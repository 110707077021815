<template>
  <section class="message-wrapper">
    <div class="message-wrapper__photo" v-if="photoLeft">
      <avatar-component
        class="photo-wrapper left"
        :image-id="userMessage?.image_id ?? -1"
        :user-id="userMessage?.id ?? -1"
      />
    </div>
    <div
      class="message-wrapper__body"
      :class="photoRight ? 'background-blue' : ''"
    >
      <em v-if="!isListen" class="message-wrapper__body__new"></em>
      <div class="message-wrapper__user caption">
        {{ userMessage?.name }}
      </div>
      <div class="message-wrapper__audio">
        <div class="message-wrapper__audio__control">
          <template v-if="enableWrapper">
            <VsxIcon
              @click="playAudio"
              v-if="!isPlaying"
              class="pointer"
              :iconName="'Play'"
              slot="end"
            ></VsxIcon>
            <VsxIcon
              @click="pauseAudio"
              v-if="isPlaying"
              class="pointer"
              iconName="Pause"
              slot="end"
            ></VsxIcon>
          </template>
          <template v-else>
            <VsxIcon iconName="CloseCircle" slot="end"></VsxIcon>
          </template>
        </div>
        <div class="message-wrapper__audio__wave">
          <img
            :src="userMessage?.is_user ? waveBlue : wave"
            class="message-wrapper__audio__wave__img"
          />
          <div class="message-wrapper__audio__wave__info">
            <div class="message-wrapper__audio__wave__info__time">
              {{ progress }}
            </div>
            <div class="message-wrapper__audio__wave__info__text">
              {{ duration }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message-wrapper__photo" v-if="photoRight">
      <avatar-component
        class="photo-wrapper right"
        :image-id="user?.image_id ?? -1"
        :user-id="user?.id ?? -1"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import { onMounted, PropType, ref, watch } from "vue";
import { VsxIcon } from "vue-iconsax";
import { useAudio } from "@/composables/useAudio";
import wave from "@/assets/wave.svg";
import waveBlue from "@/assets/wave-blue.svg";
import { useUser } from "@/composables/useUser";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import { useRegister } from "@/composables/useRegister";
import useCore from "@/composables/useCore";
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { handleRemoveNotification } from "@/services/talkie";
import { INotification } from "@/interfaces/notification/INotification";
import { IMessageSummary, ISender } from "@/interfaces/IMessageSummary";

const { getAudio } = useAudio();
const { getUserInfo } = useUser();
const { user, setNotifications } = useRegister();
const { listeningAudio, listeningAudioId, audioSelected } = useCore();
const registerStore: RegisterStore = useRegisterStore();

const props = defineProps({
  message: Object as PropType<IMessageSummary>,
  notifications: Array as PropType<INotification[]>,
});

const audio: HTMLAudioElement = new Audio();
const isPlaying = ref(false);
const isPause = ref(false);
const progress = ref("00:00");
const duration = ref("00:00");
const userMessage = ref<ISender>();
const photoLeft = ref(true);
const photoRight = ref(false);
const enableWrapper = ref(true);
const isListen = ref(props.message?.is_listen);

const playAudio = async () => {
  isListen.value = true;

  const item = await getAudio({
    model_id: props.message?.id,
    id: props.message?.file_id,
  });
  if (!isPause.value) {
    if (item) {
      audio.src = `data:audio/wav;base64,${item}`;
      await audio.play();
      isPlaying.value = true;

      audioSelected({mode: true, id: props.message?.id ?? -1});
      audio.addEventListener("timeupdate", () => {
        progress.value = new Date(audio.currentTime * 1000)
            .toISOString()
            .slice(14, 19)
            .toString();
      });
    }
    audio.onended = async function () {
      isPlaying.value = false;
      progress.value = duration.value;
      if (props.message && !props.message?.is_listen) {
        await handleRemoveNotification(props.message.id).then(() => {
          isPlaying.value = true;
        });
      }
      audioSelected({mode: false, id: 0});
    };
  } else {
    await audio.play();
    isPlaying.value = true;
    isPause.value = false;
  }
};

const pauseAudio = () => {
  audio.pause();
  isPlaying.value = false;
  isPause.value = true;
  audioSelected({mode: false, id: props.message?.id ?? -1});
};

const hasNotificationPending = (messageId: number) => {
  if (props.notifications?.length) {
    return (
      props.notifications.filter((n) => n.message_id === messageId).length > 0
    );
  } else {
    return [];
  }
};

onMounted(async () => {
  duration.value = props.message?.duration
    ? new Date(props.message.duration * 1000).toISOString().slice(14, 19)
    : "00:00";
  userMessage.value = props.message?.sender as ISender;
  if (userMessage.value?.is_user) {
    photoLeft.value = false;
    photoRight.value = true;
  }
});

watch(listeningAudio, () => {
  if (listeningAudio.value && listeningAudioId.value == props.message?.id) {
    enableWrapper.value = true;
  } else if (listeningAudio.value) {
    enableWrapper.value = false;
  } else {
    enableWrapper.value = true;
  }
});
</script>
<style scoped lang="scss">
.photo-wrapper {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;

  &.right {
    margin-left: 10px;
  }

  &.left {
    margin-right: 10px;
  }
}

.message-left {
  margin-left: 15px;
}

.message-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &__body {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 10px;
    background: #c7ccd680;
    border-radius: 12px 12px 12px 0px;
    position: relative;

    &__new {
      position: absolute;
      top: 2px;
      right: 5px;
      height: 7px;
      width: 7px;
      border-radius: 50%;
      font-size: 20px;
      background: #1c4197;
    }

    &.background-blue {
      background: #b6cbfb4d;
    }
  }

  &__user {
    color: #464c59;
  }

  &__audio {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__control {
      color: #464c59;
      margin-right: 10px;
      width: 40px;
    }

    &__wave {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      color: #464c59;
      width: 80%;

      &__img {
        width: 100%;
      }

      &__info {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &__time {
          color: #464c59;
          font-size: 12px;
        }

        &__text {
          color: #464c59;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
