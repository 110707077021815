<template>
  <div class="ion-flex ion-align-items-center">
    <AvatarLocaleComponent
      :locale-id="item.id"
      :image-id="item.image_id"
      :locale-name="item.name"
    />
    <div class="ion-padding-start">
      <ion-label>
        {{ item.name }}
        <p>
          {{ item.address }}
        </p>
      </ion-label>
    </div>
  </div>
</template>
<script setup lang="ts">
import { IonLabel } from "@ionic/vue";
import { ref, computed, watch } from "vue";
import type { Ref } from "vue";
import { useRegister } from "@/composables/useRegister";
import { getImageLetter } from "@/utils/getImageLetter";
import AvatarLocaleComponent from "@/components/shared/avatar/AvatarLocaleComponent.vue";

interface Props {
  item: any;
}

const { getLocalePhoto, localePhoto } = useRegister();

const props = defineProps<Props>();
const photo: Ref<string | null> = ref("");

const getPhoto = async () => {
  if (props.item.image_id) {
    const data = await getLocalePhoto(props.item.id, props.item.image_id, true);
    if (data) {
      photo.value = data.url;
    } else {
      photo.value = null;
    }
  }
};

getPhoto();

watch(localePhoto, () => {
  if (localePhoto.value) {
    getPhoto();
  }
});
</script>
