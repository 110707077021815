<template>
  <ion-page>
    <ion-content class="ion-padding join-wrapper">
      <div class="join-wrapper__back">
        <ion-back-button
          :text="$t('joinTeam.youWillJoin')"
          color="neutral-80"
          defaultHref="/join"
        ></ion-back-button>
      </div>
      <section class="join-wrapper__body">
        <img :src="photo ? photo : defaultPhoto" style="width: 120px" />
        <h3 class="join-wrapper__title">
          {{ registerStore.location.name ?? "" }}
        </h3>
        <p class="join-wrapper__description">
          {{ registerStore.location.address ?? "" }}
        </p>
        <p class="join-wrapper__confirm">
          {{ $t("joinTeam.joinConfirm") }}
        </p>
        <p class="join-wrapper__confirm bold">
          {{ $t("joinTeam.joinConfirmSecond") }}
        </p>
        <ion-button
          mode="ios"
          expand="block"
          class="button-validate ion-margin-top"
          type="button"
          @click="sendRequest"
          fill="solid"
          >{{ $t("actions.sendRequest") }}
        </ion-button>
      </section>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import { RegisterStore, useRegisterStore } from "@/stores/register";
import { IonBackButton, IonButton, IonContent, IonPage } from "@ionic/vue";

import { useJoin } from "@/composables/useJoin";
import { onMounted, ref } from "vue";
import defaultPhoto from "@/assets/default_photo.svg";
import { useRegister } from "@/composables/useRegister";

const registerStore: RegisterStore = useRegisterStore();
const { sendApplication } = useJoin();
const { getLocalePhoto } = useRegister();
const photo = ref<string | null>(null);


const img = ref<string | ArrayBuffer | null>(
  `https://ui-avatars.com/api/?name=${registerStore.location.name.charAt(0)}${registerStore.location.name.charAt(1)}&size=400&rounded=true`
);

const sendRequest = () => {
  sendApplication();
};

onMounted(async () => {
  if (
    registerStore.location.image_id > 0 &&
    registerStore.location.image_id != null
  ) {
    const data = await getLocalePhoto(
      registerStore.location.id!,
      registerStore.location.image_id
    );
    if (data) {
      photo.value = `data:image/png;base64,${data}`;
    } else {
      photo.value = null;
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/theme/mixins_responsive";

.button-native {
  font: {
    weight: bold;
  }
}

.join-wrapper {
  &__back {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    margin-top: 30px;
  }

  &__description {
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }
  }

  &__confirm {
    margin-top: 0px;
    margin-bottom: 30px;
    text-align: center;
    font: {
      weight: 300;
      size: 14px;
    }

    &.bold {
      font: {
        weight: bold;
      }
    }
  }
}

.button-validate {
  width: 100%;
}
</style>
