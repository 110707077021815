<template>
  <ModalComponent :model-value="coreStore.loading" noIconClose>
    <div
      class="
        div__loading
        ion-flex ion-justify-content-center ion-align-items-center
      "
    >
      <Vue3Lottie :animationData="LoadingJSON" :height="200" :width="200" />
    </div>
  </ModalComponent>
</template>
<script setup lang="ts">
import { Vue3Lottie } from "vue3-lottie";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import LoadingJSON from "@/assets/loading.json";
import { CoreStore, useCoreStore } from "@/stores/core";
const coreStore: CoreStore = useCoreStore();
</script>
<style lang="scss" scoped>
.div__loading {
  height: 90vh;
}
</style>
