<template>
    <DropdownComponent>
        <template #button>
            <div class="avatar">
                <avatar-component :user-id="registerStore.user.id" :image-id="registerStore.user.image_id ?? -1"/>
            </div>
        </template>
        <ol>
            <li>
                <ion-item
                        :button="true"
                        :detail="false"
                        class="options_actions"
                        @click="emitClick('click:profile')"
                >
                    <VsxIcon iconName="Profile" color="gray"/>
                    <span class="ion-padding-start"> {{ $t('myProfile.profile') }} </span>
                    <VsxIcon iconName="ArrowRight2" color="gray" style="position: absolute; right: 10px"/>
                </ion-item>
            </li>
            <li>
                <ion-item
                        :button="true"
                        :detail="false"
                        class="options_actions"
                        @click="emitClick('click:setting')"
                >
                    <VsxIcon iconName="Setting2" color="gray"/>
                    <span class="ion-padding-start"> {{ $t('myProfile.setting') }} </span>
                    <VsxIcon iconName="ArrowRight2" color="gray" style="position: absolute; right: 10px"/>
                </ion-item>
            </li>
            <li>
                <ion-item
                        :button="true"
                        :detail="false"
                        class="options_actions"
                        @click="emitClick('click:logout')"
                >
                    <VsxIcon iconName="Logout" color="gray"/>
                    <span class="ion-padding-start"> {{ $t('myProfile.logout') }} </span>
                    <VsxIcon iconName="ArrowRight2" color="gray" style="position: absolute; right: 10px"/>
                </ion-item>
            </li>
        </ol>
    </DropdownComponent>
</template>
<script setup lang="ts">
import {IonItem} from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";
import AvatarComponent from "@/components/shared/avatar/AvatarComponent.vue";
import {RegisterStore, useRegisterStore} from "@/stores/register";

interface Props {
    item: any;
}

const props = defineProps<Props>();
const emit = defineEmits(["click:profile", "click:setting", "click:logout"]);

const registerStore: RegisterStore = useRegisterStore();

const emitClick = (event: any) => {
    emit(event, props.item);
};
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}

.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}

ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
