<template>
  <div class="drawer__menu" v-if="modelValue">
    <div class="drawer__header">
      <ion-fab-button
        mode="ios"
        size="small"
        color="transparent"
        @click="closeDrawer"
      >
        <VsxIcon iconName="ArrowRight" />
      </ion-fab-button>
      <h3>{{ title }}</h3>
    </div>
    <div class="drawer__content">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { IonFabButton } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";

interface Props {
  title: string;
  modelValue: boolean;
}

defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const closeDrawer = () => {
  emit("update:modelValue", false);
};
</script>
<style scoped lang="scss">
.drawer__menu {
  padding: 15px;
  min-width: 424px;
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f8f9fc;
  box-shadow: -2px 0px 8px -3px rgba(28, 65, 151, 0.1),
    -10px 0px 16px -4px rgba(28, 65, 151, 0.05);
  .drawer__header {
    display: flex;
    align-items: center;
    h3 {
      padding-bottom: 8px;
    }
  }
}
ion-fab-button::part(native) {
  box-shadow: none;
}
</style>
