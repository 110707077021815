<template>
  <DropdownComponent>
    <template #button>
      <VsxIcon class="pointer" iconName="More" slot="end"></VsxIcon>
    </template>
    <ol>
      <li>
        <ion-item
          :button="true"
          :detail="false"
          class="options_actions"
          @click="emitClick('click:detail')"
        >
          <VsxIcon iconName="InfoCircle" color="gray" />
          <span class="ion-padding-start"> {{ $t('talkie.detail') }} </span>
        </ion-item>
      </li>
      <li>
        <ion-item
          :button="true"
          :detail="false"
          class="options_actions"
          @click="emitClick('click:button')"
        >
          <VsxIcon iconName="Maximize" color="gray" />
          <span class="ion-padding-start"> {{ $t('talkie.showButton') }} </span>
        </ion-item>
      </li>
        <li>
            <ion-item
                    :button="true"
                    :detail="false"
                    class="options_actions"
                    @click="emitClick('click:mark')"
            >
                <VsxIcon iconName="Messages" color="gray" />
                <span class="ion-padding-start"> {{ $t('talkie.markAsListened') }} </span>
            </ion-item>
        </li>
    </ol>
  </DropdownComponent>
</template>
<script setup lang="ts">
import { IonItem } from "@ionic/vue";
import { VsxIcon } from "vue-iconsax";
import DropdownComponent from "@/components/shared/dropdown/DropdownComponent.vue";


const emit = defineEmits(["click:detail", "click:button", "click:mark"]);

const emitClick = (event: any) => {
  emit(event);
};
</script>
<style scoped lang="scss">
.trash-icon {
  color: var(--ion-color-danger-80) !important;
}
.trash-text {
  color: var(--ion-color-danger-50-shade) !important;
}
ion-item {
  --background: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --border-color: white;
}
</style>
