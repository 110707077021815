<template>
  <section class="my-profile-wrapper__body">
    <div class="ion-padding-horizontal"  :class="isPlatformIOS ? 'safe-area' : ''">
      <div class="header-task">
        <h4>{{ $t("tasks.list") }}</h4>
        <div class="ion-flex ion-align-items-center">
          <ion-searchbar
            @ion-input="filterList"
            :placeholder="$t('global.searchByName')"
            @keydown="handleKeyDown($event)"
          ></ion-searchbar>
          <ion-button
            class="button-filter"
            fill="clear"
            @click="showFilters = true"
          >
            <VsxIcon iconName="Sort"></VsxIcon>
          </ion-button>
        </div>
      </div>
      <ion-segment
        color="primary"
        :value="selected"
        mode="md"
        @ionChange="optionSelected($event)"
      >
        <ion-segment-button :value="TASKS_VIEW.PENDING">
          <ion-label>{{ $t("tasks.pending") }}</ion-label>
        </ion-segment-button>
        <ion-segment-button :value="TASKS_VIEW.FINISHED">
          <ion-label>{{ $t("tasks.finished") }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
    <section>
      <div
        class="ion-padding-top ion-padding-horizontal"
        v-if="selected === TASKS_VIEW.PENDING"
      >
        <AdviceListComponent ref="getAdvice"></AdviceListComponent>
      </div>
      <div v-if="selected === TASKS_VIEW.PENDING">
        <PendingListComponent :tab="selected" sort="asc" ref="getList" />
      </div>
      <div v-else>
        <PendingListComponent :tab="selected" sort="desc" ref="getList" />
      </div>
    </section>
    <ModalComponent :model-value="true" v-show="showFilters" noIconClose>
      <HeaderNavigationComponent
        preventDefault
        @click:back="showFilters = false"
        :title="$t('tasks.filter')"
      />
      <div class="ion-padding-top">
        <ModalFiltersTask
          :tab="selected"
          @update:filters="updatePendingListFilter"
          :class="isPlatformIOS ? 'safe-area' : ''"
        />
      </div>
    </ModalComponent>
  </section>
</template>

<script setup lang="ts">
import {ref} from "vue";
import { VsxIcon } from "vue-iconsax";
import type { Ref } from "vue";
import PendingListComponent from "@/components/admin/tasks/PendingListComponent.vue";
import { ENUM_TASK, MODEL, TASKS_VIEW } from "@/utils/constants";
import { useGenericStore } from "@/stores/generic";
import ModalComponent from "@/components/shared/modal/ModalComponent.vue";
import HeaderNavigationComponent from "../location/HeaderNavigationComponent.vue";
import ModalFiltersTask from "./ModalFiltersTask.vue";
import AdviceListComponent from "./AdviceListComponent.vue";
import { useRegister } from "@/composables/useRegister";
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  IonSearchbar,
  IonButton,
} from "@ionic/vue";
import {usePlatform} from "@/composables/usePlatform";
import { fi } from "date-fns/locale";

const getList: Ref<any> = ref(null);
const getAdvice: Ref<any> = ref(null);
const showFilters: Ref<boolean> = ref(false);
const selected = ref(TASKS_VIEW.PENDING);
const { user } = useRegister();
const storeList = useGenericStore();
const { isPlatformIOS } = usePlatform();

const optionSelected = (event: SegmentCustomEvent) => {
  selected.value = event.detail.value ?? TASKS_VIEW.PENDING;
};

const updatePendingList = () => {
  getList.value.getModel({ reset: true });
};

const updateAdviceList = () => {
  getAdvice.value.getModel({ reset: true });
};

const updatePendingListFilter = () => {
  showFilters.value = false;
  getList.value.getModel({ reset: true });
};

const filterList = (evt: any) => {
  
  storeList.setFiltersModel(MODEL.TASK, [
    {
      field: "title",
      op: "ilike",
      value: evt.target.value,
    },
    {
      field: "status",
      value:
        selected.value === TASKS_VIEW.PENDING
          ? ENUM_TASK.pending
          : ENUM_TASK.finished,
      op: "==",
    },
    {
      field: "location_id",
      value: user.value.location_id,
      op: "==",
    },
  ]);

  getList.value.getModel({ reset: true, showLoading: false });

  if (evt.key === 'Enter') {
    getList.value.getModel({ reset: true });
  }
};

const handleKeyDown = function (event: KeyboardEvent) {
  console.log("HandleKey", event.key);
  if (event.key === 'Enter') {
    filterList(event);
  }
}

defineExpose({ updatePendingList, updateAdviceList });
</script>
<style scoped lang="scss">
.header-task {
  padding-bottom: 15px;
  h4 {
    margin-bottom: 5px !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  ion-searchbar {
    padding: 0 !important;
  }
}
.my-profile-wrapper__body {
  padding-bottom: 80px;
}
.safe-area {
  padding-top: 35px;
}
</style>
